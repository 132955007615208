
import { CssVarsProvider } from "@mui/joy/styles";
import { CssBaseline, GlobalStyles } from "@mui/joy";

import { Provider } from "react-redux";

import { registerLicense } from '@syncfusion/ej2-base';

import { AppRouter } from "./routers/AppRouter";

import store from "./store";

import './css/App.css'

registerLicense( process.env.REACT_APP_SYNCFUSION_API_KEY ?? '' );

export const App = () => {
    return (
        <CssVarsProvider>
            
            <CssBaseline />
            <GlobalStyles styles={{ ":root": { "--Form-maxWidth": "800px", "--Transition-duration": "0.4s" } }} />

            <Provider store = { store }>

                <AppRouter />

            </Provider>

        </CssVarsProvider>
    );
}
