import { useState, Fragment, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Sheet, GlobalStyles, Typography, Input, List, ListItem, ListItemContent, Divider, IconButton, Chip } from '@mui/joy';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import MapIcon from '@mui/icons-material/Map';
import PersonIcon from '@mui/icons-material/Person';
import ChecklistIcon from '@mui/icons-material/Checklist';
import RouteIcon from '@mui/icons-material/Route';
import AppsIcon from '@mui/icons-material/Apps';
import GroupIcon from '@mui/icons-material/Group';
import InventoryIcon from '@mui/icons-material/Inventory';
import ArticleIcon from '@mui/icons-material/Article';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import { RootState } from '../store';

import { logout } from '../store/slices/authSlice';

import { Logout } from '../connections/auth/AuthConnection';
import { ObtenerListadosEnvios } from '../connections/comun/ListadoEnvioConnection';

import { setRecepcion } from '../store/slices/recepcionSlice';

function closeSidebar() {
    if (typeof window !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
}
  
function Toggler({ defaultExpanded = false, renderToggle, children }: {
    defaultExpanded?: boolean;
    children: React.ReactNode;
    renderToggle: (params: {
      open: boolean;
      setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    }) => React.ReactNode;
}) {
    const [open, setOpen] = useState(defaultExpanded);
    return (
        <Fragment>

            {renderToggle({ open, setOpen })}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: open ? '1fr' : '0fr',
                    transition: '0.2s ease',
                    '& > *': {
                      overflow: 'hidden',
                    },
                }}
            >
                {children}
            </Box>

        </Fragment>
    );
}

export const Sidebar = () => {

    const dispatch = useDispatch();

    const { numeroNotificaciones } = useSelector( (state: RootState) => state.recepcion );

    const navigate = useNavigate();

    const { pathname } = useLocation();

    const { administrador, nombres, apellidos, correoElectronico, permisos } = useSelector( ( state: RootState ) => state.auth );

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleLogout = async () => {

        setIsLoading( true );

        await Logout().then( resp => {

            if( resp.success ){

                setTimeout(() => {

                    localStorage.setItem('lastPath', '/' );
                    localStorage.clear();
                    
                    dispatch( logout() );
                    setIsLoading( false );
                
                }, 400);

            }
            else {
                setIsLoading( false );
            }

        });

    }

    useEffect(() => {
    
        async function obtener(){

            await ObtenerListadosEnvios().then( resp =>  {

                dispatch( setRecepcion( { numeroNotificaciones: resp.length } ) );

            });

        }
       
        obtener();          

    }, [ dispatch ])   
    
    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: { xs: 'fixed', md: 'sticky' },
                transform: {
                  xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                  md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                      ':root': {
                        '--Sidebar-width': '265px',
                        [theme.breakpoints.up('lg')]: {
                          '--Sidebar-width': '275px',
                        },
                    },
                })}
            />
            
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={ () => closeSidebar() }
            />

            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>       
                <img
                    src={ process.env.PUBLIC_URL + "/logos/favicon.png" }
                    alt={"PJECZ"}
                    style={{ width: '45px' }}
                />
                <Typography level="h2">SAGA</Typography> 
            </Box>

            <Input 
                size="md" 
                placeholder="Buscar expediente" 
                sx={{ mb: 1, fontSize: 14 }} 
                endDecorator={ <IconButton sx={{ bgcolor: '#004360' }} variant='solid'> <SearchRoundedIcon /> </IconButton> }
            />

            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                      gap: 2,
                    },
                }}
            >

                <List
                    size="sm"
                    sx={{
                        gap: 3,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >

                    <ListItem>
                        <ListItemButton 
                            sx={{ p: 1, bgcolor: pathname.includes( '/inicio/' ) ? '#004360' : '' }} 
                            variant={ pathname.includes( '/inicio/' ) ? 'solid' : 'plain'} 
                            role="menuitem" 
                            onClick={ () => navigate( '/inicio/' ) } 
                        >
                            <HomeRoundedIcon sx={{ fontSize: 22 }} />

                            <ListItemContent>
                                <Typography level="title-sm" sx={{ color: pathname.includes( '/inicio/' ) ? 'white' : '' }}>Inicio</Typography>
                            </ListItemContent>                                                     

                        </ListItemButton>
                    </ListItem>  

                    {
                        ( permisos?.RecepcionistaEnvios === true && !administrador )
                        &&
                            <ListItem>
                                <ListItemButton 
                                    sx={{ p: 1, bgcolor: pathname.includes( '/nueva-recepcion/' ) ? '#004360' : '' }} 
                                    variant={ pathname.includes( '/nueva-recepcion/' ) ? 'solid' : 'plain'} 
                                    role="menuitem" 
                                    onClick={ () => navigate( '/nueva-recepcion/' ) } 
                                >
                                    <AppsIcon sx={{ fontSize: 22 }} />

                                    <ListItemContent>
                                        <Typography level="title-sm" sx={{ color: pathname.includes( '/nueva-recepcion/' ) ? 'white' : '' }}>Nueva recepción</Typography>
                                    </ListItemContent>

                                    {
                                        numeroNotificaciones !== 0
                                        &&
                                            <Chip 
                                                size="sm" 
                                                sx={{ bgcolor: pathname.includes( '/nueva-recepcion/' )  ? '#fff' : '#004360', color: pathname.includes( '/nueva-recepcion/' )  ? 'black' : '' }} 
                                                variant="solid"
                                            > 
                                                { numeroNotificaciones } 
                                            </Chip>                            
                                    }

                                </ListItemButton>
                            </ListItem>  
                    }

                    {
                        ( permisos?.GestionarNotificaciones === true && !administrador )
                        &&
                            <ListItem>
                                <ListItemButton 
                                    sx={{ p: 1, bgcolor: pathname.includes( '/gestionar-notificaciones/' ) ? '#004360' : '' }} 
                                    variant={ pathname.includes( '/gestionar-notificaciones/' ) ? 'solid' : 'plain'} 
                                    role="menuitem" 
                                    onClick={ () => navigate( '/gestionar-notificaciones/' ) } 
                                >
                                    <ChecklistIcon sx={{ fontSize: 22 }} />

                                    <ListItemContent>
                                        <Typography level="title-sm" sx={{ color: pathname.includes( '/gestionar-notificaciones/' ) ? 'white' : '' }}>Gestionar notificaciones</Typography>
                                    </ListItemContent>                                                     

                                </ListItemButton>
                            </ListItem>  
                    }                

                    {
                        ( permisos?.AsignadorActuarios === true && !administrador )
                        &&
                            <ListItem>
                                <ListItemButton 
                                    sx={{ p: 1, bgcolor: pathname.includes( '/asignacion-actuario/' ) ? '#004360' : '' }} 
                                    variant={ pathname.includes( '/asignacion-actuario/' ) ? 'solid' : 'plain'} 
                                    role="menuitem" 
                                    onClick={ () => navigate( '/asignacion-actuario/' ) } 
                                >
                                    <PersonIcon sx={{ fontSize: 22 }} />

                                    <ListItemContent>
                                        <Typography level="title-sm" sx={{ color: pathname.includes( '/asignacion-actuario/' ) ? 'white' : '' }}>Asignación de actuario</Typography>
                                    </ListItemContent>                                           

                                </ListItemButton>
                            </ListItem>  
                    }

                    {
                        ( permisos?.GeneradorRutas === true && !administrador )
                        &&
                            <ListItem>
                                <ListItemButton 
                                    sx={{ p: 1, bgcolor: pathname.includes( '/generar-ruta/' ) ? '#004360' : '' }} 
                                    variant={ pathname.includes( '/generar-ruta/' ) ? 'solid' : 'plain'} 
                                    role="menuitem" 
                                    onClick={ () => navigate( '/generar-ruta/' ) } 
                                >
                                    <MapIcon sx={{ fontSize: 22 }} />

                                    <ListItemContent>
                                        <Typography level="title-sm" sx={{ color: pathname.includes( '/generar-ruta/' ) ? 'white' : '' }}>Generar ruta</Typography>
                                    </ListItemContent>                                                     

                                </ListItemButton>
                            </ListItem>  
                    }

                    {
                        ( permisos?.VisualizadorMisRutas === true && !administrador )
                        &&
                            <ListItem>
                                <ListItemButton 
                                    sx={{ 
                                        p: 1, 
                                        bgcolor: 
                                            (
                                                pathname.includes( '/mis-rutas/' ) ||
                                                pathname.includes( '/ruta/' ) ||
                                                pathname.includes( '/notificacion/' )
                                            )
                                            ? '#004360' 
                                            : '' 
                                    }} 
                                    variant={ 
                                        (
                                            pathname.includes( '/mis-rutas/' ) ||
                                            pathname.includes( '/ruta/' ) ||
                                            pathname.includes( '/notificacion/' )
                                        )
                                        ? 'solid' 
                                        : 'plain'
                                    } 
                                    role="menuitem" 
                                    onClick={ () => navigate( '/mis-rutas/' ) } 
                                >
                                    <RouteIcon sx={{ fontSize: 22 }} />

                                    <ListItemContent>
                                        <Typography 
                                        level="title-sm" 
                                        sx={{ 
                                            color: 
                                                (
                                                    pathname.includes( '/mis-rutas/' )||
                                                    pathname.includes( '/ruta/' ) ||
                                                    pathname.includes( '/notificacion/' )
                                                ) 
                                                ? 'white' 
                                                : '' 
                                        }}>
                                            Mis rutas
                                        </Typography>
                                    </ListItemContent>                                           

                                </ListItemButton>
                            </ListItem>  
                    }

                    {
                        administrador
                        &&
                            <>

                                <ListItem>
                                    <ListItemButton 
                                        sx={{ p: 1, bgcolor: pathname.includes( '/usuarios/' ) ? '#004360' : '' }} 
                                        variant={ pathname.includes( '/usuarios/' ) ? 'solid' : 'plain'} 
                                        role="menuitem" 
                                        onClick={ () => navigate( '/usuarios/' ) } 
                                    >
                                        <GroupIcon sx={{ fontSize: 22 }} />

                                        <ListItemContent>
                                            <Typography level="title-sm" sx={{ color: pathname.includes( '/usuarios/' ) ? 'white' : '' }}>Usuarios</Typography>
                                        </ListItemContent>                                           

                                    </ListItemButton>
                                </ListItem>  

                                <ListItem>
                                    <ListItemButton 
                                        sx={{ p: 1, bgcolor: pathname.includes( '/catalogos/' ) ? '#004360' : '' }} 
                                        variant={ pathname.includes( '/catalogos/' ) ? 'solid' : 'plain'} 
                                        role="menuitem" 
                                        onClick={ () => navigate( '/catalogos/' ) } 
                                    >
                                        <InventoryIcon sx={{ fontSize: 22 }} />

                                        <ListItemContent>
                                            <Typography level="title-sm" sx={{ color: pathname.includes( '/catalogos/' ) ? 'white' : '' }}>Catálogos</Typography>
                                        </ListItemContent>                                           

                                    </ListItemButton>
                                </ListItem>  

                                <ListItem>
                                    <ListItemButton 
                                        sx={{ p: 1, bgcolor: pathname.includes( '/plantillas/' ) ? '#004360' : '' }} 
                                        variant={ pathname.includes( '/plantillas/' ) ? 'solid' : 'plain'} 
                                        role="menuitem" 
                                        onClick={ () => navigate( '/plantillas/' ) } 
                                    >
                                        <ArticleIcon sx={{ fontSize: 22 }} />

                                        <ListItemContent>
                                            <Typography level="title-sm" sx={{ color: pathname.includes( '/plantillas/' ) ? 'white' : '' }}>Plantillas</Typography>
                                        </ListItemContent>                                           

                                    </ListItemButton>
                                </ListItem>  

                                <ListItem>
                                    <ListItemButton 
                                        sx={{ p: 1, bgcolor: pathname.includes( '/api-keys/' ) ? '#004360' : '' }} 
                                        variant={ pathname.includes( '/api-keys/' ) ? 'solid' : 'plain'} 
                                        role="menuitem" 
                                        onClick={ () => navigate( '/api-keys/' ) } 
                                    >
                                        <VpnKeyIcon sx={{ fontSize: 22 }} />

                                        <ListItemContent>
                                            <Typography level="title-sm" sx={{ color: pathname.includes( '/api-keys/' ) ? 'white' : '' }}>Api keys</Typography>
                                        </ListItemContent>                                           

                                    </ListItemButton>
                                </ListItem>  

                            </>
                    }

                    {
                        ( permisos?.VisualizadorConsultas === true )
                        &&
                            <ListItem nested sx={{ '--List-nestedInsetStart': '1rem' }}>

                                <Toggler
                                    defaultExpanded={ pathname.includes( '/consultas/' ) }
                                    renderToggle={ ( { open, setOpen } ) => (
                                        <ListItemButton onClick={() => setOpen(!open)}>
                                            <ManageSearchIcon sx={{ fontSize: 22 }}  />
                                            <ListItemContent>
                                                <Typography level="title-sm">Consultas</Typography>
                                            </ListItemContent>
                                            <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
                                        </ListItemButton>
                                    )}
                                >
                                    <List sx={{ gap: 4, mt: 4 }} >         

                                        {
                                            ( permisos?.VisualizadorListadosEnvio === true )
                                            &&
                                                <ListItem>
                                                    <ListItemButton 
                                                        sx={{ bgcolor: pathname === '/consultas/listados-envio/' ? '#004360' : '' }} 
                                                        variant={ pathname === '/consultas/listados-envio/' ? 'solid' : 'plain'}  
                                                        onClick={ () => navigate( '/consultas/listados-envio/' ) } 
                                                    >                                    
                                                        <SearchIcon />
                                                        <ListItemContent>
                                                            <Typography level="title-sm" sx={{ color: pathname === '/consultas/listados-envio/' ? 'white' : '' }} >Listado(s) de envío</Typography>
                                                        </ListItemContent>
                                                    </ListItemButton>
                                                </ListItem>
                                        }    

                                        {
                                            ( permisos?.VisualizadorNotificaciones === true )
                                            &&
                                                <ListItem>
                                                    <ListItemButton 
                                                        sx={{ bgcolor: pathname === '/consultas/notificaciones/' ? '#004360' : '' }} 
                                                        variant={ pathname === '/consultas/notificaciones/' ? 'solid' : 'plain'}  
                                                        onClick={ () => navigate( '/consultas/notificaciones/' ) } 
                                                    >                                    
                                                        <SearchIcon />
                                                        <ListItemContent>
                                                            <Typography level="title-sm" sx={{ color: pathname === '/consultas/notificaciones/' ? 'white' : '' }} >
                                                                Notificaciones
                                                            </Typography>
                                                        </ListItemContent>
                                                    </ListItemButton>
                                                </ListItem>
                                        }   

                                        {
                                            ( permisos?.VisualizadorRutasAsignadas === true )
                                            &&
                                                <ListItem>
                                                    <ListItemButton 
                                                        sx={{ bgcolor: pathname === '/consultas/rutas-asignadas/' ? '#004360' : '' }} 
                                                        variant={ pathname === '/consultas/rutas-asignadas/' ? 'solid' : 'plain'}  
                                                        onClick={ () => navigate( '/consultas/rutas-asignadas/' ) } 
                                                    >                                    
                                                        <SearchIcon />
                                                        <ListItemContent>
                                                            <Typography level="title-sm" sx={{ color: pathname === '/consultas/rutas-asignadas/' ? 'white' : '' }} >Rutas asignadas</Typography>
                                                        </ListItemContent>
                                                    </ListItemButton>
                                                </ListItem>
                                        }                                              

                                    </List>

                                </Toggler>

                            </ListItem>
                    }

                </List>
            
            </Box>

            <Divider />

            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>

                <Box sx={{ minWidth: 0, flex: 1 }}>
                    <Typography fontWeight={'bold'} fontSize={14}>{ nombres + ' ' + apellidos }</Typography>
                    <Typography level="body-xs" noWrap>{ correoElectronico }</Typography>
                </Box>

               <IconButton
                    variant='outlined'
                    size='sm'
                    onClick={ handleLogout }
                    loading={ isLoading }
               >
                    <LogoutOutlinedIcon />
               </IconButton>

            </Box>

        </Sheet>    
    );
}
