import HttpClienteToken from '../../services/HttpClientToken';

import { TipoUsuarioInterface } from '../../interfaces/catalogos/TipoUsuarioInterface';

export const ObtenerTiposUsuarios = () => {

    return new Promise<TipoUsuarioInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoUsuario/ObtenerTiposUsuarios', token)
            .then( ({ data } : { data : TipoUsuarioInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const TiposUsuarios = () => {

    return new Promise<TipoUsuarioInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoUsuario/', token)
            .then( ({ data } : { data : TipoUsuarioInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarTipoUsuario = ( params: TipoUsuarioInterface ) => {

    return new Promise<TipoUsuarioInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoUsuario/Actualizar', params, token)
            .then( ({ data } : { data : TipoUsuarioInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoUsuario: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarTipoUsuario = ( params: TipoUsuarioInterface ) => {

    return new Promise<TipoUsuarioInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoUsuario/Agregar', params, token)
            .then( ({ data } : { data : TipoUsuarioInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoUsuario: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}