import React, { useState } from 'react'

import { Box, DialogContent, DialogTitle, Grid, List, ListItem, ListItemButton, ListItemContent, Modal, ModalClose, ModalDialog, ModalOverflow, Typography } from '@mui/joy';

import { NotificacionFullProps } from '../interfaces/comun/NotificacionInterface';
import moment from 'moment';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    notificacion: NotificacionFullProps;
}

export const ModalNotificacion = ({ open, setOpen, notificacion }: Props) => {

    const [openDocumento, setOpenDocumento] = useState<boolean>( false );
    const [descripcion, setDescripcion] = useState<string>( "" );
    const [documento, setDocumento] = useState<string>( "" );

    return (
        <>
            <Modal
                disableEscapeKeyDown
                open={open}
                onClose={(_, r) => {
                    if (r === 'backdropClick') return;

                    setOpen(false)
                }
                }
                sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)' }}
            >
                <ModalOverflow>

                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    >
                        <ModalClose />
                        <DialogTitle> </DialogTitle>

                        <DialogContent>                          

                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '800px' }} >

                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                    <Grid md={4} xs={4}>
                                        <Typography gutterBottom sx={{ fontWeight: 'Bold' }} >
                                            Persona a Notificar:
                                        </Typography>
                                    </Grid>
                                    <Grid md={8} xs={8} >
                                        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                                            { notificacion?.persona }
                                        </Typography>
                                        <Typography fontSize={14}> ({ notificacion.tipoParte }) </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                    <Grid md={4} xs={4}>
                                        <Typography gutterBottom sx={{ fontWeight: 'Bold' }} >
                                            Domicilio:
                                        </Typography>
                                    </Grid>
                                    <Grid md={8} xs={8} >
                                        <Typography gutterBottom style={{ fontWeight: 'normal' }}>
                                            { notificacion?.domicilio }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                    <Grid md={4} xs={4}>
                                        <Typography gutterBottom sx={{ fontWeight: 'Bold' }} >
                                            Tipo de notificación:
                                        </Typography>
                                    </Grid>
                                    <Grid md={8} xs={8} >
                                        <Typography gutterBottom style={{ fontWeight: 'normal' }}>
                                            { notificacion?.tipoNotificacion }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                    <Grid md={4} xs={4}>
                                        <Typography gutterBottom sx={{ fontWeight: 'Bold' }} >
                                            Tipo de acuerdo:
                                        </Typography>
                                    </Grid>
                                    <Grid md={8} xs={8} >
                                        <Typography gutterBottom style={{ fontWeight: 'normal' }}>
                                            { notificacion?.tipoAcuerdo }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                    <Grid md={4} xs={4}>
                                        <Typography gutterBottom sx={{ fontWeight: 'Bold' }} >
                                            Referencia:
                                        </Typography>
                                    </Grid>
                                    <Grid md={8} xs={8} >
                                        <Typography gutterBottom style={{ fontWeight: 'normal' }}>
                                            { notificacion?.referencia }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                    <Grid md={4} xs={4}>
                                        <Typography gutterBottom sx={{ fontWeight: 'Bold' }} >
                                            Se Notifico:
                                        </Typography>
                                    </Grid>
                                    <Grid md={8} xs={8} >
                                        <Typography gutterBottom style={{ fontWeight: 'normal' }}>
                                            { notificacion?.seNotifico ? 'Si' : 'No' }
                                            {
                                                notificacion?.seNotifico
                                                && <Typography ml={1} fontSize={14} fontWeight={'bold'}>( { notificacion?.fechaNotificacion ? moment( notificacion?.fechaNotificacion ).format('DD-MM-YYYY h:mm a') : '' } )</Typography>    
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {
                                    ( !notificacion?.seNotifico && notificacion?.estatus !== "Rechazada" && notificacion?.motivo !== '' )
                                    &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid md={4} xs={4}>
                                                <Typography gutterBottom sx={{ fontWeight: 'Bold' }} >
                                                    Motivo:
                                                </Typography>
                                            </Grid>
                                            <Grid md={8} xs={8} >
                                                <Typography gutterBottom style={{ fontWeight: 'normal' }}>
                                                    { notificacion?.motivo }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                }

                                <Grid container sx={{ mt: 1, mb: 1 }}>
                                    <Grid md={4} xs={4}>
                                        <Typography gutterBottom sx={{ fontWeight: 'Bold' }} >
                                            Estatus:
                                        </Typography>
                                    </Grid>
                                    <Grid md={8} xs={8} >
                                        <Typography gutterBottom style={{ fontWeight: 'normal' }}>
                                            { notificacion?.estatus }
                                            { ( !notificacion?.seNotifico && notificacion?.estatus === "Rechazada" ) && <Typography fontWeight={'bold'} mt={1}> ({ notificacion?.motivo }) </Typography> }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {
                                    notificacion?.fotoVivienda
                                    &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid md={12} xs={12} >
                                                <Typography gutterBottom style={{ fontWeight: 'bold' }} >
                                                    Foto de Vivienda:
                                                </Typography>
                                            </Grid>

                                            <Grid lg={12} md={12} xs={12}>

                                                <iframe
                                                    title='iframe  Demanda o Escrito Inicial'
                                                    style={{ width: '100%', height: '400px' }}
                                                    src={ notificacion?.fotoVivienda }
                                                />

                                            </Grid>
                                        </Grid>
                                }

                                {
                                    notificacion?.cedulaNotificacion
                                    &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid md={12} xs={12} >
                                                <Typography gutterBottom style={{ fontWeight: 'bold' }} >
                                                    Cédula de notificación:
                                                </Typography>
                                            </Grid>

                                            <Grid lg={12} md={12} xs={12}>

                                                <iframe
                                                    title='iframe  Demanda o Escrito Inicial'
                                                    style={{ width: '100%', height: '400px' }}
                                                    src={ notificacion?.cedulaNotificacion }
                                                />

                                            </Grid>
                                        </Grid>
                                }

                                {
                                    notificacion?.razonamiento
                                    &&
                                        <Grid container sx={{ mt: 1, mb: 1 }}>
                                            <Grid md={12} xs={12} >
                                                <Typography gutterBottom style={{ fontWeight: 'bold' }} >
                                                    Razonamiento:
                                                </Typography>
                                            </Grid>

                                            <Grid lg={12} md={12} xs={12}>

                                                <iframe
                                                    title='iframe  Demanda o Escrito Inicial'
                                                    style={{ width: '100%', height: '400px' }}
                                                    src={ notificacion?.razonamiento }
                                                />

                                            </Grid>
                                        </Grid>
                                }

                                {
                                    ( notificacion.anexos ?? [] ).length !== 0
                                    &&
                                        <Grid xs={12} md={8}>

                                            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>

                                                <Grid>
                                                    <Typography fontWeight={'bold'} level="body-md">
                                                        Anexo(s) presentado(s)
                                                    </Typography>
                                                </Grid>

                                            </Grid>

                                            <List sx={{ maxHeight: '155px', overflow: 'scroll', mt: 1 }}>

                                                {                                            
                                                    notificacion.anexos?.map( ( { id, contenido, descripcion } ) => (

                                                        <ListItem key={id} variant='soft' sx={{ py: 1 }} >

                                                            <ListItemButton onClick={ () => { setOpenDocumento( true ); setDocumento( contenido ); setDescripcion( 'Anexo' ); }}>
                                                                <ListItemContent sx={{ fontSize: 16 }}>{ descripcion }</ListItemContent>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))                                             
                                                }

                                            </List>

                                        </Grid>
                                }                             

                            </Box>                          

                        </DialogContent>

                    </ModalDialog>

                </ModalOverflow>

            </Modal>

            <Modal
                disableEscapeKeyDown
                open={openDocumento}
                onClose={(_, r) => {
                    if (r === 'backdropClick') return;
                    setOpenDocumento(false)
                }}
                sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)' }}
            >
                <ModalOverflow>

                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    >
                        <ModalClose />
                        <DialogTitle> { descripcion} </DialogTitle>

                        <DialogContent>

                            <Grid container mt={2} sx={{ width: '600px' }}>

                                <Grid xs={12}>

                                    <img alt='view-img' src={ documento } width="100%" height="90%" />

                                </Grid>

                            </Grid>

                        </DialogContent>

                    </ModalDialog>

                </ModalOverflow>

            </Modal>

        </>
    )
}
