
import { configureStore } from '@reduxjs/toolkit';

import authSlice from './slices/authSlice';
import recepcionSlice from './slices/recepcionSlice';

const store = configureStore({
    reducer: {
        auth:       authSlice,
        recepcion:  recepcionSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>

export default store