
import { useEffect, useState } from 'react';

import { Grid, Table, CircularProgress, Box, Typography, Button, Chip, Select, Option, FormControl, FormLabel, Input, IconButton, Modal, ModalOverflow, ModalDialog, ModalClose, DialogTitle, DialogContent } from '@mui/joy';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import moment from 'moment';

import { Sidebar } from '../../components/Sidebar';

import { Header } from '../../components/Header';
import { Layout } from '../../components/Layout';
import Pagination from '../../components/Pagination';

import { ModalNotificaciones } from '../../modals/ModalNotificaciones';

import { ObtenerEstatus } from '../../connections/catalogos/EstatusConnection';
import { ConsultarListadosEnvio, ListadosEnvio, ObtenerActuariosEnvia, ObtenerUsuarioRecibe } from '../../connections/comun/ListadoEnvioConnection';

import { UsuarioProps } from '../../interfaces/admin/UsuarioInterface';
import { NotificacionProps } from '../../interfaces/comun/NotificacionInterface';
import { EstatusInterface } from '../../interfaces/catalogos/EstatusInterface';
import { ListadosEnvioProps } from '../../interfaces/comun/ListadoEnvioInterface';

export const ListadoEnvioPage = () => {

    const [isLoading, setIsLoading] = useState<boolean>( true );

    const [open, setOpen] = useState<boolean>( false );
    const [openCedula, setOpenAcuseFirmado] = useState<boolean>( false );

    const [searching, setSearching] = useState<boolean>( false );

    const [page, setPage] = useState<number>( 0 );
    const [rowsPerPage, setRowsPerPage] = useState<number>( 10 );
    const [recordsCount, setRecordsCount] = useState<number>( 0 );

    const [rows, setRows] = useState<ListadosEnvioProps[]>( [] );
    const [notificaciones, setNotificaciones] = useState<NotificacionProps[]>( [] );

    const [cedulaNotificacion, setCedulaNotificacion] = useState<string>( '' );

    const [actuario, setActuario] = useState<UsuarioProps>( { id: '', idUsuario: 0, nombreCompleto: '', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' } );
    const [actuarios, setActuarios] = useState<UsuarioProps[]>( [] );

    const [usuarioRecibe, setUsuarioRecibe] = useState<UsuarioProps>( { id: '', idUsuario: 0, nombreCompleto: '', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' } );
    const [usuariosRecibe, setUsuariosRecibe] = useState<UsuarioProps[]>( [] );

    const [estatus, setEstatus] = useState<EstatusInterface>( { idEstatus: 0, descripcion: '', activo: false } );
    const [estatusArray, setEstatusArray] = useState<EstatusInterface[]>( [] );

    const [fecha, setFecha] = useState<string>( "" );

    const handleChange = ( _: React.SyntheticEvent | null, newValue: UsuarioProps | null, ) => setActuario( newValue ?? { id: '', idUsuario: 0, nombreCompleto: '', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' } )
    const handleChangeRecibe = ( _: React.SyntheticEvent | null, newValue: UsuarioProps | null, ) => setUsuarioRecibe( newValue ?? { id: '', idUsuario: 0, nombreCompleto: '', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' } )

    const handleChangeEstatus = ( _: React.SyntheticEvent | null, newValue: EstatusInterface | null, ) => setEstatus( newValue ?? { idEstatus: 0, descripcion: '', activo: false } )

    const handleBuscar = () => {

        setSearching( true );   
        setPage( 0 );

    }

    const handleClearFields = () => {

        setRows( [] );
        setPage( 0 );

        setEstatus( { idEstatus: 0, descripcion: '', activo: false } );
        setActuario( { id: '', idUsuario: 0, nombreCompleto: '', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' } );
        setUsuarioRecibe( { id: '', idUsuario: 0, nombreCompleto: '', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' } );

        setFecha( '' );
        setSearching( false );
        setIsLoading( true );

    }

    useEffect(() => {

        async function Obtener(){

            setIsLoading( true ); 

            await ListadosEnvio({ page, rowsPerPage }).then( resp => {

                setTimeout(() => {
                    
                    setRows( resp.records );
                    setRecordsCount( resp.recordsCount);
                    setIsLoading( false ); 

                }, 400);

            });

        }

        if( !searching ){
            Obtener();
        }
     
    }, [ page, rowsPerPage, searching ])  
     
    useEffect(() => {

        async function Obtener() {

          setIsLoading(true);
      
          await ConsultarListadosEnvio({ page, rowsPerPage, actuarioEnvia: actuario.idUsuario ?? 0, fecha, usuarioRecibe: usuarioRecibe.idUsuario ?? 0, estatus: estatus.idEstatus ?? 0 }).then(resp => {
         

            setTimeout(() => {

              setRows( resp.records );
              setRecordsCount( resp.recordsCount );
              setIsLoading( false );

            }, 400);
          });
        }
      
        if( searching ){
            Obtener();
        };
      }, [ page, rowsPerPage, actuario, usuarioRecibe, fecha, estatus, searching] );       

    useEffect(() => {
      
        async function obtener() {            

            await ObtenerActuariosEnvia().then( resp => {

                const defaultUsuario: UsuarioProps = { id: '', idUsuario: 0, nombreCompleto: 'Seleccione el actuario', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' }; 

                setActuarios([ defaultUsuario, ...resp ]);
            });

        }
    
        obtener();        
        
    }, [ ])   

    useEffect(() => {
      
        async function obtener() {            

            await ObtenerUsuarioRecibe().then( resp => {

                const defaultUsuario: UsuarioProps = { id: '', idUsuario: 0, nombreCompleto: 'Seleccione el usuario', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' }; 

                setUsuariosRecibe([ defaultUsuario, ...resp ]);
            });

        }
    
        obtener();        
        
    }, [ ])  

    useEffect(() => {
      
        async function obtener() {            

            await ObtenerEstatus().then( resp => {

                const defaultEstatus: EstatusInterface = { idEstatus: 0, descripcion: 'Seleccione el estatus', activo: false };

                setEstatusArray( [ defaultEstatus, ...resp.filter( (e) => e.idEstatus === 3 || e.idEstatus === 4 ) ] );
            });

        }
       
        obtener();        

    }, [ ])   

    return (   
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Sidebar />
            
            <Header />

            <Layout
                title={ 'Listado(s) de envio' }
                isQuery={ true }
            >

                <Grid container spacing={3}>  

                    <Grid xs={12} md={3}>

                    <FormControl disabled={ searching }>
                        <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>Actuario que envía</FormLabel>
                        
                        <Select
                            size='lg'
                            sx={{ width: '100%' }}                     
                            slotProps={{
                                listbox: {
                                    sx: {
                                        width: '100%',
                                        zIndex: 99999999
                                    },
                                },
                            }}      
                            value={ actuario }
                            onChange={ handleChange }
                            placeholder="Seleccione el actuario"
                        >
                            {
                                actuarios.map( ( e ) => (
                                    <Option key={ e.idUsuario } value={ e } sx={{ py: 1 }}>{ e.nombreCompleto }</Option>
                                ))
                            }

                        </Select>

                    </FormControl>

                    </Grid>

                    <Grid xs={12} md={3}>

                    <FormControl disabled={ searching }>
                        <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>Estatus</FormLabel>
                        <Select
                            size='lg'
                            sx={{ width: '100%' }}  
                            slotProps={{
                                listbox: {
                                    sx: {
                                        width: '100%',
                                        zIndex: 99999999
                                    },
                                },
                            }}   
                            value={ estatus }
                            onChange={ handleChangeEstatus }  
                            placeholder="Seleccione el estatus"
                        >                            
                            {
                                estatusArray.map( ( e ) => (
                                    <Option key={ e.idEstatus } value={ e } sx={{ py: 1 }}>{ e.descripcion }</Option>
                                ))
                            }
                        </Select>                                    
                    </FormControl>

                    </Grid>

                    <Grid xs={12} md={3}>

                        <FormControl disabled={ searching }>
                            <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>Fecha</FormLabel>
                            <Input size='lg' placeholder="Placeholder" type='date' value={ fecha } onChange={ (e) => setFecha( e.target.value ) } />
                        </FormControl>

                    </Grid>

                    <Grid xs={12} md={3}>

                        <FormControl disabled={ searching }>
                            <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>Usuario que recibe</FormLabel>
                            
                            <Select
                                size='lg'
                                sx={{ width: '100%' }}                     
                                slotProps={{
                                    listbox: {
                                        sx: {
                                            width: '100%',
                                            zIndex: 99999999
                                        },
                                    },
                                }}      
                                value={ usuarioRecibe }
                                onChange={ handleChangeRecibe }
                                placeholder="Seleccione el usuario"
                            >
                                {
                                    usuariosRecibe.map( ( e ) => (
                                        <Option key={ e.idUsuario } value={ e } sx={{ py: 1 }}>{ e.nombreCompleto }</Option>
                                    ))
                                }

                            </Select>

                        </FormControl>

                    </Grid>

                    <Grid xs={12} textAlign={'end'}>

                        <Button
                            color='neutral'
                            variant='solid'
                            size='lg'
                            startDecorator={ searching ? <SearchOffIcon /> : <SearchIcon /> }
                            onClick={ () => searching ? handleClearFields() : handleBuscar() }
                            disabled={ actuario.idUsuario === 0 && fecha === '' && estatus.idEstatus === 0 && usuarioRecibe.idUsuario === 0}
                        >
                            { searching ? 'Limpiar' : 'Buscar' }
                        </Button>

                    </Grid>
                           
                    <Grid xs={12} md={12} mt={1}>                         
                        
                        <Table 
                            aria-label="striped table" 
                            stripe={'even'} 
                            borderAxis='x'
                            size='lg' 
                            sx={{ 
                                width: { xs: '100%', md: '100%' },
                                display:{ xs: 'block', md: 'inline' },
                                overflowX: { xs: 'auto', md: 'hidden' },
                            }}
                        >      

                            <thead>

                                <tr>
                                    <th style={{ width: '15%', textAlign: 'center' }}>Fecha</th>
                                    <th style={{ width: '25%' }}>Actuario que envía</th>
                                    <th style={{ width: '25%' }}>Usuario que recibe</th>
                                    <th style={{ width: '15%', textAlign: 'center' }}>Acuse firmado</th>
                                    <th style={{ width: '15%', textAlign: 'center' }}>Número de <br /> notificaciones</th>
                                    <th style={{ width: '15%', textAlign: 'center' }}>Estatus</th>  
                                </tr>

                            </thead>

                            <tbody>

                                {

                                    isLoading
                                    ?
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '250px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </td>
                                        </tr>
                                    :   
                                        rows
                                        .map( ( row, index ) => (

                                            <tr key={ index }>     
                                                

                                                <td>{ moment( row.fecha ).format('DD-MM-YYYY h:mm a') }</td>

                                                <td>                                              
                                                    <Typography level="title-md">{ row.actuarioEnvia }</Typography>
                                                </td>

                                                <td>                                              
                                                    <Typography level="title-md">{ row.usuarioRecibe }</Typography>
                                                </td>
                                                                                            
                                                <td style={{ textAlign: 'center' }}>

                                                    {
                                                        row.acuseFirmado
                                                        &&
                                                            <IconButton variant='solid' size='md' onClick={() => { setOpenAcuseFirmado(true); setCedulaNotificacion( row.acuseFirmado ?? "") }}>
                                                                <FileCopyIcon />
                                                            </IconButton>
                                                    }

                                                </td>                    

                                                <td style={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>
                                                    
                                                    <Button 
                                                        variant="solid" 
                                                        color="neutral" 
                                                        size='sm'
                                                        endDecorator={ <FormatListBulletedIcon /> }
                                                        onClick={ () => { setNotificaciones( row?.notificaciones ?? [] ); setOpen( true ); } }
                                                    >
                                                        { row.notificaciones?.length }
                                                    </Button>                                            
                                                    
                                                </td> 

                                                <td style={{ textAlign: 'center' }}>
                                                    <Chip variant='outlined' size='lg'>
                                                        { row.estatus}
                                                    </Chip>
                                                </td>                                                                                       

                                            </tr>
                                        ))
                                }

                                {
                                    ( !isLoading && rows.length === 0 )
                                    &&
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '50px' }}>
                                                <Typography fontSize={14} fontWeight={'bold'}>No se encontraron registros</Typography>
                                            </td>
                                        </tr>
                                }

                            </tbody>                          

                        </Table>       

                    </Grid>

                </Grid>
                
                <Pagination
                    gotoPage={ setPage }
                    length={ recordsCount }
                    pageSize={ rowsPerPage }
                    setPageSize={ setRowsPerPage }       
                    loading={ isLoading }                     
                />

            </Layout>

            <Modal
                disableEscapeKeyDown
                open={ openCedula }
                onClose={(_, r) => {
                    if (r === 'backdropClick') return;
                    setOpenAcuseFirmado(false)
                }}
                sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)' }}
            >
                <ModalOverflow>

                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    >
                        <ModalClose />
                        <DialogTitle> Acusé </DialogTitle>

                        <DialogContent>

                            <Grid container mt={2} sx={{ width: '800px' }}>

                                <Grid xs={12}>

                                    <iframe title='acuse' src={ cedulaNotificacion } height="500px" width="100%"></iframe>

                                </Grid>

                            </Grid>

                        </DialogContent>

                    </ModalDialog>

                </ModalOverflow>

            </Modal>
            
            <ModalNotificaciones 
                open={ open }
                setOpen={ setOpen}
                notificaciones={ notificaciones }
            />  

        </Box>
    );
}
