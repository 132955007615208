import { useEffect, useState } from "react";

import { Modal, ModalOverflow, ModalDialog, ModalClose, DialogTitle, DialogContent, Grid, Typography, CircularProgress } from "@mui/joy";

import { APIProvider, Map } from "@vis.gl/react-google-maps";

import { MapProps } from "../interfaces/global/GeolocalizarInterface";

import { NotificacionFullProps } from "../interfaces/comun/NotificacionInterface";
import { ZonaNotificacionProps } from "../interfaces/comun/RutaInterface";
import { MarkerNotification } from "../components/MarkerNotification";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    zonaNotificacion: ZonaNotificacionProps;
    notificaciones: NotificacionFullProps[];     
}

export const ModalMapa = ( { open, setOpen, zonaNotificacion, notificaciones } : Props ) => {
    
    const [isLoadingMapa, setIsLoadingMapa] = useState<boolean>( true );

    const [{ center, zoom }, setMap] = useState<MapProps>({
        center: { lat: 27.2113713, lng: -101.9014431 },
        zoom: 7        
    });

    useEffect(() => {

        setIsLoadingMapa( true );

        if( notificaciones.length !== 0 ){
            setMap({
                //center: { lat: notificaciones[0].lat ?? 0, lng: notificaciones[0].lng ?? 0 },
                center: { lat: notificaciones[notificaciones.length - 1].lat ?? 0, lng: notificaciones[notificaciones.length - 1].lng ?? 0 },
                zoom: 12        
            });
        }
      
        setTimeout(() => {
            setIsLoadingMapa( false );
        }, 400);
        
    }, [ notificaciones ])

    return (       
        <Modal             
            disableEscapeKeyDown                
            open={ open }
            onClose={ ( _, r ) =>{ 
                if( r === 'backdropClick' ) return;                     
                setOpen( false )
            }}
            sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
        >
            <ModalOverflow>
            
                <ModalDialog
                    aria-labelledby="server-modal-title"
                    aria-describedby="server-modal-description"
                    layout="center"
                    sx={{ width: '70vw' }}
                >
                    <ModalClose onClick={ () => setMap({ center: { lat: 27.2113713, lng: -101.9014431 }, zoom: 7 }) } />
                   
                    <DialogTitle> 
                        <Typography level="h1">Zona - { zonaNotificacion.zona }</Typography>
                    </DialogTitle>

                    <DialogContent>   

                         {
                            isLoadingMapa
                            ?
                                <Grid container mt={1}>
                                    <Grid xs={12} sx={{ textAlign: 'center', p: '300px' }}>
                                        <CircularProgress color='neutral' size='md' />
                                    </Grid>
                                </Grid>
                            :   
                                <Grid container mt={1}>      
                                    
                                    <Grid xs={12}>  

                                        <APIProvider apiKey={ process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '' }>

                                            <Map
                                                mapId={'5cb0f0703c7dc687'}
                                                style={{ height: '80vh' }}
                                                center={ center }
                                                zoom={ zoom }                         
                                                gestureHandling={ 'greedy' }                                            
                                                onCameraChanged={ ( e ) =>  
                                                    setMap({
                                                        center: e.detail.center,
                                                        zoom: e.detail.zoom       
                                                    })
                                                }          
                                            >
                                                {
                                                    notificaciones.map( ( e, index ) => (

                                                        <MarkerNotification   
                                                            key={ index }                                            
                                                            latitud={ e?.lat ?? 0 }
                                                            longitud={ e?.lng ?? 0 }
                                                            notificacion={ e }     
                                                            setMap={ setMap }      
                                                        />

                                                    ))
                                                }
                                                    
                                            </Map>

                                        </APIProvider>                        
                                    
                                    </Grid>
                                
                                </Grid>      
                        }                

                    </DialogContent>         

                </ModalDialog>
                
            </ModalOverflow>

        </Modal>
    )
}
