import HttpClienteToken from '../../services/HttpClientToken';

import { DistritoJudicialInterface } from '../../interfaces/catalogos/DistritoJudicialInterface';

export const ObtenerDistritosJudiciales = () => {

    return new Promise<DistritoJudicialInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/DistritoJudicial/ObtenerDistritosJudiciales', token)
            .then( ({ data } : { data : DistritoJudicialInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const DistritoJudicial = () => {

    return new Promise<DistritoJudicialInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/DistritoJudicial/', token)
            .then( ({ data } : { data : DistritoJudicialInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarDistritoJudicial = ( params: DistritoJudicialInterface ) => {

    return new Promise<DistritoJudicialInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/DistritoJudicial/Actualizar', params, token)
            .then( ({ data } : { data : DistritoJudicialInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idDistritoJudicial: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarDistritoJudicial = ( params: DistritoJudicialInterface ) => {

    return new Promise<DistritoJudicialInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/DistritoJudicial/Agregar', params, token)
            .then( ({ data } : { data : DistritoJudicialInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idDistritoJudicial: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}