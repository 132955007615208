import HttpClienteToken from '../../services/HttpClientToken';

import { TipoDomicilioInterface } from '../../interfaces/catalogos/TipoDomicilioInterface';

export const ObtenerTiposDomicilios = () => {

    return new Promise<TipoDomicilioInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoDomicilio/ObtenerTiposDomicilios', token)
            .then( ({ data } : { data : TipoDomicilioInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const TiposDomicilios = () => {

    return new Promise<TipoDomicilioInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoDomicilio/', token)
            .then( ({ data } : { data : TipoDomicilioInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarTipoDomicilio = ( params: TipoDomicilioInterface ) => {

    return new Promise<TipoDomicilioInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoDomicilio/Actualizar', params, token)
            .then( ({ data } : { data : TipoDomicilioInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoDomicilio: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarTipoDomicilio= ( params: TipoDomicilioInterface ) => {

    return new Promise<TipoDomicilioInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoDomicilio/Agregar', params, token)
            .then( ({ data } : { data : TipoDomicilioInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoDomicilio: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}