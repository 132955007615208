import { useState } from "react";

import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

import { Box, Stack, Typography, FormControl, FormLabel, Input, Link, Button, IconButton, Snackbar } from "@mui/joy";

import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WarningIcon from "@mui/icons-material/Warning";

import { login, stateProps } from "../../store/slices/authSlice";

import { SnackbarProps } from "../../interfaces/ui/Snackbar";

import { Login } from "../../connections/auth/AuthConnection";

export const LoginPage = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [correoElectronico, setCorreoElectronico] = useState<string>('');
    const [contrasena, setContrasena] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isVisibility, setIsVisibility] = useState<boolean>(false);

    const [{ type: typeSnackbar, open: openMessage, message }, setOpenMessage] = useState<SnackbarProps>({
        type: 'warning',
        message: '',
        open: false,
    });

    const handleClose = () => setOpenMessage({ type: typeSnackbar, open: false, message })

    const handleLogin = async ( e: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {

        e.preventDefault();

        const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(correoElectronico);

        if(!correoElectronico ){
            setOpenMessage({ type: 'warning', open: true, message: 'Debes escribir el correo electrónico' });
            return;
        }
        else if(!validEmail){   
            setOpenMessage({ type: 'warning', open: true, message: 'El correo electrónico no es valido' });
            return;
        }
        else if(!contrasena ){
            setOpenMessage({ type: 'warning', open: true, message: 'Debes escribir la contraseña' });
            return;
        }
        else {

            setIsLoading( true );

            await Login({ correoElectronico, contrasena }).then( resp => {

                if( resp.success ){

                    const data: stateProps = {
                        nombres: resp?.nombres ?? '',
                        apellidos: resp?.apellidos ?? '',
                        token: resp?.token ?? '',
                        administrador: resp?.administrador ?? false,
                        restablecerContrasena: resp?.restablecerContrasena ?? false,
                        correoElectronico: correoElectronico,
                        puesto: resp.puesto ?? 0,
                        permisos: resp?.permisos,
                    };

                    setTimeout(() => {
                  
                        dispatch( login( data ) );
                        window.localStorage.setItem('data', JSON.stringify(data));
    
                        setIsLoading( false );
                        
                    }, 400);

                }
                else{

                    setTimeout(() => {
                        
                        const errorMessage: string = resp?.message ?? '';
    
                        setOpenMessage({ type: 'danger', open: true, message: errorMessage });
                        setIsLoading( false );
                        
                    }, 400);

                }
            });      

        }

    }

    return (
        <>
            <Snackbar
                key={ 'snackbar-login' }
                autoHideDuration={2000}
                color="warning"
                size="md"
                variant="solid"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={ openMessage }
                onClose={ handleClose }
                startDecorator={ <WarningIcon /> }
            >
                { message }
            </Snackbar>

            <Box
                  sx={{
                        height: "100%",
                        position: "fixed",
                        right: 0,
                        top: 0,
                        bottom: 0,
                        left: { xs: 0, md: "40vw" },
                        transition:
                        "background-image var(--Transition-duration), left var(--Transition-duration) !important",
                        transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
                        backgroundColor: "#fff",
                        backgroundSize: "80%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: "url(" + process.env.PUBLIC_URL + "/logos/Logo_Horizontal.png)",
                        display: { sm: "none", md: "block" },
                  }}
              >
                <Box
                    sx={{
                        py: 3,
                        position: "absolute",
                        bottom: 0,
                        top: "90%",
                        left: "55%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <img
                        src={ process.env.PUBLIC_URL + "/logos/navegacion-logotipo.png"}
                        alt={"PJECZ"}
                        style={{ width: "80%" }}
                    />
                </Box>

            </Box>

            <Box
                sx={{
                    width: { xs: "100%", md: "40vw" },
                    transition: "width var(--Transition-duration)",
                    transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
                    position: "relative",
                    left: 0,
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    backdropFilter: "blur(12px)",
                    backgroundColor: "#004360",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "100dvh",
                        width: "100%",
                        px: 2,
                    }}
                >

                    <Box
                        component="main"
                        sx={{
                            my: "auto",
                            py: 2,
                            pb: 5,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            width: 400,
                            maxWidth: "100%",
                            mx: "auto",
                            borderRadius: "sm",
                            "& form": {
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            },
                            [`& .MuiFormLabel-asterisk`]: {
                                visibility: "hidden",
                            },
                        }}
                    >
                        <Stack gap={4} sx={{ mb: 2 }}>

                            <Stack gap={1}>
                                <Typography
                                    component="h1"
                                    level="h1"
                                    textColor={"common.white"}
                                >
                                    Inicie sesión
                                </Typography>
                            </Stack>

                        </Stack>

                        <form>

                            <Stack gap={4} sx={{ mt: 2 }}>

                                <FormControl required>
                                    <FormLabel sx={{ color: "white", fontSize: 16 }}>
                                        Correo electrónico
                                    </FormLabel>
                                    <Input 
                                        type="email" 
                                        name="email" 
                                        size="lg" 
                                        autoComplete="off" 
                                        value={ correoElectronico } 
                                        onChange={ (e) => setCorreoElectronico( e.target.value ) }  
                                    />

                                </FormControl>

                                <FormControl required>

                                    <FormLabel sx={{ color: "white", fontSize: 16 }}>
                                        Contraseña
                                    </FormLabel>
                                    <Input 
                                        type={ isVisibility ? "text" : "password" }
                                        name="password" 
                                        size="lg" 
                                        autoComplete="off" 
                                        endDecorator={ 
                                            <IconButton onClick={ () => setIsVisibility( ( prev ) => !prev ) }> 
                                                {
                                                    isVisibility
                                                    ?
                                                        <VisibilityIcon /> 
                                                    :
                                                        <VisibilityOffIcon />
                                                }
                                            </IconButton> 
                                        }
                                        value={ contrasena } 
                                        onChange={ (e) => setContrasena( e.target.value ) }  
                                    />

                                </FormControl>

                                <Stack gap={4} sx={{ mt: 2 }}>

                                    <Button
                                        type="submit"
                                        size="lg"
                                        fullWidth
                                        sx={{
                                            bgcolor: "black",
                                            ":hover": { bgcolor: "rgba(0, 0, 0, 0.5)" },
                                        }}
                                        startDecorator={ <LoginOutlinedIcon /> }
                                        loading={ isLoading }
                                        onClick={ handleLogin }
                                    >
                                        Ingresar
                                    </Button>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "center",
                                        }}
                                    >
                                    
                                        <Link
                                            sx={{
                                                color: "white",
                                                textDecoration: "none",
                                                fontSize: 14,
                                                ":hover": { textDecoration: "none" },
                                            }}
                                            component='button'
                                            onClick={ () => navigate('/auth/forgot-password/') }
                                        >
                                            ¿Olvidaste la contraseña?
                                        </Link>

                                    </Box>
                                
                                </Stack>

                            </Stack>

                        </form>

                    </Box>

                </Box>

            </Box>
        </>
    );
};
