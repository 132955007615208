
export const convertirHora = ( hora: number ) => {

    const segundos = ( Math.round( hora % 0x3C ) ).toString();
    const minutos  = ( Math.floor( hora / 0x3C ) % 0x3C ).toString();
    const horas    = ( Math.floor( hora / 0xE10 ) ).toString();
    
    if( horas !== '' && horas !== '0' ){
        return `${horas} hrs, ${minutos} min`;     
    }
    else if( horas === '0' && minutos !== '' && minutos !== '0' && segundos !== '0' ){           
        return `${minutos} min, ${segundos} s`;                 
    }   
    else if( horas === '0' && minutos !== '' && minutos !== '0' && segundos === '0' ){           
        return `${minutos} min`;                 
    }   
    else if( minutos === '0' && segundos !== '' && segundos !== '0' ){           
        return `${segundos} s`;                 
    }
    
    return '';       
}