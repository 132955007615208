
import { ListItem, Typography, ListItemContent, ListItemDecorator, Chip, IconButton } from '@mui/joy';

import { Draggable } from '@hello-pangea/dnd';

import RoomIcon from '@mui/icons-material/Room';

import { NotificacionFullProps } from '../interfaces/comun/NotificacionInterface';

export type DraggableListItemProps = {
    item: NotificacionFullProps;
    index: number;
    onClickMarker?: () => void;
};

const DraggableListItem = ({ item, index, onClickMarker }: DraggableListItemProps) => {

    return (
        <Draggable draggableId={ item.id ?? '' } index={ index }>

            {
                ( provided, snapshot ) => (      
             
                    <ListItem 
                        ref={ provided.innerRef }
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}                      
                        sx={{ background: snapshot.isDropAnimating ? 'rgb(235, 235, 235)' : '', p: 1 }}            
                        endAction={ <IconButton onClick={ onClickMarker } sx={{ mr: 2 }} variant='outlined' size='sm'> <RoomIcon /> </IconButton> }
                    >         

                        <ListItemDecorator>
                            <Chip variant='outlined'>{ ( item.indice ?? 0 ) + 1 }</Chip>
                        </ListItemDecorator>    

                        <ListItemContent>
                            <Typography level="body-md" fontSize={14} fontWeight={'lg'} sx={{ width: 260 }}>
                                { item.domicilio }
                            </Typography>
                        </ListItemContent>

                    </ListItem>

                )
            }
            
        </Draggable>
    );
};

export default DraggableListItem;
