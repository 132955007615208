
export const curpFechaNacimiento = (curp: string ) : Date | null => {
    var m = curp.match( /^\w{4}(\w{2})(\w{2})(\w{2})/ );
    
    if( m === null ){
        return null;
    }

    var anyo = parseInt(m[1],10) + 1900;
    
    if( anyo < 1950 ) anyo += 100;

    var mes = parseInt(m[2], 10)-1;
    var dia = parseInt(m[3], 10);

    return (new Date( anyo, mes, dia ));
}

export const curpValida = ( curp: string ) => {
    const re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    
    const validado = curp.match(re);
	
    if ( !validado ){
    	return false; 
    }   

    if ( validado[2] !== digitoVerificador( validado[1] ).toString() ) {
    	return false;
    }
        
    return true;
}

const digitoVerificador = ( curp17: string ) => {
       
    var diccionario  = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
        lngSuma      = 0.0,
        lngDigito    = 0.0;
    for(var i=0; i<17; i++)
        lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
    lngDigito = 10 - lngSuma % 10;
    if (lngDigito === 10) return 0;

    return lngDigito;
}