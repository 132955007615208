import HttpClienteToken from '../../services/HttpClientToken';

import { RolInterface } from '../../interfaces/catalogos/RolInterface';

export const ObtenerRoles = () => {

    return new Promise<RolInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Rol/ObtenerRoles', token)
            .then( ({ data } : { data : RolInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const Rol = () => {

    return new Promise<RolInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Rol/', token)
            .then( ({ data } : { data : RolInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarRol = ( params: RolInterface ) => {

    return new Promise<RolInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Rol/Actualizar', params, token)
            .then( ({ data } : { data : RolInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idRol: 0,
                    nombre: '',     
                    descripcion: '',
                    activo: false,      
                });
            });
        }

    });

}

export const AgregarRol = ( params: RolInterface ) => {

    return new Promise<RolInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Rol/Agregar', params, token)
            .then( ({ data } : { data : RolInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idRol: 0,
                    nombre: '',     
                    descripcion: '',
                    activo: false,         
                });
            });
        }

    });

}