import httpClienteToken from '../../services/HttpClientToken';

import { AxiosError } from 'axios';

import { HttpResponse } from '../../interfaces/global/HttpInterface';

import { ActualizarApiKeyEstatusParams, ActualizarApiKeyParams, ActualizarApiKeyResponse, AgregarApiKeyParams, AgregarApiKeyResponse, ApiKeyProps } from '../../interfaces/admin/ApiKeysInterface';

export const ObtenerApiKeys = () => {

    return new Promise<ApiKeyProps[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            httpClienteToken.post('/Api/ApiKeys/ObtenerApiKeys/', null, token)
            .then( ({ data } : { data : ApiKeyProps[]} ) => {
                resolve( data );
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarApiKeyEstatus = ( params: ActualizarApiKeyEstatusParams ) => {

    return new Promise<HttpResponse>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            httpClienteToken.post('/Api/ApiKeys/ActualizarApiKeyEstatus/', params, token)
            .then( ({ data } : { data : HttpResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: HttpResponse = {
                    success: false,
                    message: error.message,
                };
    
                resolve( data );
            });
        }

    });

}

export const AgregarApiKey = ( params: AgregarApiKeyParams ) => {

    return new Promise<AgregarApiKeyResponse>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            httpClienteToken.post('/Api/ApiKeys/AgregarApiKey/', params, token)
            .then( ({ data } : { data : AgregarApiKeyResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: AgregarApiKeyResponse = {
                    success: false,
                    message: error.message,                    
                };
    
                resolve( data );
            });
        }

    });

}

export const ActualizarApiKey = ( params : ActualizarApiKeyParams) => {

    return new Promise<ActualizarApiKeyResponse>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            httpClienteToken.post('/Api/ApiKeys/ActualizarApiKey/', params, token)
            .then( ({ data } : { data : AgregarApiKeyResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: AgregarApiKeyResponse = {
                    success: false,
                    message: error.message,
                };
    
                resolve( data );
            });
        }

    });

}