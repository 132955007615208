import { useState } from "react";

import { Box, Divider, Typography } from "@mui/joy";

import { useAdvancedMarkerRef, AdvancedMarker, InfoWindow, Pin } from "@vis.gl/react-google-maps";

import { NotificacionFullProps } from '../interfaces/comun/NotificacionInterface';
import { MapProps } from "../interfaces/global/GeolocalizarInterface";

interface Props {
    latitud:        number;
    longitud:       number;
    notificacion:   NotificacionFullProps;
    setMap?:         React.Dispatch<React.SetStateAction<MapProps>>;
    viewPin?:       boolean;
    label?:         string;
}

export const MarkerNotification = ( { latitud, longitud, notificacion, setMap, viewPin, label } : Props ) => {

    const [infowindowOpen, setInfowindowOpen] = useState( false );
    const [markerRef, marker] = useAdvancedMarkerRef();

    const { domicilio, persona } = notificacion;

    return (
       <>      
            <AdvancedMarker            
                ref={ markerRef }
                position={{ lat: latitud, lng: longitud }}
                onClick={ () => {                  
                    
                    if( setMap ){
                        setMap({
                            center: { lat: latitud, lng: longitud },
                            zoom: 14       
                        });   
                    }

                    setInfowindowOpen( ( prev ) => !prev ); 
                }}
            >
                {
                    viewPin
                    &&
                        <Pin>
                            <Typography fontWeight={'bold'} fontSize={14} sx={{ color: 'white' }}>
                                { label }
                            </Typography>
                        </Pin>
                }
            </AdvancedMarker>                        
                
            {
                infowindowOpen 
                && 
                    <InfoWindow
                        anchor={ marker }
                        maxWidth={ 200 }
                        onCloseClick={ () => {
                            setInfowindowOpen( false );    
                        }}
                    >
                        <Box px={1} py={1}>
                            <Typography level="body-md" fontWeight='bold'>
                                { persona ?? '' }
                            </Typography>

                            <Divider sx={{ my: 1 }} />

                            <Typography level="body-sm">
                                { domicilio ?? '' }
                            </Typography>
                        </Box>
                    </InfoWindow>
            }    
       </>
    )
}
