import { useState } from "react";

import { Modal, ModalOverflow, ModalDialog, ModalClose, DialogTitle, DialogContent, Grid, Table, Typography, Box, IconButton, Chip } from "@mui/joy";

import FileCopyIcon from '@mui/icons-material/FileCopy';

import Pagination from "../components/Pagination";

import { NotificacionFullProps } from "../interfaces/comun/NotificacionInterface";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    notificaciones: NotificacionFullProps[];
}

export const ModalRutaNotificaciones = ( { open, setOpen, notificaciones } : Props ) => {

    const [openDocumento, setOpenDocumento] = useState<boolean>( false );
    const [documento, setDocumento] = useState<string>( '' );

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState( 5 );

    return (

        <>
            <Modal             
                disableEscapeKeyDown                
                open={ open }
                onClose={ ( _, r ) =>{ 
                    if( r === 'backdropClick' ) return; 
                    
                    setOpen( false )} 
                }
                sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
            >
                <ModalOverflow>
                
                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    >
                        <ModalClose />
                        <DialogTitle> Notificaciones </DialogTitle>

                        <DialogContent>      

                            <Grid container >                   

                                <Grid xs={12}>  

                                    <Table aria-label="striped table" stripe={'even'} size='md' sx={{ width: '1300px' }}>      

                                        <thead>

                                            <tr>
                                                <th style={{ width: '20%' }}>Persona a notificar</th>
                                                <th style={{ width: '20%' }}>Domicilio</th>
                                                <th style={{ width: '10%' }}>Tipo de <br /> notificación</th>
                                                <th style={{ width: '10%' }}>Referencia</th>    
                                                <th style={{ width: '8%' }}>Tipo de <br /> acuerdo</th>
                                                <th style={{ width: '8%', textAlign: 'center' }}>Cédula de <br /> notificación</th>
                                                <th style={{ width: '10%', textAlign: 'center' }}>Razonamiento</th>
                                                <th style={{ width: '14%', textAlign: 'center' }}>Estatus</th>
                                            </tr>

                                        </thead>

                                        <tbody>

                                            {
                                                (
                                                    rowsPerPage > 0
                                                    ? notificaciones.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                                    : notificaciones
                                                )
                                                .map( ( row, index ) => (
                                                    <tr key={ index }>
                                                                                                        
                                                        <td>
                                                            <Typography fontSize={14} fontWeight='bold'> { row.persona } </Typography>
                                                            <Typography fontSize={14}> ({ row.tipoParte }) </Typography>                                         
                                                        </td>
                                                    
                                                        <td>{ row.domicilio }</td>
                                                    
                                                        <td>{ row.tipoNotificacion }</td>                                                        

                                                        <td>{ row.referencia }</td>                                                                                                                
                                                        
                                                        <td>{ row.tipoAcuerdo }</td>        

                                                        <td style={{ textAlign: 'center' }}>
                                                            <IconButton variant='solid' size='md' onClick={() => { setOpenDocumento(true); setDocumento( row.cedulaNotificacion ?? "") }}>
                                                                <FileCopyIcon />
                                                            </IconButton>
                                                        </td>

                                                        <td style={{ textAlign: 'center' }}>
                                                            {
                                                                row.razonamientoFirmado
                                                                &&
                                                                    <IconButton variant='solid' size='md' onClick={() => { setOpenDocumento(true); setDocumento( row.razonamientoFirmado ?? "") }}>
                                                                        <FileCopyIcon />
                                                                    </IconButton>
                                                            }
                                                        </td>               

                                                        <td style={{ textAlign: 'center' }}>
                                                            <Chip variant='outlined'>
                                                                { row.estatus }
                                                            </Chip>
                                                        </td>                               

                                                    </tr>
                                                ))
                                            }

                                        </tbody>

                                    </Table>
                                
                                </Grid>
                            
                            </Grid>                           

                            <Box mt={2}>
                        
                                <Pagination 
                                    gotoPage={ setPage }
                                    length={ notificaciones.length }
                                    pageSize={ rowsPerPage }
                                    setPageSize={ setRowsPerPage }     
                                    showPerPages={ false }                       
                                />
                                
                            </Box>   

                        </DialogContent>                  

                    </ModalDialog>
                    
                </ModalOverflow>

            </Modal>

            <Modal
                disableEscapeKeyDown
                open={ openDocumento }
                onClose={(_, r) => {
                    if (r === 'backdropClick') return;
                    setOpenDocumento(false)
                }}
                sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)' }}
            >
                <ModalOverflow>

                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    >
                        <ModalClose />
                        <DialogTitle> Documento </DialogTitle>

                        <DialogContent>

                            <Grid container mt={2} sx={{ width: '800px' }}>

                                <Grid xs={12}>

                                    <iframe title='acuse' src={ documento } height="500px" width="100%"></iframe>

                                </Grid>

                            </Grid>

                        </DialogContent>

                    </ModalDialog>

                </ModalOverflow>

            </Modal>        
        
        </>
        
    )
}
