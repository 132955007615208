import HttpClienteToken from '../../services/HttpClientToken';

import { EstadoInterface } from '../../interfaces/catalogos/EstadoInterface';

export const ObtenerEstados = () => {

    return new Promise<EstadoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Estado/ObtenerEstados/', token)
            .then( ({ data } : { data : EstadoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const Estados = () => {

    return new Promise<EstadoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Estado/', token)
            .then( ({ data } : { data : EstadoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarEstado = ( params: EstadoInterface ) => {

    return new Promise<EstadoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Estado/Actualizar', params, token)
            .then( ({ data } : { data : EstadoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idEstado: 0,
                    clave: '',
                    descripcion: '',
                    activo: false,
                    idPais: 0,                    
                });
            });
        }

    });

}

export const AgregarEstado = ( params: EstadoInterface ) => {

    return new Promise<EstadoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Estado/Agregar', params, token)
            .then( ({ data } : { data : EstadoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idEstado: 0,
                    clave: '',
                    descripcion: '',
                    activo: false,
                    idPais: 0,                
                });
            });
        }

    });

}