export const ObtenerClaveEntidad = ( Descripcion: string ) => {     
    
    const entidades_federativas = [
        { estado: 'Aguascalientes', clave: 'AS' },
        { estado: 'Baja California', clave:  'BC' },
        { estado: 'Baja California Sur', clave:  'BS' },
        { estado: 'Campeche', clave:  'CC' },
        { estado: 'Coahuila de Zaragoza', clave:  'CL' },
        { estado: 'Colima', clave:  'CM' },
        { estado: 'Chiapas', clave:  'CS' }, 
        { estado: 'Chihuahua', clave:  'CH' },
        { estado: 'Ciudad de México', clave:  'DF' },
        { estado: 'Durango', clave:  'DG' },
        { estado: 'Guanajuato', clave:  'GT' }, 
        { estado: 'Guerrero', clave:  'GR' },
        { estado: 'Hidalgo', clave:  'HG' },
        { estado: 'Jalisco', clave:  'JC' },
        { estado: 'México', clave:  'MC' }, 
        { estado: 'Michoacán de Ocampo', clave:  'MN' },
        { estado: 'Morelos', clave:  'MS' }, 
        { estado: 'Nayarit', clave:  'NT' },
        { estado: 'Nuevo León', clave:  'NL' }, 
        { estado: 'Oaxaca', clave:  'OC' },
        { estado: 'Puebla', clave:  'PL' },
        { estado: 'Querétaro', clave:  'QT' },
        { estado: 'Quintana Roo', clave:  'QR' }, 
        { estado: 'San Luis Potosí', clave:  'SP' },
        { estado: 'Sinaloa', clave:  'SL' }, 
        { estado: 'Sonora', clave:  'SR' },
        { estado: 'Tabasco', clave:  'TC' }, 
        { estado: 'Tamaulipas', clave:  'TS' },
        { estado: 'Tlaxcala', clave:  'TL' }, 
        { estado: 'Veracruz de Ignacio de la Llave', clave:  'VZ' },
        { estado: 'Yucatán', clave:  'YN' }, 
        { estado: 'Zacatecas', clave:  'ZS' }
    ];
    
    const entidad_federativa = entidades_federativas.find( element => { return element.estado === Descripcion });

    return entidad_federativa;
}

export const ObtenerDescripcionEntidad = ( clave: string ) => {     
    
    const entidades_federativas = [
        { id: 1, estado: 'Aguascalientes', clave: 'AS' },
        { id: 2, estado: 'Baja California', clave:  'BC' },
        { id: 3, estado: 'Baja California Sur', clave:  'BS' },
        { id: 4, estado: 'Campeche', clave:  'CC' },
        { id: 5, estado: 'Coahuila de Zaragoza', clave:  'CL' },
        { id: 6, estado: 'Colima', clave:  'CM' },
        { id: 7, estado: 'Chiapas', clave:  'CS' }, 
        { id: 8, estado: 'Chihuahua', clave:  'CH' },
        { id: 9, estado: 'Ciudad de México', clave:  'DF' },
        { id: 10, estado: 'Durango', clave:  'DG' },
        { id: 11, estado: 'Guanajuato', clave:  'GT' }, 
        { id: 12, estado: 'Guerrero', clave:  'GR' },
        { id: 13, estado: 'Hidalgo', clave:  'HG' },
        { id: 14, estado: 'Jalisco', clave:  'JC' },
        { id: 15, estado: 'México', clave:  'MC' }, 
        { id: 16, estado: 'Michoacán de Ocampo', clave:  'MN' },
        { id: 17, estado: 'Morelos', clave:  'MS' }, 
        { id: 18, estado: 'Nayarit', clave:  'NT' },
        { id: 19, estado: 'Nuevo León', clave:  'NL' }, 
        { id: 20, estado: 'Oaxaca', clave:  'OC' },
        { id: 21, estado: 'Puebla', clave:  'PL' },
        { id: 22, estado: 'Querétaro', clave:  'QT' },
        { id: 23, estado: 'Quintana Roo', clave:  'QR' }, 
        { id: 24, estado: 'San Luis Potosí', clave:  'SP' },
        { id: 25, estado: 'Sinaloa', clave:  'SL' }, 
        { id: 26, estado: 'Sonora', clave:  'SR' },
        { id: 27, estado: 'Tabasco', clave:  'TC' }, 
        { id: 28, estado: 'Tamaulipas', clave:  'TS' },
        { id: 29, estado: 'Tlaxcala', clave:  'TL' }, 
        { id: 30, estado: 'Veracruz de Ignacio de la Llave', clave:  'VZ' },
        { id: 31, estado: 'Yucatán', clave:  'YN' }, 
        { id: 32, estado: 'Zacatecas', clave:  'ZS' }
    ];
    
    const entidad_federativa = entidades_federativas.find( element => { return element.clave === clave });

    return entidad_federativa;
}