
export const FormatPhoneNumber = ( value: string ) => {

    let format = '';

    if( value ){

        format = '(' + value.substring(0, 3) + ') ' + value.substring(3, 6) + '-' + value.substring(6);
    }

    return format;
}

export const ClearFormatPhoneNumber = ( value: string ) => {

    let format = '';

    if( value ){

        format = value.replace(/[&#,+()$~%.'":*?<>{}-]/g, '').replace(' ', '');
    }

    return format;
}