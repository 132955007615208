import { useEffect, useState } from "react";

import { Box, Button, Card, CardActions, CardContent, Chip, Grid, IconButton, Option, Select, Typography } from "@mui/joy"

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import MapIcon from '@mui/icons-material/Map';

import moment from "moment";

import { ObtenerActuariosRuta } from "../connections/comun/RutaConnection";

import { NotificacionFullProps } from "../interfaces/comun/NotificacionInterface";
import { ActuariosRutaProps, ZonaNotificacionProps } from "../interfaces/comun/RutaInterface";

interface Props {
    zonaNotificacion: ZonaNotificacionProps;
    onClick: ( zona: ZonaNotificacionProps, notificaciones: NotificacionFullProps[], allNotifications: NotificacionFullProps[] ) => void;
    onChangeActuario: ( zona: ZonaNotificacionProps, actuario: ActuariosRutaProps[] ) => void
    onViewMapa: ( zona: ZonaNotificacionProps, notificaciones: NotificacionFullProps[] ) => void
}

export const CardZona = ( { zonaNotificacion, onClick, onChangeActuario, onViewMapa }: Props ) => {

    const { idZona, zona, numeroNotificaciones, notificacionesUbicadas, notificacionesNoUbicadas, notificaciones, actuarios } = zonaNotificacion;
    
    const [actuariosDisponibles, setActuariosDisponibles] = useState<ActuariosRutaProps[]>( [] );

    const handleChange = ( _: React.SyntheticEvent | null, newValue: Array<ActuariosRutaProps> | null, ) => onChangeActuario( zonaNotificacion, newValue ?? [] )

    useEffect(() => {
      
        async function obtener() {            

            await ObtenerActuariosRuta( idZona ?? 0 ).then( resp => {
                setActuariosDisponibles( resp );
            });

        }

        if( notificaciones?.length !== 0 && numeroNotificaciones === notificacionesUbicadas ){
            obtener();
        }

    }, [ notificaciones, numeroNotificaciones, notificacionesUbicadas, idZona ])    

    return (
        <Card variant="outlined" color="neutral" invertedColors>
                         
            <CardContent orientation="horizontal">
        
                <CardContent>

                    <Grid container>
                   
                        <Grid xs={10}>
                            <Typography level="h2"> Zona - { zona } </Typography>
                        </Grid>

                        <Grid xs={2} textAlign={'end'}>
                            <IconButton
                                color="neutral"
                                size="lg"
                                disabled={ notificacionesUbicadas === 0 }
                                onClick={ () => onViewMapa( zonaNotificacion, notificaciones?.filter( (e) => e.ubicacion === true ) ?? [] ) }
                            >
                                <MapIcon />
                            </IconButton>
                        </Grid>

                    </Grid>

                    <Grid container mt={2}>

                        <Grid xs={8}>
                            <Typography level="body-md" fontSize={26}>Total de notificaciones:</Typography>
                        </Grid>

                        <Grid xs={4}>
                            <Button
                                sx={{ color: 'black', fontSize: 32, fontWeight: 'bold'  }}
                                variant="plain"
                                disabled={ numeroNotificaciones === 0 }
                                onClick={ () => onClick( zonaNotificacion, notificaciones ?? [], notificaciones ?? [] ) } 
                            >
                                { numeroNotificaciones }
                            </Button>
                        </Grid>

                    </Grid>

                </CardContent>

            </CardContent>

            <CardActions>

                <Grid container spacing={1} sx={{ width: '100%' }}>

                    <Grid xs={12} sm={6}>

                        <Button 
                            fullWidth 
                            variant="outlined" 
                            color="success" 
                            size="sm" 
                            onClick={ () => onClick( zonaNotificacion, notificaciones?.filter( (e) => e.ubicacion === true ) ?? [], notificaciones ?? [] ) } 
                            endDecorator={ <CheckCircleOutlineIcon /> }
                            disabled={ notificacionesUbicadas === 0 }
                        >
                            Ubicadas { notificacionesUbicadas }
                        </Button>

                    </Grid>

                    <Grid xs={12} sm={6}>
                
                        <Button 
                            fullWidth 
                            variant="outlined" 
                            color="danger" 
                            size="sm" 
                            onClick={ () => onClick( zonaNotificacion, notificaciones?.filter( (e) => e.ubicacion === false ) ?? [], notificaciones ?? [] ) } 
                            endDecorator={ <WrongLocationIcon /> }
                            disabled={ notificacionesNoUbicadas === 0 }
                        >
                            No ubicadas { notificacionesNoUbicadas }
                        </Button>

                    </Grid>

                    <Grid xs={12} mt={2}>

                        <Select
                            multiple    
                            renderValue={ () => (
                                <Box sx={{ display: 'inline-grid', gap: '0.7rem', my: 1 }}>
                                {
                                    actuarios?.map( ( value, index ) => (
                                        <Chip 
                                            key={index} 
                                            variant="soft" 
                                            color="primary"                                          
                                        >
                                            { value.nombreCompleto }
                                        </Chip>
                                    ))
                                }
                                </Box>
                            )}   
                            sx={{ width: '100%' }}                     
                            slotProps={{
                                listbox: {
                                    sx: {
                                        width: '100%',
                                        zIndex: 99999999
                                    },
                                },
                            }}      
                            value={ actuarios ?? [] }
                            onChange={ handleChange }
                            placeholder="Seleccione uno o mas actuarios"
                            disabled={ notificacionesUbicadas === 0 || notificacionesNoUbicadas !== 0 }
                        >
                            {
                                actuariosDisponibles.map( ( e ) => (
                                    <Option key={ e.idUsuario } value={ e } sx={{ py: 1 }}>
                                       
                                        <Box component="span" sx={{ display: 'block' }} mr={2}>
                                            
                                            <Typography level="title-sm">
                                                { e.nombreCompleto }
                                            </Typography>

                                            { 
                                                e.fechaRuta 
                                                &&
                                                <Typography level="body-xs" mt={1}> Fecha de la ultima ruta en esta zona: <strong>{ moment( e.fechaRuta ).format( 'DD-MM-YYYY' ) }</strong> </Typography>
                                            }
                                        </Box>

                                        <Chip
                                            size="sm"
                                            variant="solid"      
                                            color={ e.zonaVisitada ? 'warning' : 'primary' }                                   
                                            sx={{ ml: 'auto', fontSize: 'xs' }}
                                        > </Chip>

                                    </Option>
                                ))
                            }

                        </Select>

                    </Grid>

                </Grid>         

            </CardActions>

        </Card>
    )
}
