import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface stateProps {
    numeroNotificaciones:  number;
}

const initialState = {
    numeroNotificaciones: 0,    
} as stateProps;

export const recepcionSlice = createSlice({
    name: 'recepcion',
    initialState: initialState,
    reducers: {
        setRecepcion: ( state, { payload } : PayloadAction<stateProps> ) => {
            state.numeroNotificaciones = payload.numeroNotificaciones;          
        },      
    },
})

export const { setRecepcion } = recepcionSlice.actions

export default recepcionSlice.reducer