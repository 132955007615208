import { useEffect, useState } from 'react';

import { Box, Button, Checkbox, Chip, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, List, Modal, ModalClose, ModalDialog, ModalOverflow, Radio, RadioGroup, Snackbar, Table, Textarea, Typography } from "@mui/joy"

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import EditIcon from '@mui/icons-material/Edit';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from "@mui/icons-material/Warning";

import { Layout } from "../components/Layout"
import { Sidebar } from "../components/Sidebar"
import { Header } from "../components/Header"

import Pagination from '../components/Pagination';

import { ModalEditUbicacion } from '../modals/ModalEditUbicacion';

import { FinalizarValidacionNotificacion, GuardarMotivoRechazo, GuardarNotificacionAceptada, GuardarTodasLasNotificacion, GuardarUbicacion, ObtenerNotificacionesRecibidas } from '../connections/comun/NotificacionConnection';


import { ActualizarUbicacionParams } from '../interfaces/comun/DomicilioInterface';
import { ActualizarMotivoRechazoParams, ActualizarNotificacionAceptadaParams, FinalizarValidacionParams, NotificacionFullProps } from '../interfaces/comun/NotificacionInterface';
import { Location } from '../interfaces/global/GeolocalizarInterface';
import { SnackbarProps } from '../interfaces/ui/Snackbar';

export const GestionarNotificacionesPage = () => {

    const [open, setOpen] = useState<boolean>( false );
    const [openMapa, setOpenMapa] = useState<boolean>( false );
    const [openConfirmacion, setOpenConfirmacion] = useState<boolean>( false );
    const [openCedula, setOpenCedula] = useState<boolean>( false );
    
    const [isLoading, setIsLoading] = useState<boolean>( false );
    const [isLoadingMotivo, setIsLoadingMotivo] = useState<boolean>( false );
    const [isLoadingLocation, setIsLoadingLocation] = useState<boolean>( false );
    const [isLoadingFinalizar, setIsLoadingFinalizar] = useState<boolean>( false );
    const [reload, setReload] = useState<boolean>( true );
    
    const [rows, setRows] = useState<NotificacionFullProps[]>( [] );

    const [page, setPage] = useState<number>( 0 );
    const [rowsPerPage, setRowsPerPage] = useState<number>( 10 );

    const [referencia, setReferencia] = useState<string>( '' );
    const [cedulaNotificacion, setCedulaNotificacion] = useState<string>( '' );
    const [motivo, setMotivo] = useState<string>( '' );

    const [notificacion, setNotificacion] = useState<NotificacionFullProps>( {} );
    
    const [{ type: typeSnackbar, open: openMessage, message }, setOpenMessage] = useState<SnackbarProps>({
        type: 'warning',
        message: '',
        open: false,
    });

    const handleCloseAlert = () => setOpenMessage({ type: typeSnackbar, open: false, message }) 

    const handleChangeAceptar = async ( value: string, id: string ) => {

        const params : ActualizarNotificacionAceptadaParams = {
            id: id,
            valor: (value === "Si")? true : false
        }

        await GuardarNotificacionAceptada( params ).then( resp => {

            if( resp ){

                if( resp.success ){

                    const newRows = rows.map((elem) => {
                        if (elem.id === id) {
                            elem.aceptarNotificacion = ( value === "Si" );
                            elem.motivo = ( value === "Si" ) ? '' : motivo
                        }
                        return elem;
                    });
            
                    setRows(newRows);

                }
                else{

                    setTimeout(() => {

                        const errorMessage: string = resp.message ?? '';

                        setOpenMessage({ type: 'danger', open: true, message: errorMessage });

                    }, 400);
                }
            }
        });

    }

    const handleChangeAll = async ( value: boolean ) => {

        var params : ActualizarNotificacionAceptadaParams[] = [];

        rows.forEach(element => {
            params.push({ id : element.id  ?? "" , valor : value })
        });

        await GuardarTodasLasNotificacion( params ).then( resp => {

            if( resp ){

                if( resp.success ){

                    const newRows = rows.map( (elem) => { 

                        if( elem.zona !== '' ){
                            elem.aceptarNotificacion = value; 
                            elem.motivo = (value) ? '' : motivo; 
                        }
                        
                        return elem; 
                    });
            
                    setRows( newRows );

                }
                else{

                    setTimeout(() => {

                        const errorMessage: string = resp.message ?? '';

                        setOpenMessage({ type: 'danger', open: true, message: errorMessage });

                    }, 400);
                }
            }
        });
    }

    const handleAddMotivo = async () => {

        setIsLoadingMotivo( true );

        const params : ActualizarMotivoRechazoParams = {
            id: referencia,
            motivo : motivo
        }

        await GuardarMotivoRechazo( params ).then( resp =>{

            if( resp ) {

                if( resp.success ){

                    setTimeout(() => {
                        
                        const newRows = rows.map((elem) => {
                            if (elem.id === referencia) {
                                elem.motivo = motivo;
                            }
                            return elem;
                        });
        
                        const errorMessage: string = resp.message ?? '';
                        
                        setOpenMessage({ type: 'success', open: true, message: errorMessage });
    
                        setIsLoadingMotivo( false );
                        
                        setRows(newRows);
    
                        handleClose();

                    }, 500);

                }else{

                    setTimeout(() => {

                        const errorMessage: string = resp.message ?? '';

                        setOpenMessage({ type: 'danger', open: true, message: errorMessage });

                        setIsLoadingMotivo(false);

                    }, 400);
                }
            }
        })

    }

    const handleClose = () => {

        setOpen( false );
        setOpenMapa( false );

        setReferencia( '' );
        setMotivo( '' );
        setNotificacion( {} );

    }

    const handleSaveLocation = async ({ lat, lng }: Location ) => {

        setIsLoadingLocation( true );

        const params: ActualizarUbicacionParams = {
            id: referencia,
            lat: lat,
            lng: lng
        }

        await GuardarUbicacion( params ).then(resp => {

            if ( resp ) {

                if( resp.success ){

                    setTimeout(() => {
                        
                        const newRows = rows.map((elem) => {
                            if (elem.id === referencia) {
                                elem.lat = lat;
                                elem.lng = lng;
                                elem.ubicacion = true;
                            }
                            return elem;
                        });   
                        
                        setRows(newRows);
                        
                        handleClose();
                       
                        const errorMessage: string = resp.message ?? '';
                        
                        setOpenMessage({ type: 'success', open: true, message: errorMessage });
                        
                        setIsLoadingLocation( false );

                    }, 400);

                }else{

                    setTimeout(() => {

                        const errorMessage: string = resp.message ?? '';

                        setOpenMessage({ type: 'danger', open: true, message: errorMessage });

                        setIsLoadingLocation( false );

                    }, 400);
                }

            }

        });

    }

    const handleFinalizarValidacion = async () => {
         
        setIsLoadingFinalizar( true )

        var params : FinalizarValidacionParams[] = [];

        rows.forEach(element => {
            params.push({ id: element.id ?? "" })
        });

        await FinalizarValidacionNotificacion( params ).then( resp => {

            if( resp ){

                if( resp.success ){

                    setTimeout(() => {

                        const errorMessage: string = resp.message ?? '';

                        setOpenMessage({ type: 'success', open: true, message: errorMessage });

                        setIsLoadingFinalizar(false)

                        setOpenConfirmacion(false)

                        setReload(true)

                    }, 400);
                }
                else{

                    setTimeout(() => {

                        const errorMessage: string = resp.message ?? '';

                        setOpenMessage({ type: 'danger', open: true, message: errorMessage });

                        setIsLoadingFinalizar(false)

                    }, 400);
                }
            }
        });
    }

    useEffect(() => {

        async function Obtener() {

            setIsLoading( true );

            await ObtenerNotificacionesRecibidas().then(resp => {

                setTimeout(() => {

                    const newRows = resp.map( ( e ) => {
                        if( e.zona === '' ){
                            
                            e.aceptarNotificacion = false;
                            
                            if( e.motivo === '' ){
                                e.motivo = 'El domicilio no se encontró en ninguna zona'
                            }
                        }
                        return e;
                    });
                    
                    setRows( newRows );
                    setIsLoading( false );
                    
                }, 400);

            })

        }
      
        Obtener();

    }, [ ])

    useEffect(() => {

        async function Obtener() {

            setIsLoading( true );

            await ObtenerNotificacionesRecibidas().then(resp => {

                setTimeout(() => {
                    
                    const newRows = resp.map( ( e ) => {
                        if( e.zona === '' ){
                            
                            e.aceptarNotificacion = false;
                            
                            if( e.motivo === '' ){
                                e.motivo = 'El domicilio no se encontró en ninguna zona'
                            }
                        }
                        return e;
                    });
                    
                    setRows( newRows );
                    setIsLoading( false );
                    setReload( false );
                    
                }, 400);

            })

        }

        if( reload ){
            Obtener();
        }

    }, [ reload ])

    return (

        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Snackbar
                key={ 'snackbar-login' }
                autoHideDuration={2000 }
                color={ typeSnackbar }
                size="md"
                variant="solid"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={ openMessage }
                onClose={ handleCloseAlert }
                startDecorator={ typeSnackbar === 'success' ? <CheckIcon /> : <WarningIcon /> }
            >
                { message }
            </Snackbar>

            <Sidebar />

            <Header />

            <Layout title='Gestionar notificaciones'>

                <Grid container >

                    <Grid xs={12}>

                        <Table
                            aria-label="striped table" 
                            stripe={'even'} 
                            borderAxis='x'
                            size='lg' 
                            sx={{ 
                                width: { xs: '100%', md: '100%' },
                                display:{ xs: 'block', md: 'inline' },
                                overflowX: { xs: 'auto', md: 'hidden' },
                            }}
                        >

                            <thead style={{ width: '100%' }}>

                                <tr>
                                    <th style={{ width: '25%' }}>Persona a notificar</th>
                                    <th style={{ width: '25%' }}>Domicilio</th>
                                    <th style={{ width: '8%' }}>Notificación</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Cédula de <br /> notificación</th>
                                    <th style={{ width: '5%' }}>Zona</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Ubicación</th>
                                    <th style={{ width: '10%', textAlign: 'start' }}>
                                        Aceptar <br /> notificación
                                        <Checkbox 
                                            color='neutral' 
                                            sx={{ ml: 1 }} 
                                            checked={ ( rows.filter( (e) => e.zona !== '' ).length === rows.filter( (e) => e.aceptarNotificacion === true ).length ) && rows.length !== 0 }
                                            onChange={ ( e ) => handleChangeAll( e.target.checked ) } 
                                        />
                                    </th>
                                    <th style={{ width: '45%', textAlign: 'center' }}>Motivo de <br /> rechazo</th>
                                </tr>

                            </thead>

                            <tbody>

                                {

                                    isLoading
                                    ?
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '250px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </td>
                                        </tr>
                                    :                                    
                                        (
                                            rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        )
                                        .map((row, index) => (
                                            <tr key={index}>

                                                <td>
                                                    <Typography fontSize={14} fontWeight='bold'> {row.persona} </Typography>
                                                    <Typography fontSize={14}> ({row.tipoParte}) </Typography>
                                                </td>

                                                <td><Typography fontSize={14}> { row.domicilio } </Typography></td>

                                                <td>
                                                    <Typography fontSize={14} fontWeight='bold'>{ row.referencia } </Typography>
                                                    <Typography fontSize={14}> ({ row.tipoNotificacion }) </Typography>
                                                </td>

                                                <td style={{ textAlign: 'center' }}>

                                                    <IconButton variant='solid' size='md' onClick={() => { setOpenCedula(true); setCedulaNotificacion( row.cedulaNotificacion ?? "") }}>
                                                        <FileCopyIcon />
                                                    </IconButton>

                                                </td>

                                                <td> <Typography fontSize={14}> { row.zona } </Typography> </td>

                                                <td style={{ textAlign: 'center' }}>

                                                    {
                                                        row.ubicacion
                                                        ?
                                                            <Chip
                                                                size="md"
                                                                variant="outlined"
                                                                color="success"
                                                                endDecorator={<CheckCircleOutlineIcon />}
                                                                onClick={() => { setReferencia(row?.id ?? ''); setNotificacion(row); setOpenMapa(true); }}
                                                            >
                                                                Ubicada
                                                            </Chip>
                                                        :
                                                            <Chip
                                                                size="md"
                                                                variant="outlined"
                                                                color="danger"
                                                                endDecorator={<WrongLocationIcon />}
                                                                onClick={() => { setReferencia(row?.id ?? ''); setNotificacion(row); setOpenMapa(true); }}
                                                            >
                                                                No ubicada
                                                            </Chip>
                                                    }

                                                </td>

                                                <td>

                                                    {
                                                        row.zona === ''
                                                        ?
                                                            <Typography textAlign={'center'} fontWeight={'bold'} fontSize={16}> No </Typography>
                                                        :
                                                            <FormControl>
                                                                <RadioGroup defaultValue="Si" onChange={(e) => handleChangeAceptar(e.target.value, row?.id ?? '')} >
                                                                    <List component='div' orientation='horizontal' >
                                                                        <Radio color='neutral' value="Si" label="Si" variant="outlined" size='md' sx={{ mr: 1 }} checked={ row.aceptarNotificacion === true } disabled={ row.zona === '' } />
                                                                        <Radio color='neutral' value="No" label="No" variant="outlined" size='md' sx={{ ml: 1 }} checked={ row.aceptarNotificacion === false } disabled={ row.zona === '' } />
                                                                    </List>
                                                                </RadioGroup>
                                                            </FormControl>
                                                    }                                                   
                                                        
                                                </td>

                                                <td style={{ textAlign: 'center' }}>
                                                    {
                                                        ( row.aceptarNotificacion === false && row.motivo === "" )
                                                        ?
                                                            <IconButton variant='solid' size='sm' onClick={() => { setReferencia(row?.id ?? ''); setMotivo(''); setOpen(true); }}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        :
                                                            ( row.zona !== '' && row.motivo !== '' && row.motivo !== null )
                                                            ?                                                              
                                                                <Button variant='plain' endDecorator={ <EditIcon />} onClick={() => { setReferencia(row?.id ?? ''); setMotivo(row?.motivo ?? ''); setOpen(true); }}>
                                                                    { row.motivo?.substring(0, 15) }......
                                                                </Button>
                                                            :
                                                                <Typography color='primary' fontWeight={'600'} fontSize={14}> { row.motivo } </Typography>
                                                    }
                                                </td>

                                            </tr>
                                        ))
                                }

                                {
                                    ( !isLoading && rows.length === 0 )
                                    &&
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '250px' }}>
                                                <Typography fontSize={14} fontWeight={'bold'}>No se encontraron registros</Typography>
                                            </td>
                                        </tr>
                                }   

                            </tbody>

                        </Table>

                    </Grid>

                </Grid>

                <Pagination
                    gotoPage={setPage}
                    length={rows.length}
                    pageSize={rowsPerPage}
                    setPageSize={setRowsPerPage}
                />

                <Grid container textAlign='end'>

                    <Grid xs={12} mt={5} >

                        <Button
                            size='lg'
                            sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }}
                            startDecorator={<DoneOutlineIcon />}
                            disabled={
                                rows.filter((e) => e.aceptarNotificacion === false && e.motivo === '').length !== 0 ||
                                (rows.length === rows.filter((e) => e.aceptarNotificacion === undefined).length) ||
                                (rows.length === rows.filter((e) => e.aceptarNotificacion === null).length) 
                            }
                            onClick={() => setOpenConfirmacion(true)}
                        >
                            Finalizar validación
                        </Button>

                    </Grid>

                </Grid>

            </Layout>

            <Modal
                disableEscapeKeyDown
                open={ open }
                onClose={(_, r) => {
                    if (r === 'backdropClick') return;
                    setOpen(false)
                }}
                sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)' }}
            >
                <ModalOverflow>

                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    >
                        <ModalClose />
                        <DialogTitle> Motivo de no aceptación </DialogTitle>

                        <DialogContent>

                            <Grid container mt={2}>

                                <Grid xs={12}>

                                    <Textarea
                                        placeholder="Escribe el motivo"
                                        minRows={5}
                                        sx={{ width: 500 }}
                                        value={ motivo }
                                        onChange={ (e) => setMotivo( e.target.value ) }
                                    />

                                </Grid>

                            </Grid>

                        </DialogContent>

                        <DialogActions>
                            <Button
                                sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }}
                                disabled={ motivo === '' }
                                onClick={ handleAddMotivo }
                                loading={ isLoadingMotivo }
                            > 
                                Agregar
                            </Button>
                            <Button color='neutral' variant='plain' disabled={ isLoadingMotivo } onClick={ handleClose }>Cancelar</Button>
                        </DialogActions>

                    </ModalDialog>

                </ModalOverflow>

            </Modal>

            <Modal
                disableEscapeKeyDown
                open={ openConfirmacion }
                onClose={(_, r) => {
                    if (r === 'backdropClick') return;
                    setOpenConfirmacion(false)
                }}
                sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)' }}
            >

                <ModalDialog variant="outlined" role="alertdialog" sx={{ width: 450 }}>

                    <DialogTitle>
                        <WarningRoundedIcon />
                        Confirmación
                    </DialogTitle>

                    <Divider />

                    <DialogContent>
                        ¿Desea finalizar la validación de las notificaciones?
                    </DialogContent>

                    <DialogActions>

                        <Button sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }} loading={ isLoadingFinalizar } onClick={ handleFinalizarValidacion } >
                            Aceptar
                        </Button>

                        <Button variant="plain" color="neutral" disabled={ isLoadingFinalizar } onClick={ () => setOpenConfirmacion( false ) } >
                            Cancelar
                        </Button>

                    </DialogActions>

                </ModalDialog>

            </Modal>

            <Modal
                disableEscapeKeyDown
                open={ openCedula }
                onClose={(_, r) => {
                    if (r === 'backdropClick') return;
                    setOpenCedula(false)
                }}
                sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)' }}
            >
                <ModalOverflow>

                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    >
                        <ModalClose />
                        <DialogTitle> Cédula de notificación </DialogTitle>

                        <DialogContent>

                            <Grid container mt={2} sx={{ width: '800px' }}>

                                <Grid xs={12}>

                                    <iframe title='acuse' src={ cedulaNotificacion } height="500px" width="100%"></iframe>

                                </Grid>

                            </Grid>

                        </DialogContent>

                    </ModalDialog>

                </ModalOverflow>

            </Modal>

            <ModalEditUbicacion
                open={ openMapa }
                isLoading={ isLoadingLocation }
                setOpen={ setOpenMapa }
                notificacion={ notificacion }
                setNotificacion={ setNotificacion }
                handleSave={ handleSaveLocation }
            />

        </Box>

    )
}
