import { EstatusInterface } from '../../interfaces/catalogos/EstatusInterface';

import HttpClienteToken from '../../services/HttpClientToken';



export const ObtenerEstatus = () => {

    return new Promise<EstatusInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Estatus/ObtenerEstatus', token)
            .then( ({ data } : { data : EstatusInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const Estatus = () => {

    return new Promise<EstatusInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Estatus/', token)
            .then( ({ data } : { data : EstatusInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarEstatus = ( params: EstatusInterface ) => {

    return new Promise<EstatusInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Estatus/Actualizar', params, token)
            .then( ({ data } : { data : EstatusInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idEstatus: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarEstatus = ( params: EstatusInterface ) => {

    return new Promise<EstatusInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Estatus/Agregar', params, token)
            .then( ({ data } : { data : EstatusInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idEstatus: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}