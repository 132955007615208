import { useEffect, useState } from "react"

import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, Modal, ModalDialog, ModalOverflow, Snackbar, Typography } from "@mui/joy"

import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import WarningIcon from "@mui/icons-material/Warning";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import { Header } from "../components/Header"
import { Sidebar } from "../components/Sidebar"

import { MapProps } from "../interfaces/global/GeolocalizarInterface"
import { APIProvider, Map, useMap, useMapsLibrary } from "@vis.gl/react-google-maps"

import { DropResult } from "@hello-pangea/dnd";

import { Reorder } from "../helpers/ReorderFunction";
import { convertirHora } from "../helpers/FormatHora";

import DraggableList from "../components/DraggableList";

import { MarkerNotification } from "../components/MarkerNotification";
import { RutaListItem } from "../components/RutaListItem";

import { AsignarRuta, GuardarRuta, ObtenerRutas } from "../connections/comun/RutaConnection";

import { RutaProps } from "../interfaces/comun/RutaInterface";
import { NotificacionFullProps } from "../interfaces/comun/NotificacionInterface";
import { SnackbarProps } from "../interfaces/ui/Snackbar";

export const GenerarRutaPage = () => {

    const [rutas, setRutas] = useState<RutaProps[]>( [] );
    const [rutaSelected, setRutaSelected] = useState<RutaProps>( { } );
    
    const [notificaciones, setNotificaciones] = useState<NotificacionFullProps[]>( [] );

    const [reload, setReload] = useState<boolean>( false );

    const [isLoading, setIsLoading] = useState<boolean>( false );
    const [isLoadingNotificaciones, setIsLoadingNotificaciones] = useState<boolean>( false );

    const [isLoadingGuardar, setIsLoadingGuardar] = useState<boolean>( false );
    const [isLoadingAsignar, setIsLoadingAsignar] = useState<boolean>( false );

    const [openConfirmacionGuardar, setOpenConfirmacionGuardar] = useState<boolean>( false );
    const [openConfirmacionAsignar, setOpenConfirmacionAsignar] = useState<boolean>( false );

    const [{ center, zoom }, setMap] = useState<MapProps>({
        center: { lat: 27.2113713, lng: -101.9014431 },
        zoom: 7        
    });

    const [{ type: typeSnackbar, open: openMessage, message }, setOpenMessage] = useState<SnackbarProps>({
        type: 'warning',
        message: '',
        open: false,
    });

    const handleCloseAlert = () => setOpenMessage({ type: typeSnackbar, open: false, message }) 

    const onDragEnd = ({ destination, source }: DropResult) => {
 
        if ( !destination ) return;
    
        const newItems = Reorder(notificaciones, source.index, destination.index);
        
        const rewRows = newItems.map( ( e, index ) => {
            e.indice = index;
            return e;
        });

        setNotificaciones( rewRows );

    };

    const handleClickRuta = ( ruta : RutaProps, selected: boolean) => {

        if( selected ){

            setRutaSelected( ruta );

            const newRows = rutas.map( (e) => {
                if( e.id === ruta.id ){
                    e.selected = selected;
                }
                else{
                    e.selected = false;
                }
                return e;
            });

            setRutas( newRows );

            setIsLoadingNotificaciones( true );
            setNotificaciones( [] );

            setTimeout(() => {
                
                setNotificaciones( ruta?.notificaciones ?? [] );
                setIsLoadingNotificaciones( false );

                if( ruta?.notificaciones?.length !== 0 ){

                    let index = Math.ceil(( ruta?.notificaciones?.length ?? 0 ) / 2);

                    if( index !== 0 ){
                        index = index - 1;
                    }

                    const latitud = ruta?.notificaciones?.[index].lat ?? 0;
                    const longitud = ruta?.notificaciones?.[index].lng ?? 0;

                    setMap({
                        center: { lat: latitud, lng: longitud },
                        zoom: 10       
                    });   
                }

            }, 400);

        }
        else {

            setRutaSelected( {} );

            const newRows = rutas.map( (e) => {
                if( e.id === ruta.id ){
                    e.selected = selected;
                }
                else{
                    e.selected = false;
                }
                return e;
            });

            setRutas( newRows );

            setNotificaciones( [] );

            setMap({
                center: { lat: 27.2113713, lng: -101.9014431 },
                zoom: 7     
            });   

        }

    }

    const handleGuardarRuta = async () => {

        setIsLoadingGuardar( true );

        await GuardarRuta({ id: rutaSelected.id ?? '', notificaciones }).then( resp => {

            setTimeout(() => {

                if( resp.success ){
    
                    const newRows = rutas.map( (e) => {
                        if( e.id === rutaSelected.id ){
                            e.notificaciones = notificaciones;
                        }
                        return e;
                    });
    
                    setRutas( newRows );

                    setOpenMessage({ type: 'success', open: true, message: resp.message ?? '' });

                    setOpenConfirmacionGuardar( false );
                    setIsLoadingGuardar( false );
                }
                else {

                    setOpenMessage({ type: 'danger', open: true, message: resp.message ?? '' });
                    setOpenConfirmacionGuardar( false );
                    setIsLoadingGuardar( false );

                }
                

            }, 400);

        });

    }

    const handleAsinarRuta = async () => {
                
        setIsLoadingAsignar( true );
        setOpenConfirmacionAsignar( false );

        await AsignarRuta({ id: rutaSelected.id ?? '', notificaciones }).then( resp => {

            setTimeout(() => {

                if( resp.success ){
        
                    setRutaSelected( {} );
                    setNotificaciones( [] );                    
                    
                    setIsLoadingAsignar( false );
                    setReload( true );

                    setOpenMessage({ type: 'success', open: true, message: resp.message ?? '' });

                    setMap({
                        center: { lat: 27.2113713, lng: -101.9014431 },
                        zoom: 7        
                    });
                }
                else {

                    setOpenMessage({ type: 'danger', open: true, message: resp.message ?? '' });
                    setOpenConfirmacionAsignar( false );
                    setIsLoadingAsignar( false );

                }
                

            }, 400);

        });

    }

    useEffect(() => {

        async function Obtener() {

            setIsLoading( true );
            setRutas( [] );

            await ObtenerRutas().then(resp => {

                setTimeout(() => {

                    setRutas( resp );
                    setIsLoading( false );        

                }, 400);

            })

        }
      
        Obtener();

    }, [ ])    

    useEffect(() => {

        async function Obtener() {

            setIsLoading( true );
            setRutas( [] );

            await ObtenerRutas().then(resp => {

                setTimeout(() => {

                    setRutas( resp );
                    setIsLoading( false );        
                    setReload( false );

                }, 400);

            })

        }
      
        if( reload ){
            Obtener();
        }

    }, [ reload ])    

    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Snackbar
                key={ 'snackbar-login' }
                autoHideDuration={ 3000 }
                color={ typeSnackbar }
                size="md"
                variant="solid"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={ openMessage }
                onClose={ handleCloseAlert }
                startDecorator={ typeSnackbar === 'success' ? <CheckIcon /> : <WarningIcon /> }
            >
                { message }
            </Snackbar>

            <Sidebar />
            
            <Header />               
            
            <Box style={{ width: '75%' }}>

                <APIProvider apiKey={ process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '' }>

                    <Map
                        mapId={'5cb0f0703c7dc687'}
                        style={{ height: '100vh' }}
                        center={ center }
                        zoom={ zoom }                         
                        gestureHandling={ 'greedy' }  
                        onCameraChanged={ ( e ) =>  
                            setMap({
                                center: e.detail.center,
                                zoom: e.detail.zoom       
                            })
                        }        
                    >

                        {
                            notificaciones.map( ( e, index ) => (

                                <MarkerNotification   
                                    key={ index }                                            
                                    latitud={ e?.lat ?? 0 }
                                    longitud={ e?.lng ?? 0 }
                                    notificacion={ e }     
                                    setMap={ setMap }     
                                    label={ (( e.indice ?? 0 ) + 1 ).toString() }
                                    viewPin={ true } 
                                />

                            ))
                        }


                    </Map>

                    <Directions notificaciones={ notificaciones ?? [] } />

                </APIProvider>   

            </Box>
            
            <Box style={{ width: '25%' }}>
                        
                <Grid container mt={1} p={2}>

                    <Grid xs={12} sx={{ height: 390, maxHeight: 390, overflow: 'scroll' }}>
                    
                        <Typography level="body-md" textTransform="uppercase" fontWeight="lg" mb={2} >
                            Rutas pendienes por liberar
                        </Typography>

                        {
                            isLoading
                            ?
                                <Grid xs={12}>
                                    <Box sx={{ textAlign: 'center', mt: 25 }}>
                                        <CircularProgress color='neutral' size='md' />
                                    </Box>
                                </Grid>
                            :      
                                rutas.length !== 0
                                ?
                                    <List aria-labelledby="ellipsis-list-demo" sx={{ gap: 1 }} >

                                        {
                                            rutas.map( ( elem, index ) => (

                                                <RutaListItem 
                                                    key={ index }
                                                    ruta={ elem }
                                                    onClick={ (e) => handleClickRuta( e, !elem.selected ) }
                                                />

                                            ))
                                        }
                                    
                                    </List>
                                :
                                    ( !isLoading && rutas.length === 0 )
                                    &&
                                        <Typography level="h4" textAlign={'center'} mt={'50%'}> No se encontraron rutas pendientes </Typography>                                    

                        }
                        
                    </Grid>
                    
                    <Grid xs={12} mt={2} sx={{ height: 300 }}>
                        {
                            ( rutaSelected.id !== '' )
                            &&                            
                                isLoadingNotificaciones
                                ?                                
                                    <Box sx={{ textAlign: 'center', mt: 6 }}>
                                        <CircularProgress color='neutral' size='md' />
                                    </Box>
                                :    
                                    notificaciones.length !== 0
                                    &&
                                        <>                                
                                            <Typography level="body-md" textTransform="uppercase" fontWeight="lg">
                                                Notificaciones <strong>({ notificaciones.length })</strong> <br />
                                            </Typography>

                                            <Typography level="body-sm" textTransform="uppercase" mb={2} >                      
                                                { rutaSelected.actuario ?? '' }
                                            </Typography>

                                            <Box sx={{ maxHeight: 300, overflow: 'scroll' }}>

                                                <DraggableList 
                                                    items={ notificaciones } 
                                                    onDragEnd={ onDragEnd }      
                                                    setMap={ setMap }                                         
                                                />      

                                            </Box>

                                        </>                      
                        }
                    </Grid>    

                    {
                        ( rutaSelected.id !== '' )
                        &&
                        <>                
                            <Grid xs={12} mt={10}>

                                <Button   
                                    fullWidth                                          
                                    variant="solid" 
                                    color="neutral"  
                                    startDecorator={ <CheckIcon /> }         
                                    disabled={ rutaSelected.id === '' || !rutaSelected.id }
                                    onClick={ () => setOpenConfirmacionAsignar( true ) }
                                >
                                    Asignar ruta
                                </Button>

                            </Grid>

                            <Grid xs={12} mt={1}>                              

                                <Button       
                                    fullWidth                                      
                                    variant="soft" 
                                    color="neutral"      
                                    startDecorator={ <SaveIcon /> }         
                                    disabled={ rutaSelected.id === '' || !rutaSelected.id }
                                    onClick={ () => setOpenConfirmacionGuardar( true ) }
                                >
                                    Guardar
                                </Button>

                            </Grid>
                        </>
                    }                    

                </Grid>

            </Box>

            <Modal 
                disableEscapeKeyDown
                open={ openConfirmacionGuardar }              
                onClose={ ( _, r ) => { 
                    if( r === 'backdropClick' ) return;                     
                    setOpenConfirmacionGuardar( false )
                }}
                sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}               
            >

                <ModalDialog variant="outlined" role="alertdialog" sx={{ width: 550 }}>

                    <DialogTitle>
                        <WarningRoundedIcon />
                        Confirmación
                    </DialogTitle>

                    <Divider />

                    <DialogContent sx={{ mt: 1 }}>
                        <Typography level="body-md">
                            ¿Desea guardar la <strong>Ruta { rutaSelected.idRuta } </strong> de <strong>{ rutaSelected.actuario }</strong>?
                        </Typography>
                    </DialogContent>

                    <DialogActions>

                        <Button sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }} loading={ isLoadingGuardar } onClick={ handleGuardarRuta } >
                            Aceptar
                        </Button>

                        <Button variant="plain" color="neutral" disabled={ isLoadingGuardar } onClick={ () => setOpenConfirmacionGuardar( false ) } >
                            Cancelar
                        </Button>

                    </DialogActions>

                </ModalDialog>

            </Modal>

            <Modal 
                disableEscapeKeyDown
                open={ openConfirmacionAsignar }              
                onClose={ ( _, r ) => { 
                    if( r === 'backdropClick' ) return;                     
                    setOpenConfirmacionAsignar( false )
                }}
                sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}               
            >

                <ModalDialog variant="outlined" role="alertdialog" sx={{ width: 500 }}>

                    <DialogTitle>
                        <WarningRoundedIcon />
                        Confirmación
                    </DialogTitle>

                    <Divider />

                    <DialogContent>
                        <Typography level="body-md">
                            ¿Desea finalizar la asignación de la <strong>Ruta { rutaSelected.idRuta }</strong>?
                        </Typography>
                    </DialogContent>

                    <DialogActions>

                        <Button sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }} onClick={ handleAsinarRuta } >
                            Aceptar
                        </Button>

                        <Button variant="plain" color="neutral" disabled={ isLoadingAsignar } onClick={ () => setOpenConfirmacionAsignar( false ) } >
                            Cancelar
                        </Button>

                    </DialogActions>

                </ModalDialog>

            </Modal>

            <Modal
                disableEscapeKeyDown
                open={ isLoadingAsignar }
                onClose={(_, r) => {
                    if (r === 'backdropClick') return;
                }}
                sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)', bgcolor: 'rgba(0, 0, 0, 0.5)' }}
            >
                <ModalOverflow>

                    <Box 
                        height={'100vh'}
                        width={'100%'}
                        alignItems="center"
                        textAlign={'center'}
                        mt={50}
                    >
                        <CircularProgress color='neutral' size='md' />
                    </Box>

                </ModalOverflow>

            </Modal>

        </Box>  
    );
}

interface Props {
    notificaciones: NotificacionFullProps[];
}

function Directions( { notificaciones }: Props ) {

    const map = useMap();
    const routesLibrary = useMapsLibrary('routes');
    
    const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
    const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
    const [routes, setRoutes] = useState<google.maps.DirectionsRoute[]>([]);

    const [distance, setDistance] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    
    const [waypoints, setWaypoints] = useState<google.maps.DirectionsWaypoint[]>( [] );

    const selected = routes[0];
    const leg = selected?.legs[0];

    useEffect(() => {

        setDistance( 0 );
        setDuration( 0 );
          
        const points: google.maps.DirectionsWaypoint[] = notificaciones.map( elem => {             
            return {
                location: {
                    lat: elem.lat ?? 0,
                    lng: elem.lng ?? 0,
                },
                stopover: true,
            };       
        });

        setWaypoints( points );

    }, [ notificaciones ])   

    useEffect(() => {
           
        if( selected?.legs?.length !== 0 ){

            let dur = 0;
            let dis = 0;
            selected?.legs?.forEach( e => {
                dur += e.duration?.value ?? 0;
                dis += e.distance?.value ?? 0;
            });

            setDistance( dis );
            setDuration( dur );
        }

    }, [ selected ])    

    // Initialize directions service and renderer
    useEffect(() => {

        if (!routesLibrary || !map) return;     

        setDirectionsService(new routesLibrary.DirectionsService());
        setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map, markerOptions: { visible: false, draggable: true } }));
    
    }, [routesLibrary, map, waypoints]);

    // Use directions service
    useEffect(() => {

        if (!directionsService || !directionsRenderer) return;

        if( waypoints.length !== 0 ){

            const origin = waypoints[0].location ?? '';
            const destination = waypoints[ waypoints.length - 1 ].location ?? '';

            directionsService        
                .route({
                    origin: origin,                    
                    destination: destination,
                    travelMode: google.maps.TravelMode.DRIVING,
                    provideRouteAlternatives: false,
                    //optimizeWaypoints: true,
                    waypoints: waypoints.filter( ( _, index ) => index !== 0 && index !== waypoints.length - 1 ),                                
                })
                .then(response => {
                    directionsRenderer.setDirections(response);
                    setRoutes(response.routes);
                });
        }


        return () => directionsRenderer.setMap( null );

    }, [ directionsService, directionsRenderer, waypoints ]);

    // Update direction route
    useEffect(() => {

        if ( !directionsRenderer ) return;
        
        directionsRenderer.setRouteIndex( 0 );

    }, [ directionsRenderer ]);

    if (!leg) return null;

    return ( 
        ( distance !== 0 && duration !== 0 )        
        ?
            <Box position={'absolute'} top={30} left={'40%'} bgcolor={'rgba(255, 255, 255, 0.9)'} p={4} borderRadius={30}>

                <Typography level="title-lg" fontSize={22} fontWeight={'normal'}>
                    Distancia: <strong> { Math.ceil(distance / 1000) } Km </strong>
                </Typography>

                <Typography level="title-lg" fontSize={22} fontWeight={'normal'} mt={2}>
                    Duración: <strong> { convertirHora( duration ) } </strong>
                </Typography>

            </Box>
        :
            <></>
    );
}
