import HttpClienteToken from '../../services/HttpClientToken';

import { PaginacionInterface } from '../../interfaces/global/PaginacionInterface';
import { AsentamientoInterface, AsentamientoParser, AsentamientosResponse, ObtenerAsentamientoParams, ObtenerCodigosPostalesParams } from '../../interfaces/catalogos/AsentamientoInterface';

export const ObtenerCodigosPostales = ( idMunicipio: number ) => {

    return new Promise<ObtenerCodigosPostalesParams[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get(`/Api/Asentamiento/ObtenerCodigosPostales/${ idMunicipio }`, token)
            .then( ({ data } : { data : ObtenerCodigosPostalesParams[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ObtenerAsentamientos = ( param: ObtenerAsentamientoParams ) => {

    return new Promise<AsentamientoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Asentamiento/ObtenerAsentamientos/', param, token)
            .then( ({ data } : { data : AsentamientoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ObtenerAsentamientosMunicipio = ( id: number ) => {

    return new Promise<AsentamientoParser[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Asentamiento/ObtenerAsentamientosMunicipio/' + id, token)
            .then( ({ data } : { data : AsentamientoParser[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const Asentamientos = ( params: PaginacionInterface ) => {

    return new Promise<AsentamientosResponse>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Asentamiento/', params, token)
            .then( ({ data } : { data : AsentamientosResponse } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve({
                    pageCount: 0,
                    currentPageIndex: 0,
                    recordsCount: 0,
                    records: [],
                });
            });
        }

    });

}

export const ActualizarAsentamiento = ( params: AsentamientoInterface ) => {

    return new Promise<AsentamientoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Asentamiento/Actualizar', params, token)
            .then( ({ data } : { data : AsentamientoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idAsentamiento: 0,
                    clave: '',
                    descripcion: '',
                    codigoPostal: '',
                    idTipoAsentamiento: 0,
                    idEstado: 0,
                    idMunicipio: 0,
                    idCiudad: 0,
                    activo: false,
                });
            });
        }

    });

}

export const AgregarAsentamiento = ( params: AsentamientoInterface ) => {

    return new Promise<AsentamientoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Asentamiento/Agregar', params, token)
            .then( ({ data } : { data : AsentamientoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idAsentamiento: 0,
                    clave: '',
                    descripcion: '',
                    codigoPostal: '',
                    idTipoAsentamiento: 0,
                    idEstado: 0,
                    idMunicipio: 0,
                    idCiudad: 0,
                    activo: false,
                });
            });
        }

    });

}