import HttpClienteToken from '../../services/HttpClientToken';

import { TipoPersonaInterface } from '../../interfaces/catalogos/TipoPersonaInterface';

export const ObtenerTiposPersonas = () => {

    return new Promise<TipoPersonaInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoPersona/ObtenerTiposPersonas', token)
            .then( ({ data } : { data : TipoPersonaInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const TiposPersonas= () => {

    return new Promise<TipoPersonaInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoPersona/', token)
            .then( ({ data } : { data : TipoPersonaInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarTipoPersona = ( params: TipoPersonaInterface ) => {

    return new Promise<TipoPersonaInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoPersona/Actualizar', params, token)
            .then( ({ data } : { data : TipoPersonaInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoPersona: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarTipoPersona= ( params: TipoPersonaInterface ) => {

    return new Promise<TipoPersonaInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoPersona/Agregar', params, token)
            .then( ({ data } : { data : TipoPersonaInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoPersona: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}