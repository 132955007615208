import HttpClienteApiKey from '../../services/HttpClientApiKey';
import HttpClienteToken from '../../services/HttpClientToken';

import { AxiosError } from 'axios';

import { HttpResponse } from '../../interfaces/global/HttpInterface';
import { LoginParams, LoginResponse, RecuperarContrasenaParams, RestablecerContrasenaParams, ValidarTokenRecuperacionParams, ValidarTokenResponse } from '../../interfaces/auth/AuthInterface';

export const Login = ( params: LoginParams ) => {

    return new Promise<LoginResponse>( (resolve, eject) => {
  
        HttpClienteApiKey.post('/Api/Auth/Login', params)
        .then( ({ data } : { data : LoginResponse} ) => {
            resolve( data );
		})
        .catch( (error: AxiosError) => {
            
            const data: LoginResponse = {
                success: false,
                message: error.message,
            };

            resolve( data );
        });

    });
}

export const Logout = () => {

    return new Promise<HttpResponse>( ( resolve, eject ) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Auth/Logout', null, token)
            .then( ({ data } : { data : HttpResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: HttpResponse = {
                    success: false,
                    message: error.message,
                };
    
                resolve( data );
            });
        }   
       
    });
}

export const ValidarToken = () => {

    return new Promise<ValidarTokenResponse>( ( resolve, eject ) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Auth/ValidarToken/', null, token)
            .then( ({ data } : { data : ValidarTokenResponse} ) => {               
                resolve( data );                
            })
            .catch( (error: AxiosError) => {
                
                const data: ValidarTokenResponse = {
                    success: false,
                    message: error.message,
                };
    
                resolve( data );
            });
        }

    });

}

export const RecuperarContrasena = ( params: RecuperarContrasenaParams ) => {

    return new Promise<HttpResponse>( ( resolve, eject ) => {

        HttpClienteApiKey.post('/Api/Auth/RecuperarContrasena/', params)
        .then( ({ data } : { data : HttpResponse} ) => {               
            resolve( data );                
        })
        .catch( (error: AxiosError) => {
            
            const data: HttpResponse = {
                success: false,
                message: error.message,
            };

            resolve( data );
        });

    });

}

export const ValidarTokenRecuperacion = ( params: ValidarTokenRecuperacionParams ) => {

    return new Promise<HttpResponse>( (resolve, eject) => {

        HttpClienteApiKey.post('/Api/Auth/ValidarTokenRecuperacion/', params)
        .then( ({ data } : { data : HttpResponse} ) => {               
            resolve( data );                
        })
        .catch( (error: AxiosError) => {
            
            const data: HttpResponse = {
                success: false,
                message: error.message,
            };

            resolve( data );
        });  

    });

}

export const RestablecerContrasena = ( params: RestablecerContrasenaParams ) => {

    return new Promise<HttpResponse>( (resolve, eject) => {

        HttpClienteApiKey.post('/Api/Auth/RestablecerContrasena/', params)
        .then( ({ data } : { data : HttpResponse} ) => {               
            resolve( data );                
        })
        .catch( (error: AxiosError) => {
            
            const data: HttpResponse = {
                success: false,
                message: error.message,
            };

            resolve( data );
        });  

    });

}