import { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Box, Breadcrumbs, Typography, Stack, Button, DialogActions, DialogContent, DialogTitle, Modal, ModalDialog, ModalOverflow } from '@mui/joy';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import { Logout, ValidarToken } from '../connections/auth/AuthConnection';
import { logout, setPermisos } from '../store/slices/authSlice';

interface Props {
    children?: React.ReactNode;
    title?: string | JSX.Element;
    isCatalog?: boolean;
    isQuery?: boolean;
}

export const Layout = ( { children, title = '', isCatalog = false, isQuery = false } : Props ) => {

    const dispatch = useDispatch();

    const initPage = useRef<boolean>( false );

    const [open, setOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState( false );

    const handleLogout = async () => {

        setIsLoading( true );

        await Logout().then( resp => {

            if( resp.success ){

                setTimeout(() => {

                    window.localStorage.clear();
                    
                    dispatch( logout() );
                    setIsLoading( false );
                
                }, 400);

            }
            else {
                setIsLoading( false );
            }

        });

    }

    useEffect(() => {
       
        async function Obtener(){

            await ValidarToken().then( resp => {               
       
                if( resp.success === false){
                    setOpen(true);
                }
                
                dispatch( setPermisos({ permisos: resp.permisos }));

                initPage.current = true;

            });

        }
            
        Obtener();        

    }, [ dispatch ] );

    return (
        <>
            <Box sx={{ flex: 1, width: '100%', mb: 2 }}>

                <Box
                    sx={{
                        position: 'sticky',
                        top: { sm: -100, md: -110 },
                        bgcolor: '#f3f3f5 ',
                        zIndex: 9995,
                    }}
                >
                    <Box sx={{ px: { xs: 3, md: 4 }, mt:{ xs: 8, md: 1 } }}>

                        {
                            title
                            &&
                                <Breadcrumbs
                                    size="sm"
                                    aria-label="breadcrumbs"
                                    separator={ <ChevronRightRoundedIcon sx={{ fontSize: 28 }}  /> }
                                    sx={{ pl: 3, mt: 3 }}
                                >
                                    {
                                        isCatalog
                                        ? <Typography color="neutral" fontWeight={500} fontSize={22} sx={{ ml: 1 }}>Catálogos</Typography>
                                        : 
                                            isQuery
                                            ? <Typography color="neutral" fontWeight={500} fontSize={22} sx={{ ml: 1 }}>Consultas</Typography>
                                            : <Typography></Typography>
                                    }
                                    
                                    <Typography color="neutral" fontWeight={500} fontSize={22}>
                                        { title }
                                    </Typography>

                                </Breadcrumbs>
                        }

                    </Box>
                
                </Box>

                <Stack
                    spacing={4}
                    sx={{
                        display: 'flex',                    
                        mx: 'auto',
                        px: { xs: 3, md: 6 },
                        py: { xs: 3, md: 2 },
                    }}
                >   

                    { children }

                </Stack>

            </Box>

            <Modal             
                disableEscapeKeyDown                
                open={ open }
                onClose={ ( _, r ) =>{ 
                    if( r === 'backdropClick' ) return;                     
                    setOpen( false )
                }}
                sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
            >
                <ModalOverflow>
                
                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                        sx={{ width: 600 }}
                    >
                        
                        <DialogTitle> El tiempo de sesión ha expirado </DialogTitle>

                        <DialogContent>   
                            Por seguridad es necesario volver a iniciar sesión
                        </DialogContent>     

                        <DialogActions>
                            
                            <Button variant="solid" color="neutral" loading={ isLoading } onClick={ handleLogout }>
                                Aceptar
                            </Button>

                        </DialogActions>    

                    </ModalDialog>
                    
                </ModalOverflow>

            </Modal>          

        </>
    );
}
