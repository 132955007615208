export const colourStyles = {
    control: (styles: any) => ({ 
        ...styles, 
        backgroundColor: 'white',
        paddingTop: '10px',
        paddingBottom: '10px',
    }),
    option: (styles: any) => ({ 
        ...styles, 
        fontSize: '14px'
    }),
    multiValue: (styles: any  ) => {
        return {
            ...styles,
            borderRadius: '50px',
            padding: '4px',
        };
    },
    multiValueLabel: (styles: any) => ({
        ...styles,
        fontWeight: 'bold',
        color: 'black',
        fontSize: '12px',
    }),
    multiValueRemove: (styles: any) => ({
        ...styles,
        color: 'black',
        ':hover': {
            color: 'black',
        },
    }),
};