import HttpClienteToken from '../../services/HttpClientToken';

import { MunicipioInterface } from '../../interfaces/catalogos/MunicipioInterface';

export const ObtenerMunicipios = ( id: number ) => {

    return new Promise<MunicipioInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get(`/Api/Municipio/ObtenerMunicipios/${ id }`, token)
            .then( ({ data } : { data : MunicipioInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const Municipios = () => {

    return new Promise<MunicipioInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Municipio/', token)
            .then( ({ data } : { data : MunicipioInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarMunicipio = ( params: MunicipioInterface ) => {

    return new Promise<MunicipioInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Municipio/Actualizar', params, token)
            .then( ({ data } : { data : MunicipioInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idMunicipio: 0,
                    clave: '',
                    descripcion: '',
                    activo: false,
                    idEstado: 0,                
                });
            });
        }

    });

}

export const AgregarMunicipio = ( params: MunicipioInterface ) => {

    return new Promise<MunicipioInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Municipio/Agregar', params, token)
            .then( ({ data } : { data : MunicipioInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idMunicipio: 0,
                    clave: '',
                    descripcion: '',
                    activo: false,
                    idEstado: 0,             
                });
            });
        }

    });

}