
import HttpClienteToken from '../../services/HttpClientToken';

import { HttpResponse } from '../../interfaces/global/HttpInterface';
import { PaginacionInterface } from '../../interfaces/global/PaginacionInterface';

import { ActuariosRutaProps,  ConsultarRutasAsignadasParams,  FinalizarRutaParams,  GaudarRutaParams, IniciarRutaParams, RutaNotificacionProps, RutaProps, RutasAsignadasResponse, ZonaNotificacionProps } from '../../interfaces/comun/RutaInterface';

import { AxiosError } from 'axios';

export const ObtenerRutas = () => {

    return new Promise<RutaProps[]>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.get('/Api/Ruta/ObtenerRutas', token)
            .then(({ data }: { data: RutaProps[] }) => {
                resolve( data );
            })
            .catch(() => {
                resolve( [] );
            });

        }

    });

}

export const ObtenerNotificaciones = () => {

    return new Promise<ZonaNotificacionProps[]>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.get('/Api/Ruta/ObtenerNotificaciones', token)
            .then(({ data }: { data: ZonaNotificacionProps[] }) => {
                resolve( data );
            })
            .catch(() => {
                resolve( [] );
            });

        }

    });

}

export const ObtenerActuariosRuta = ( id: number ) => {

    return new Promise<ActuariosRutaProps[]>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.get(`/Api/Ruta/ObtenerActuariosRuta/${ id }`, token)
            .then(({ data }: { data: ActuariosRutaProps[] }) => {
                resolve( data );
            })
            .catch(() => {
                resolve( [] );
            });

        }

    });

}

export const GenerarRuta = ( params: ZonaNotificacionProps[] ) => {

    return new Promise<HttpResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Ruta/GenerarRuta/', params, token)
            .then(({ data }: { data: HttpResponse }) => {
                resolve( data );
            })
            .catch( ( ex: AxiosError ) => {
                resolve( {
                    success: false,
                    message: ex.message,
                });
            });

        }

    });

}

export const GuardarRuta = ( params: GaudarRutaParams ) => {

    return new Promise<HttpResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Ruta/GuardarRuta/', params, token)
            .then(({ data }: { data: HttpResponse }) => {
                resolve( data );
            })
            .catch( ( ex: AxiosError ) => {
                resolve( {
                    success: false,
                    message: ex.message,
                });
            });

        }

    });

}

export const AsignarRuta = ( params: GaudarRutaParams ) => {

    return new Promise<HttpResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Ruta/AsignarRuta/', params, token)
            .then(({ data }: { data: HttpResponse }) => {
                resolve( data );
            })
            .catch( ( ex: AxiosError ) => {
                resolve( {
                    success: false,
                    message: ex.message,
                });
            });

        }

    });

}

export const MisRutas = () => {

    return new Promise<RutaProps[]>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.get('/Api/Ruta/MisRutas', token)
            .then(({ data }: { data: RutaProps[] }) => {
                resolve( data );
            })
            .catch(() => {
                resolve( [] );
            });

        }

    });

}

export const RutaNotificacionDetalle = ( idRuta: string ) => {

    return new Promise<RutaNotificacionProps>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.get(`/Api/Ruta/RutaNotificacionDetalle/${idRuta}`, token)
            .then(({ data }: { data: RutaNotificacionProps }) => {
                resolve( data );
            })
            .catch(() => {
                resolve( { success : false } );
            });

        }

    });

}

export const IniciarRuta = ( params : IniciarRutaParams ) => {

    return new Promise<HttpResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Ruta/IniciarRuta', params, token)
            .then(({ data }: { data: HttpResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const FinalizarRuta = ( params : FinalizarRutaParams ) => {

    return new Promise<HttpResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Ruta/FinalizarRuta', params, token)
            .then(({ data }: { data: HttpResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const RutasAsignadas = ( params: PaginacionInterface ) => {

    return new Promise<RutasAsignadasResponse>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Ruta/RutasAsignadas', params, token)
            .then( ({ data } : { data : RutasAsignadasResponse } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve({
                    pageCount: 0,
                    currentPageIndex: 0,
                    recordsCount: 0,
                    records: [],
                });
            });
        }

    });

}

export const ConsultarRutasAsignadas = ( params: ConsultarRutasAsignadasParams ) => {

    return new Promise<RutasAsignadasResponse>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Ruta/ConsultarRutasAsignadas', params, token)
            .then( ({ data } : { data : RutasAsignadasResponse } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve({
                    pageCount: 0,
                    currentPageIndex: 0,
                    recordsCount: 0,
                    records: [],
                });
            });
        }

    });

}
