import HttpClienteToken from '../../services/HttpClientToken';

import { ZonaInterface } from '../../interfaces/catalogos/ZonaInterface';

export const ObtenerZonas = () => {

    return new Promise<ZonaInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Zona/ObtenerZonas', token)
            .then( ({ data } : { data : ZonaInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const Zonas = () => {

    return new Promise<ZonaInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Zona/', token)
            .then( ({ data } : { data : ZonaInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarZona = ( params: ZonaInterface ) => {

    return new Promise<ZonaInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Zona/Actualizar', params, token)
            .then( ({ data } : { data : ZonaInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idZona: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarZona = ( params: ZonaInterface ) => {

    return new Promise<ZonaInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Zona/Agregar', params, token)
            .then( ({ data } : { data : ZonaInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idZona: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}