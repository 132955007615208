import { memo } from 'react';

import { List } from '@mui/joy';

import { DragDropContext, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';

import DraggableListItem from './DraggableListItem';

import { NotificacionFullProps } from '../interfaces/comun/NotificacionInterface';
import { MapProps } from '../interfaces/global/GeolocalizarInterface';

export type DraggableListProps = {
    items: NotificacionFullProps[];
    onDragEnd: OnDragEndResponder;
    setMap: React.Dispatch<React.SetStateAction<MapProps>>
};

const DraggableList = memo( ({ items, onDragEnd, setMap } : DraggableListProps) => {

    return (

        <DragDropContext onDragEnd={ onDragEnd }>
        
            <Droppable droppableId="droppable-list">

                {
                    ( provided ) => (

                        <List 
                            ref={ provided.innerRef } 
                            { ...provided.droppableProps } 
                            sx={{ gap: 2, '--ListItemDecorator-size': '42px' }}                         
                        >
                        
                            {
                                items.map( ( item, index ) => (
                                    <DraggableListItem 
                                        item={ item } 
                                        index={ index } 
                                        key={ item.id } 
                                        onClickMarker={ () => setMap({ center: { lat: item?.lat ?? 0, lng: item?.lng ?? 0 }, zoom: 14 }) } 
                                    />
                                ))
                            } 
                            { provided.placeholder }
                        
                        </List>
                    )
                }
            </Droppable>

        </DragDropContext>
    );
});

export default DraggableList;
