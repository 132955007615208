import HttpClienteToken from '../../services/HttpClientToken';

import { AutoridadDomicilioProps, AutoridadInterface } from '../../interfaces/catalogos/AutoridadInterface';
import { DomicilioInterface } from '../../interfaces/comun/DomicilioInterface';

export const ObtenerAutoridades = () => {

    return new Promise<AutoridadInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Autoridad/ObtenerAutoridades/', token)
            .then( ({ data } : { data : AutoridadInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const Autoridades = () => {

    return new Promise<AutoridadInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Autoridad/', token)
            .then( ({ data } : { data : AutoridadInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarAutoridad = ( params: AutoridadInterface ) => {

    return new Promise<AutoridadInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Autoridad/Actualizar', params, token)
            .then( ({ data } : { data : AutoridadInterface } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idAutoridad: 0, 
                    referencia: 0,
                    descripcion: '',
                    idDomicilio: 0,
                    idMateria: 0,
                    idDistritoJudicial: 0,
                    activo: false,
                });
            });
        }

    });

}

export const AgregarAutoridad = ( params: AutoridadInterface ) => {

    return new Promise<AutoridadInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Autoridad/Agregar', params, token)
            .then( ({ data } : { data : AutoridadInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idAutoridad: 0,
                    referencia: 0,
                    descripcion: '',
                    idDomicilio: 0,
                    idMateria: 0,
                    idDistritoJudicial: 0,
                    activo: false,
                });
            });
        }

    });

}

export const AgregarDomicilio = ( params: AutoridadDomicilioProps ) => {

    return new Promise<DomicilioInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Autoridad/AgregarDomicilio', params, token)
            .then( ({ data } : { data : DomicilioInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idDomicilio: 0,
                    descripcion: '',
                    numero: "",
                    referencia: "",
                    idAsentamiento: 0,
                    idTipoDomicilio: 0,
                });
            });
        }

    });

}