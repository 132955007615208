import HttpClienteToken from '../../services/HttpClientToken';

import { MateriaInterface } from '../../interfaces/catalogos/MateriaInterface';

export const ObtenerMaterias = () => {

    return new Promise<MateriaInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Materia/ObtenerMaterias', token)
            .then( ({ data } : { data : MateriaInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const Materia = () => {

    return new Promise<MateriaInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Materia/', token)
            .then( ({ data } : { data : MateriaInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarMateria = ( params: MateriaInterface ) => {

    return new Promise<MateriaInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Materia/Actualizar', params, token)
            .then( ({ data } : { data : MateriaInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idMateria: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarMateria = ( params: MateriaInterface ) => {

    return new Promise<MateriaInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Materia/Agregar', params, token)
            .then( ({ data } : { data : MateriaInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idMateria: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}