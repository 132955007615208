import HttpClienteToken from '../../services/HttpClientToken';

import { TipoArchivoInterface } from '../../interfaces/catalogos/TipoArchivoInterface';

export const ObtenerTiposArchivos = () => {

    return new Promise<TipoArchivoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoArchivo/ObtenerTiposArchivos', token)
            .then( ({ data } : { data : TipoArchivoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const TiposArchivos = () => {

    return new Promise<TipoArchivoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoArchivo/', token)
            .then( ({ data } : { data : TipoArchivoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarTipoArchivo = ( params: TipoArchivoInterface ) => {

    return new Promise<TipoArchivoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoArchivo/Actualizar', params, token)
            .then( ({ data } : { data : TipoArchivoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoArchivo: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarTipoArchivo= ( params: TipoArchivoInterface ) => {

    return new Promise<TipoArchivoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoArchivo/Agregar', params, token)
            .then( ({ data } : { data : TipoArchivoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoArchivo: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}