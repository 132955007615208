import { useEffect, useState } from "react";

import { Box, Button,  Chip, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Modal, ModalClose, ModalDialog, ModalOverflow, Snackbar, Table, Typography } from "@mui/joy"

import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from "@mui/icons-material/Warning";

import { Layout } from "../components/Layout"
import { Sidebar } from "../components/Sidebar"
import { Header } from "../components/Header"

import { CardZona } from "../components/CardZona"

import Pagination from "../components/Pagination";

import { ModalEditUbicacion } from "../modals/ModalEditUbicacion";
import { ModalMapa } from "../modals/ModalMapa";

import { groupBy } from "../helpers/GroupbyFunction";

import { GenerarRuta, ObtenerNotificaciones } from "../connections/comun/RutaConnection";
import { GuardarUbicacion } from "../connections/comun/NotificacionConnection";

import { SnackbarProps } from "../interfaces/ui/Snackbar";

import { ActuariosRutaProps, ValidateActuariosZona, ZonaNotificacionProps } from "../interfaces/comun/RutaInterface";
import { NotificacionFullProps } from "../interfaces/comun/NotificacionInterface";
import { Location } from "../interfaces/global/GeolocalizarInterface";
import { ActualizarUbicacionParams } from "../interfaces/comun/DomicilioInterface";

export const AsignacionActuarioPage = () => {    

    const [isLoading, setIsLoading] = useState<boolean>( false );
    const [isLoadingLocation, setIsLoadingLocation] = useState<boolean>( false );
    const [isLoadingFinalizar, setIsLoadingFinalizar] = useState<boolean>( false );
    const [reload, setReload] = useState<boolean>( false );

    const [openConfirmacion, setOpenConfirmacion] = useState<boolean>( false );
    const [openMapa, setOpenMapa] = useState<boolean>( false );
    const [openMapaEditar, SetOpenMapaEditar] = useState<boolean>( false );

    const [zonaSelected, setZonaSelected] = useState<ZonaNotificacionProps>( { idZona: 0, zona: '', notificaciones: [], numeroNotificaciones: 0, notificacionesUbicadas: 0, notificacionesNoUbicadas: 0 } );
    const [zonas, setZonas] = useState<ZonaNotificacionProps[]>( [] );

    const [isLoadingRows, setIsLoadingRows] = useState<boolean>( false );
    const [rows, setRows] = useState<NotificacionFullProps[]>( [] );

    const [page, setPage] = useState<number>( 0 );
    const [rowsPerPage, setRowsPerPage] = useState<number>( 10 );

    const [referencia, setReferencia] = useState<string>( '' );
    const [openCedula, setOpenCedula] = useState<boolean>( false );
    const [cedulaNotificacion, setCedulaNotificacion] = useState<string>( '' );

    const [notificacion, setNotificacion] = useState<NotificacionFullProps>( {} );
    const [notificacionAll, setNotificacionAll] = useState<NotificacionFullProps[]>( [] );

    const [zonaMapa, setZonaMapa] = useState<ZonaNotificacionProps>( { } );
    const [notificacionesMapa, setNotificacioneMapa] = useState<NotificacionFullProps[]>( [] );

    const [{ type: typeSnackbar, open: openMessage, message }, setOpenMessage] = useState<SnackbarProps>({
        type: 'warning',
        message: '',
        open: false,
    });

    const handleCloseAlert = () => setOpenMessage({ type: typeSnackbar, open: false, message }) 

    const handleClose = () => {

        setOpenMapa( false );
        setReferencia( '' );
        setNotificacion( {} );

    }

    const handleSaveLocation = async ({ lat, lng }: Location) => {

        setIsLoadingLocation( true );

        const params: ActualizarUbicacionParams = {
            id: referencia,
            lat: lat,
            lng: lng
        }

        await GuardarUbicacion( params ).then(resp => {

            if ( resp ) {

                if( resp.success ){

                    setTimeout(() => {
                        
                        const newRows = rows.map( ( elem ) => {
                            if (elem.id === referencia) {
                                elem.lat = lat;
                                elem.lng = lng;
                                elem.ubicacion = true;
                            }
                            return elem;
                        });      
                        
                        setRows ( newRows );

                        const newZonas = zonas.map( ( e ) => {
                            if( e.idZona === zonaSelected.idZona ){
                                e.notificacionesUbicadas = notificacionAll.filter( (e) => e.ubicacion === true ).length;
                                e.notificacionesNoUbicadas = notificacionAll.filter( (e) => e.ubicacion === false ).length;
                                e.notificaciones = notificacionAll.map( ( elem ) => {
                                    if (elem.id === referencia) {
                                        elem.lat = lat;
                                        elem.lng = lng;
                                        elem.ubicacion = true;
                                    }
                                    return elem;
                                });
                            }
                            return e;
                        });

                        setZonas( newZonas );
                  
                        handleClose();

                        const errorMessage: string = resp.message ?? '';
                        
                        setOpenMessage({ type: 'success', open: true, message: errorMessage });
                        
                        setIsLoadingLocation( false );     
                        SetOpenMapaEditar( false );                  

                    }, 400);

                }else{

                    setTimeout(() => {

                        const errorMessage: string = resp.message ?? '';

                        setOpenMessage({ type: 'danger', open: true, message: errorMessage });

                        setIsLoadingLocation( false );
                        SetOpenMapaEditar( false );                  

                    }, 400);
                }

            }

        });

    }

    const handleChangeActuario = ( zonaSelected: ZonaNotificacionProps, actuarios: ActuariosRutaProps[] ) => {

        const isValidate = Math.trunc( ( zonaSelected?.numeroNotificaciones ?? 0 ) / actuarios.length ) === 0;

        if( isValidate ) return;

        const newZonas = zonas.map( ( e ) => {
            if( e.idZona === zonaSelected.idZona ){             
                e.actuarios = actuarios; 
            }
            return e;
        });

        setZonas( newZonas );
    }

    const handleClick = async ( zona: ZonaNotificacionProps , notificaciones: NotificacionFullProps[], allNotifications: NotificacionFullProps[] ) => {

        setIsLoadingRows( true );
        setRows( [] );

        setTimeout(() => {
            
            setZonaSelected( zona );
            setRows( notificaciones );
            setNotificacionAll( allNotifications );
            setIsLoadingRows( false );      
        
        }, 400);

    }

    const handleValidateFields = () => {

        let valid = true;

        const zonasArray = zonas.filter( (e) => e.notificacionesUbicadas !== 0 && e.notificacionesNoUbicadas === 0 );

        let arrayCompare: ValidateActuariosZona[] = [];
        zonasArray.forEach( ({ idZona, zona, actuarios }) => {
            actuarios?.forEach( ({ idUsuario, nombreCompleto }) => {
                arrayCompare.push({ idZona: idZona ?? 0, zona: zona ?? '', idUsuario, nombreCompleto });
            });          
        });

        const result: Map<string, ValidateActuariosZona[]> = groupBy( arrayCompare );

        let message = '';
        result.forEach( ( e, key ) => {
            if( e.length > 1 ){
                message = 'El actuario ' + key + ' se encuentra en las zonas ' + e.map( ( elem ) => elem.zona ).join(', ');
            }            
        });

        if( message ){

            setOpenMessage({
                open: true,
                type: 'warning',
                message: message,
            });      

            return false;
        }

        return valid;
    }

    const handleFinalizar = async () => {       

        const valid = handleValidateFields();

        if( !valid ){            
            setOpenConfirmacion( false );
            return;
        }

        setIsLoadingFinalizar( true );

        await GenerarRuta( zonas.filter( (e) => e.actuarios?.length !== 0 ) ).then( resp => {

            const { success, message } = resp;

            const errorMessage: string = message ?? '';

            if( success ){

                setTimeout(() => {                   

                    setOpenMessage({ type: 'success', open: true, message: errorMessage });
         
                    setReload( true );

                }, 400);

            }
            else {

                setTimeout(() => {

                    setOpenMessage({ type: 'danger', open: true, message: errorMessage });

                    setOpenConfirmacion( false );
                    setIsLoadingFinalizar( false );

                }, 400);

            }

        });

    }

    const handleViewMapa = ( zona: ZonaNotificacionProps, notificaciones: NotificacionFullProps[] ) => {
        setZonaMapa( zona );
        setNotificacioneMapa( notificaciones );
        setOpenMapa( true );
    }

    useEffect(() => {

        async function Obtener() {

            setIsLoading( true );
            setIsLoadingRows( true );

            await ObtenerNotificaciones().then(resp => {

                setTimeout(() => {

                    setZonas( resp );
                    setIsLoading( false );                    
                    setIsLoadingRows( false );

                }, 400);

            })

        }
      
        Obtener();

    }, [ ])

    useEffect(() => {

        async function Obtener() {          

            await ObtenerNotificaciones().then(resp => {

                setTimeout(() => {

                    setZonas( resp );
                    setRows( [] );
                    setReload( false );

                    setOpenConfirmacion( false );        
                    setIsLoadingFinalizar( false );

                }, 400);

            })

        }

        if( reload ){
            Obtener();
        }      

    }, [ reload ])

    return (
        
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Snackbar
                key={ 'snackbar-login' }
                autoHideDuration={ 3000 }
                color={ typeSnackbar }
                size="md"
                variant="solid"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={ openMessage }
                onClose={ handleCloseAlert }
                startDecorator={ typeSnackbar === 'success' ? <CheckIcon /> : <WarningIcon /> }
            >
                { message }
            </Snackbar>

            <Sidebar />
            
            <Header />       
       
            <Layout title='Generar ruta'>

                <Grid container spacing={1}>   

                    {
                        isLoading
                        ?
                            <Grid xs={12}>
                                <Box sx={{ textAlign: 'center', height: 150, mt: 5 }}>
                                    <CircularProgress color='neutral' size='md' />
                                </Box>
                            </Grid>
                        :                        
                            zonas.map( ( elem, index ) => (

                                <Grid xs={12} sm={6} md={6} lg={6} xl={3} key={ index }>  

                                    <CardZona  
                                        zonaNotificacion={ elem }
                                        onClick={ handleClick }
                                        onChangeActuario={ handleChangeActuario }
                                        onViewMapa={ handleViewMapa }
                                    />

                                </Grid>         

                            ))                                
                    }    
                
                </Grid>     

                <Grid container textAlign='end'> 

                    <Grid xs={12} >

                        <Button 
                            size='lg'
                            sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }} 
                            startDecorator={ <DoneOutlineIcon /> }          
                            disabled={ 
                                isLoading ||
                                //zonas.filter( ( e ) => e.notificacionesNoUbicadas !== 0).length !== 0 ||                               
                                //zonas.filter( ( e ) => e.numeroNotificaciones !== 0 && e.actuarios?.length === 0 && e.notificacionesNoUbicadas === 0 ).length !== 0 //||
                                zonas.filter( ( e ) => e.actuarios?.length !== 0 ).length === 0                                
                            }                
                            onClick={ () => setOpenConfirmacion( true ) }
                        >
                            Finalizar asignación
                        </Button>

                    </Grid>

                </Grid>  
              
                <Grid container >

                    <Grid xs={12}>

                        <Table
                            aria-label="striped table" 
                            stripe={'even'} 
                            borderAxis='x'
                            size='lg' 
                            sx={{ 
                                width: { xs: '100%', md: '100%' },
                                display:{ xs: 'block', md: 'inline' },
                                overflowX: { xs: 'auto', md: 'hidden' },
                            }}
                        >

                            <thead style={{ width: '100%' }}>

                                <tr>
                                    <th style={{ width: '30%' }}>Persona a notificar</th>
                                    <th style={{ width: '30%' }}>Domicilio</th>
                                    <th style={{ width: '10%' }}>Notificación</th>
                                    <th style={{ width: '15%', textAlign: 'center' }}>Cédula de <br /> notificación</th>
                                    <th style={{ width: '10%' }}>Zona</th>
                                    <th style={{ width: '15%', textAlign: 'center' }}>Ubicación</th>                   
                                </tr>

                            </thead>

                            <tbody>

                                {

                                    isLoadingRows
                                    ?
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '250px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </td>
                                        </tr>
                                    :                                    
                                        (
                                            rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        )
                                        .map((row, index) => (
                                            <tr key={index}>

                                                <td>
                                                    <Typography fontSize={14} fontWeight='bold'> {row.persona} </Typography>
                                                    <Typography fontSize={14}> ({row.tipoParte}) </Typography>
                                                </td>

                                                <td><Typography fontSize={14}> { row.domicilio } </Typography></td>

                                                <td>
                                                    <Typography fontSize={14} fontWeight='bold'>{ row.referencia } </Typography>
                                                    <Typography fontSize={14}> ({ row.tipoNotificacion }) </Typography>
                                                </td>

                                                <td style={{ textAlign: 'center' }}>

                                                    <IconButton variant='solid' size='md' onClick={() => { setOpenCedula(true); setCedulaNotificacion( row.cedulaNotificacion ?? "") }}>
                                                        <FileCopyIcon />
                                                    </IconButton>

                                                </td>

                                                <td> <Typography fontSize={14}> { row.zona } </Typography> </td>

                                                <td style={{ textAlign: 'center' }}>

                                                    {
                                                        row.ubicacion
                                                        ?
                                                            <Chip
                                                                size="md"
                                                                variant="outlined"
                                                                color="success"
                                                                endDecorator={<CheckCircleOutlineIcon />}
                                                                onClick={() => { setReferencia(row?.id ?? ''); setNotificacion( row ); SetOpenMapaEditar( true ); }}
                                                            >
                                                                Ubicada
                                                            </Chip>
                                                        :
                                                            <Chip
                                                                size="md"
                                                                variant="outlined"
                                                                color="danger"
                                                                endDecorator={<WrongLocationIcon />}
                                                                onClick={() => { setReferencia(row?.id ?? ''); setNotificacion( row ); SetOpenMapaEditar( true ); }}
                                                            >
                                                                No ubicada
                                                            </Chip>
                                                    }

                                                </td>                                           

                                            </tr>
                                        ))
                                }

                                {
                                    ( !isLoadingRows && rows.length === 0 )
                                    &&
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '250px' }}>
                                                <Typography fontSize={14} fontWeight={'bold'}>No se encontraron registros</Typography>
                                            </td>
                                        </tr>
                                }   

                            </tbody>

                        </Table>

                    </Grid>

                </Grid>                               

                <Pagination
                    gotoPage={setPage}
                    length={rows.length}
                    pageSize={rowsPerPage}
                    setPageSize={setRowsPerPage}
                />              
    
            </Layout>       

            <Modal 
                disableEscapeKeyDown
                open={ openConfirmacion }              
                onClose={ ( _, r ) => { 
                    if( r === 'backdropClick' ) return;                     
                    setOpenConfirmacion( false )
                }}
                sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}               
            >

                <ModalDialog variant="outlined" role="alertdialog" sx={{ width: 450 }}>

                    <DialogTitle>
                        <WarningRoundedIcon />
                        Confirmación
                    </DialogTitle>

                    <Divider />

                    <DialogContent>
                        ¿Desea finalizar la asignación de las notificaciones?
                    </DialogContent>

                    <DialogActions>

                        <Button sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }} loading={ isLoadingFinalizar } onClick={ handleFinalizar } >
                            Aceptar
                        </Button>

                        <Button variant="plain" color="neutral" disabled={ isLoadingFinalizar } onClick={ () => setOpenConfirmacion( false ) } >
                            Cancelar
                        </Button>

                    </DialogActions>

                </ModalDialog>

            </Modal>

            <Modal
                disableEscapeKeyDown
                open={ openCedula }
                onClose={(_, r) => {
                    if (r === 'backdropClick') return;
                    setOpenCedula(false)
                }}
                sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)' }}
            >
                <ModalOverflow>

                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    >
                        <ModalClose />
                        <DialogTitle> Cédula de notificación </DialogTitle>

                        <DialogContent>

                            <Grid container mt={2} sx={{ width: '800px' }}>

                                <Grid xs={12}>

                                    <iframe title='acuse' src={ cedulaNotificacion } height="500px" width="100%"></iframe>

                                </Grid>

                            </Grid>

                        </DialogContent>

                    </ModalDialog>

                </ModalOverflow>

            </Modal>

            <ModalEditUbicacion
                open={ openMapaEditar }
                isLoading={ isLoadingLocation }
                setOpen={ SetOpenMapaEditar }
                notificacion={ notificacion }
                setNotificacion={ setNotificacion }
                handleSave={ handleSaveLocation }
            />

            <ModalMapa
                open={ openMapa }
                setOpen={ setOpenMapa }
                zonaNotificacion={ zonaMapa }
                notificaciones={ notificacionesMapa }
            />

        </Box>

    )
}



