import HttpClienteToken from '../../services/HttpClientToken';

import { TipoAsentamientoInterface } from '../../interfaces/catalogos/TipoAsentamientoInterface';

export const ObtenerTiposAsentamientos = () => {

    return new Promise<TipoAsentamientoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoAsentamiento/ObtenerTiposAsentamientos', token)
            .then( ({ data } : { data : TipoAsentamientoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const TiposAsentamientos = () => {

    return new Promise<TipoAsentamientoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoAsentamiento/', token)
            .then( ({ data } : { data : TipoAsentamientoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarTipoAsentamiento = ( params: TipoAsentamientoInterface ) => {

    return new Promise<TipoAsentamientoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoAsentamiento/Actualizar', params, token)
            .then( ({ data } : { data : TipoAsentamientoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoAsentamiento: 0,
                    clave: '',     
                    descripcion: '',
                    activo: false,      
                });
            });
        }

    });

}

export const AgregarTipoAsentamiento = ( params: TipoAsentamientoInterface ) => {

    return new Promise<TipoAsentamientoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoAsentamiento/Agregar', params, token)
            .then( ({ data } : { data : TipoAsentamientoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoAsentamiento: 0,
                    clave: '',     
                    descripcion: '',
                    activo: false,         
                });
            });
        }

    });

}