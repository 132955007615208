import HttpClienteToken from '../../services/HttpClientToken';
import HttpClientFormData from '../../services/HttpClientFormData';

import { AxiosError } from 'axios';

import { HttpResponse } from '../../interfaces/global/HttpInterface';
import { ActivarUsuarioParams, ActualizarImagenPerfilResponse, ActualizarUsuarioParams, AgregarUsuarioParams, AgregarUsuarioResponse, BuscarUsuarioResponse, CambiarContrasenaParams, CambiarInformacionParams, PerfilResponse, UsuarioDetalleResponse, UsuarioProps } from '../../interfaces/admin/UsuarioInterface';

export const CambiarContrasena = ( params: CambiarContrasenaParams ) => {

    return new Promise<HttpResponse>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Usuario/CambiarContrasena/', params, token)
            .then( ({ data } : { data : HttpResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: HttpResponse = {
                    success: false,
                    message: error.message,
                };
    
                resolve( data );
            });
        }

    });

}

export const ObtenerUsuarios = ( ) => {

    return new Promise<UsuarioProps[]>( ( resolve, eject ) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Usuario/ObtenerUsuarios/', token)
            .then( ({ data } : { data : UsuarioProps[]} ) => {
                resolve( data );
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActivarUsuario = ( params: ActivarUsuarioParams ) => {

    return new Promise<HttpResponse>( ( resolve, eject ) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Usuario/ActivarUsuario/', params, token)
            .then( ({ data } : { data : HttpResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: HttpResponse = {
                    success: false,
                    message: error.message,
                };
    
                resolve( data );
            });
        }

    });

}

export const BuscarUsuarioRH = ( curp: string ) => {

    return new Promise<BuscarUsuarioResponse>( ( resolve, eject ) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get(`/Api/Usuario/BuscarUsuario/${ curp }`, token)
            .then( ({ data } : { data : BuscarUsuarioResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: BuscarUsuarioResponse = {
                    success: false,
                    message: error.message,
                };
    
                resolve( data );
            });
        }

    });

}

export const ObtenerUsuario = ( id: string ) => {

    return new Promise<UsuarioDetalleResponse>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? ' ');

        if( data ){
            const { token } = data;

            HttpClienteToken.get(`/Api/Usuario/ObtenerUsuario/${ id }`, token)
            .then( ({ data } : { data : UsuarioDetalleResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: UsuarioDetalleResponse = {
                    success: false,
                    message: error.message,
                };
    
                resolve( data );
            });
        }

    });

}

export const ActualizarUsuario = ( params: ActualizarUsuarioParams ) => {

    return new Promise<HttpResponse>( ( resolve, eject ) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Usuario/ActualizarUsuario/', params, token)
            .then( ({ data } : { data : HttpResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: HttpResponse = {
                    success: false,
                    message: error.message,
                };
    
                resolve( data );
            });
        }

    });

}

export const AgregarUsuario = ( params: AgregarUsuarioParams ) => {

    return new Promise<AgregarUsuarioResponse>( ( resolve, eject ) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Usuario/AgregarUsuario/', params, token)
            .then( ({ data } : { data : AgregarUsuarioResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: AgregarUsuarioResponse = {
                    success: false,
                    message: error.message,
                    id: '',
                    idUsuario: 0,                 
                };
    
                resolve( data );
            });
        }

    });

}

export const ObtenerPerfil = () => {

    return new Promise<PerfilResponse>( ( resolve, eject ) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Usuario/ObtenerPerfil/', null, token)
            .then( ({ data } : { data : PerfilResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: PerfilResponse = {
                    success: false,
                    message: error.message,           
                };
    
                resolve( data );
            });
        }

    });

}

export const CambiarInformacion = ( params: CambiarInformacionParams ) => {

    return new Promise<HttpResponse>( ( resolve, eject ) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Usuario/CambiarInformacion/', params, token)
            .then( ({ data } : { data : HttpResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: HttpResponse = {
                    success: false,
                    message: error.message,           
                };
    
                resolve( data );
            });
        }

    });

}

export const ActualizarImagenPerfil = ( params: FormData ) => {

    return new Promise<ActualizarImagenPerfilResponse>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClientFormData.post('/Api/Usuario/ActualizarImagenPerfil/', params, token)
            .then( ({ data } : { data : ActualizarImagenPerfilResponse} ) => {
                resolve( data );
            })
            .catch( (error: AxiosError) => {
                
                const data: ActualizarImagenPerfilResponse = {
                    success: false,
                    message: error.message,           
                };
    
                resolve( data );
            });
        }

    });

}

export const ObtenerActuarios = ( ) => {

    return new Promise<UsuarioProps[]>( ( resolve, eject ) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Usuario/ObtenerActuarios/', token)
            .then( ({ data } : { data : UsuarioProps[]} ) => {
                resolve( data );
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}