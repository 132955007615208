import { useEffect, useState } from "react";

import { Modal, ModalOverflow, ModalDialog, ModalClose, DialogTitle, Chip, DialogContent, Grid, Typography, CircularProgress } from "@mui/joy";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';

import { APIProvider, Map } from "@vis.gl/react-google-maps";

import { MarkerNotification } from "../components/MarkerNotification";

import { NotificacionFullProps } from "../interfaces/comun/NotificacionInterface";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    notificacion: NotificacionFullProps;
}

export const ModalMostrarMapa = ({ open, setOpen, notificacion }: Props) => {

    const [isLoadingMapa, setIsLoadingMapa] = useState<boolean>(true);

    useEffect(() => {

        setIsLoadingMapa(true);

        setTimeout(() => {
            setIsLoadingMapa(false);
        }, 400);

    }, [])

    return (
        <Modal
            disableEscapeKeyDown
            open={open}
            onClose={(_, r) => {
                if (r === 'backdropClick') return;
                setOpen(false)
            }}
            sx={{ zIndex: 9999999, backdropFilter: 'blur(0px)' }}
        >
            <ModalOverflow>

                <ModalDialog
                    aria-labelledby="server-modal-title"
                    aria-describedby="server-modal-description"
                    layout="center"
                    sx={(notificacion.lat)? { width: '800px'} : {width : '400px'}}
                >
                    <ModalClose />
                    <DialogTitle>

                        {
                            notificacion.lat
                                ?
                                <Chip size="md" variant="outlined" color="success" endDecorator={<CheckCircleOutlineIcon />} > Ubicada </Chip>
                                :
                                <></>
                        }
                    </DialogTitle>

                    {
                        notificacion.lat ?
                            <DialogContent>

                                {
                                    isLoadingMapa
                                        ?
                                        <Grid container sx={{ maxHeight: '550px' }}>
                                            <Grid xs={12} sx={{ textAlign: 'center', p: '200px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container mt={2}>
                                            <Grid xs={12}>
                                                <Typography>
                                                    {notificacion?.domicilio ?? ''}
                                                </Typography>
                                            </Grid>

                                            <Grid xs={12} mt={2}>

                                                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}>

                                                    <Map
                                                        mapId={'5cb0f0703c7dc687'}
                                                        style={{ height: '500px' }}
                                                        defaultCenter={{ lat: notificacion?.lat ?? 0, lng: notificacion?.lng ?? 0 }}
                                                        defaultZoom={18}
                                                        gestureHandling={'greedy'}
                                                        disableDefaultUI={true}
                                                    >
                                                        
                                                        <MarkerNotification                                                                                              
                                                            latitud={ notificacion?.lat ?? 0 }
                                                            longitud={ notificacion?.lng ?? 0 }
                                                            notificacion={ notificacion }     
                                                        />

                                                    </Map>

                                                </APIProvider>

                                            </Grid>
                                        </Grid>
                                }

                            </DialogContent>
                            :
                            <td style={{ textAlign: 'center', padding: '100px' }}>
                                <Chip size="lg" variant="outlined" color="danger" endDecorator={<WrongLocationIcon />} > No ubicada </Chip>
                            </td>
                    }


                </ModalDialog>

            </ModalOverflow>

        </Modal>
    )
}
