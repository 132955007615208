import { useState, useEffect } from "react";

import { Modal, ModalOverflow, ModalDialog, ModalClose, DialogTitle, Grid, Box, Typography, CircularProgress } from "@mui/joy";

import { APIProvider, Map, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";

import { convertirHora } from "../helpers/FormatHora";

import { NotificacionFullProps } from "../interfaces/comun/NotificacionInterface";

import { MapProps } from "../interfaces/global/GeolocalizarInterface";
import { MarkerNotification } from "../components/MarkerNotification";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    notificaciones: NotificacionFullProps[];
}

export const ModalRutaActuario = ( { open, setOpen, notificaciones } : Props ) => {
    
    const [{ center, zoom }, setMap] = useState<MapProps>({
        center: { lat: 27.2113713, lng: -101.9014431 },
        zoom: 7        
    });

    const [isLoading, setIsLoading] = useState<boolean>( true );

    useEffect(() => {

        function load(){
       
            setIsLoading( true );
    
            setTimeout(() => {

                if( notificaciones.length !== 0 ){
                   
                    let index = Math.ceil(( notificaciones?.length ?? 0 ) / 2);

                    if( index !== 0 ){
                        index = index - 1;
                    }

                    const latitud = notificaciones?.[index].lat ?? 0;
                    const longitud = notificaciones?.[index].lng ?? 0;

                    setMap({
                        center: { lat: latitud, lng: longitud },
                        zoom: 10       
                    });   

                }
                
                setIsLoading( false );
    
            }, 400);

        }

        if( open ){
            load();
        }
      
    }, [ open, notificaciones ])    

    return (       
        <Modal             
            disableEscapeKeyDown                
            open={ open }
            onClose={ ( _, r ) =>{ 
                if( r === 'backdropClick' ) return;                     
                setOpen( false )
            }}
            sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
        >
            <ModalOverflow>
            
                <ModalDialog
                    aria-labelledby="server-modal-title"
                    aria-describedby="server-modal-description"
                    layout="center"
                    sx={{ width: '900px' }}
                >
                    <ModalClose />
                    <DialogTitle> 
                        Ruta                       
                    </DialogTitle>

                    {
                        isLoading
                        ?
                            <Grid container sx={{ maxHeight: '550px' }}>
                                <Grid xs={12} sx={{ textAlign: 'center', p: '200px' }}>
                                    <CircularProgress color='neutral' size='md' />
                                </Grid>
                            </Grid>
                        :
                            <Grid container spacing={1}>  
                                
                                <APIProvider apiKey={ process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '' }>

                                    <Map
                                        mapId={'5cb0f0703c7dc687'}
                                        style={{ height: '80vh' }}
                                        center={ center }
                                        zoom={ zoom }                         
                                        gestureHandling={ 'greedy' }  
                                        onCameraChanged={ ( e ) =>  
                                            setMap({
                                                center: e.detail.center,
                                                zoom: e.detail.zoom       
                                            })
                                        }        
                                    >

                                        {
                                            notificaciones.map( ( e, index ) => (

                                                <MarkerNotification   
                                                    key={ index }                                            
                                                    latitud={ e?.lat ?? 0 }
                                                    longitud={ e?.lng ?? 0 }
                                                    notificacion={ e }     
                                                    setMap={ setMap }     
                                                    label={ (( e.indice ?? 0 ) + 1 ).toString() }
                                                    viewPin={ true } 
                                                />

                                            ))
                                        }


                                    </Map>

                                    <Directions notificaciones={ notificaciones ?? [] }  />

                                </APIProvider>     
                            
                            </Grid>          
                    }            

                </ModalDialog>
                
            </ModalOverflow>

        </Modal>
    )
}

interface PropsRuta {
    notificaciones: NotificacionFullProps[];
}

function Directions( { notificaciones }: PropsRuta ) {

    const map = useMap();
    const routesLibrary = useMapsLibrary('routes');
    
    const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>();
    const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
    const [routes, setRoutes] = useState<google.maps.DirectionsRoute[]>([]);

    const [distance, setDistance] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    
    const [waypoints, setWaypoints] = useState<google.maps.DirectionsWaypoint[]>( [] );

    const selected = routes[0];
    const leg = selected?.legs[0];

    useEffect(() => {

        setDistance( 0 );
        setDuration( 0 );
          
        const points: google.maps.DirectionsWaypoint[] = notificaciones.map( elem => {             
            return {
                location: {
                    lat: elem.lat ?? 0,
                    lng: elem.lng ?? 0,
                },
                stopover: true,
            };       
        });

        setWaypoints( points );

    }, [ notificaciones ])   

    useEffect(() => {
           
        if( selected?.legs?.length !== 0 ){

            let dur = 0;
            let dis = 0;
            selected?.legs?.forEach( e => {
                dur += e.duration?.value ?? 0;
                dis += e.distance?.value ?? 0;
            });

            setDistance( dis );
            setDuration( dur );
        }

    }, [ selected ])    

    // Initialize directions service and renderer
    useEffect(() => {

        if (!routesLibrary || !map) return;     

        setDirectionsService(new routesLibrary.DirectionsService());
        setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map, markerOptions: { visible: false, draggable: true } }));
    
    }, [routesLibrary, map, waypoints]);

    // Use directions service
    useEffect(() => {

        if (!directionsService || !directionsRenderer) return;

        if( waypoints.length !== 0 ){

            const origin = waypoints[0].location ?? '';
            const destination = waypoints[ waypoints.length - 1 ].location ?? '';

            directionsService        
                .route({
                    origin: origin,                    
                    destination: destination,
                    travelMode: google.maps.TravelMode.DRIVING,
                    provideRouteAlternatives: false,
                    //optimizeWaypoints: true,
                    waypoints: waypoints.filter( ( _, index ) => index !== 0 && index !== waypoints.length - 1 ),                                
                })
                .then(response => {
                    directionsRenderer.setDirections(response);
                    setRoutes(response.routes);
                });
        }


        return () => directionsRenderer.setMap( null );

    }, [ directionsService, directionsRenderer, waypoints ]);

    // Update direction route
    useEffect(() => {

        if ( !directionsRenderer ) return;
        
        directionsRenderer.setRouteIndex( 0 );

    }, [ directionsRenderer ]);

    if (!leg) return null;

    return ( 
        ( distance !== 0 && duration !== 0 )        
        ?
            <Box position={'absolute'} top={70} left={'40%'} bgcolor={'rgba(255, 255, 255, 0.9)'} p={4} borderRadius={30}>

                <Typography level="title-lg" fontSize={22} fontWeight={'normal'}>
                    Distancia: <strong> { Math.ceil(distance / 1000) } Km </strong>
                </Typography>

                <Typography level="title-lg" fontSize={22} fontWeight={'normal'} mt={2}>
                    Duración: <strong> { convertirHora( duration ) } </strong>
                </Typography>

            </Box>
        :
            <></>
    );
}
