
import { useNavigate } from 'react-router-dom';

import { Box, Container, Typography, Button } from '@mui/joy';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const NotFoundPage = () => {

    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', color: 'white', backgroundColor: "#004360", }} >
            
            <Container maxWidth='lg' sx={{ bgcolor: 'white', minHeight: '100vh', pt:'7em' }} >

                <Container component="main" sx={{ mt: 1, mb: 3 }} maxWidth="md">

                    <Container component="main" sx={{ textAlign: 'center' }} maxWidth="sm">         
                        <img
                            src={ process.env.PUBLIC_URL + "/logos/Logo_CA.png"}
                            alt={"PJECZ"}
                            style={{ width: "400px" }}
                        />
                    </Container> 

                    <Typography component="h1" level='h2' mt={10} textAlign='center'>
                        LO SENTIMOS, ESTA PÁGINA NO ESTÁ DISPONIBLE
                    </Typography>

                </Container>   

                <Container component="main" sx={{ mt: 4, mb: 4 }} maxWidth="sm">
                    <Typography component="article" level='body-md' textAlign='center'>
                        Es posible que el enlace haya sido cambiado, eliminado, no exista ó no tenga los permisos necesarios.
                    </Typography>
                </Container> 

                <Container component="main" sx={{ mt: 10, textAlign: 'center' }} maxWidth="sm">               
                    <Button color='neutral' variant="solid" startDecorator={ <ArrowBackIcon /> } onClick={ () => navigate(-1) } >
                        Regresar a la pagina anterior
                    </Button> 
                </Container> 

            </Container>

        </Box>
    )
}