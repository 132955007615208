import { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import { AuthRoutes} from './AuthRoutes';

import { login } from '../store/slices/authSlice';

import { RootState } from '../store/index';
import { SagaRoutes } from './SagaRoutes';

export const AppRouter = () => {

    const dispatch = useDispatch();

    const token = useRef<String | null>(null);

    const { token: tokenRedux } = useSelector((state: RootState) => state.auth);

    const data = window.localStorage.getItem("data");

    useEffect(() => {
        
        if( data ){

            dispatch( login( JSON.parse( data ) ) );

        } 
        else{            
            localStorage.removeItem('data');
            localStorage.removeItem('lastPath');
        }      

    }, [ data, dispatch ]); 

    if( data ){
        const dataJSON = JSON.parse( data );

        if( dataJSON ){
            token.current = dataJSON.token;
        }
    }
    else{
        token.current = tokenRedux ?? '';
    }

    return (
        <BrowserRouter>  

            <Routes>
                
                <Route path="/auth/*" element={ <PublicRoute token={ token.current } children={ <AuthRoutes /> } /> } />   

                <Route path="/*" element={ <PrivateRoute token={ token.current } children={ <SagaRoutes /> } /> } />  

            </Routes>
        
        </BrowserRouter>
    )

}