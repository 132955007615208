import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Button, Card, CardActions, CardContent, FormControl, FormLabel, Grid, Input, Typography } from "@mui/joy"

import ArrowForwardIosOutlined from "@mui/icons-material/ArrowForwardIosOutlined";

import { Header } from "../../components/Header"

import { Sidebar } from "../../components/Sidebar"

import { Layout } from "../../components/Layout"

import { catalogosDB } from "../../data/Catalogos"
import { CatalogoProps } from "../../interfaces/admin/CatalogoInterface";

export const CatalogosPage = () => {

    const navigate = useNavigate();

    const [catalogos, setCatalogos] = useState<CatalogoProps[]>( [] );
    
    const [valueFilter, setValueFilter] = useState<string>( '' );

    const filter = () => {

        if( valueFilter !== ""){
            
            const catalogosFilter = catalogosDB.filter(function ({ descripcion }) { 
    
                const descripcionFilter = descripcion.toLowerCase().indexOf( valueFilter.toLowerCase() );
                
                return descripcionFilter !== -1;
            });

            setCatalogos( catalogosFilter );
        }
        else{
            setCatalogos( catalogosDB );
        }

    }

    useEffect(() => {
        
        setCatalogos(
            catalogosDB.sort((a: CatalogoProps, b: CatalogoProps) => {
                let fa = a.descripcion.toLowerCase(),
                    fb = b.descripcion.toLowerCase();
            
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            })
        );

    }, [ ]);


    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Sidebar />
            
            <Header />

            <Layout title={'Catálogos'}>

                <Grid container spacing={3} >
                                        
                    <Grid xs={12}>

                        <FormControl>
                            <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                Buscar catálogo
                            </FormLabel>
                            <Input
                                sx={{ '--Input-decoratorChildHeight': '45px' }}
                                placeholder=""     
                                autoComplete="off" 
                                size="lg"
                                value={ valueFilter }   
                                onChange={ ( e ) => setValueFilter( e.target.value ) }
                                onKeyUp={ filter }                                
                            />        
                        </FormControl>

                    </Grid>

                    {
                        catalogos.map( ( elem, index ) => (

                            <Grid key={ index } xs={12} sm={6} md={4} lg={3} xl={2}>

                                <Card variant="outlined" >
                                
                                    <CardContent>
                                        
                                        <Typography level="title-lg">
                                            { elem.descripcion } 
                                        </Typography>                                   

                                    </CardContent>
                                
                                    <CardActions buttonFlex="1">                          
                                    
                                        <Button size='sm' variant="solid" color="neutral" endDecorator={ <ArrowForwardIosOutlined /> } onClick={ () => navigate( elem.path ) } >
                                            Ver
                                        </Button>

                                    </CardActions>
                                    
                                </Card>


                            </Grid>

                        ))
                    }

                
                </Grid>

            </Layout>

        </Box>
    )

}
