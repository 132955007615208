import HttpClienteToken from '../../services/HttpClientToken';

import { SubTipoAcuerdoInterface } from '../../interfaces/catalogos/SubTipoAcuerdoInterface';

export const ObtenerSubTiposAcuerdos = () => {

    return new Promise<SubTipoAcuerdoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/SubTipoAcuerdo/ObtenerSubTiposAcuerdos', token)
            .then( ({ data } : { data : SubTipoAcuerdoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ObtenerSubTiposAcuerdosTipoAcuerdo = ( id: number ) => {

    return new Promise<SubTipoAcuerdoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get(`/Api/SubTipoAcuerdo/ObtenerSubTiposAcuerdosTipoAcuerdo/${ id }`, token)
            .then( ({ data } : { data : SubTipoAcuerdoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const SubTipoAcuerdos = () => {

    return new Promise<SubTipoAcuerdoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/SubTipoAcuerdo/', token)
            .then( ({ data } : { data : SubTipoAcuerdoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarSubTipoAcuerdo = ( params: SubTipoAcuerdoInterface ) => {

    return new Promise<SubTipoAcuerdoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/SubTipoAcuerdo/Actualizar', params, token)
            .then( ({ data } : { data : SubTipoAcuerdoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idSubTipoAcuerdo: 0,
                    descripcion: '',
                    activo: false,
                    idTipoAcuerdo: 0,                    
                });
            });
            
        }

    });

}

export const AgregarSubTipoAcuerdo = ( params: SubTipoAcuerdoInterface ) => {

    return new Promise<SubTipoAcuerdoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/SubTipoAcuerdo/Agregar', params, token)
            .then( ({ data } : { data : SubTipoAcuerdoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idSubTipoAcuerdo: 0,
                    descripcion: '',
                    activo: false,
                    idTipoAcuerdo: 0,                    
                });
            });
        }

    });

}