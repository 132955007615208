import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Grid, Button, Table, Switch, IconButton, Typography, CircularProgress, DialogActions, FormControl, FormLabel, Modal, ModalDialog, ModalOverflow, Textarea, Alert } from '@mui/joy';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Sidebar } from '../../components/Sidebar';

import { Header } from '../../components/Header';

import { Layout } from '../../components/Layout';

import Pagination from '../../components/Pagination';

import { ActualizarPuesto, AgregarPuesto, Puestos } from '../../connections/catalogos/PuestoConnection';

import { PuestoInterface } from '../../interfaces/catalogos/PuestoInterface';

interface Errors {
    descripcion?:  string;
}

type OptionType = 'Agregar' | 'Editar' ; 

export const PuestoPage = ( ) => {

    const navigate = useNavigate();

    const [rows, setRows] = useState<PuestoInterface[]>( [] );

    const [isLoading, setIsLoading] = useState<boolean>( true );
    const [isSaving, setIsSaving] = useState<boolean>( false );

    const [page, setPage] = useState<number>( 0 );
    const [rowsPerPage, setRowsPerPage] = useState<number>( 10 );

    const [open, setOpen] = useState<boolean>( false );
    
    const [referencia, setReferencia] = useState<number>( 0 );
    const [opcion, setOpcion] = useState<OptionType>( 'Agregar' );
   
    const [descripcion, setDescripcion] = useState<string>( '' );

    const [errors, setErrors] = useState<Errors>( {} );

    const handleCloseModal = () => {
        setOpen( false );
        setErrors( { } );
    }

    const handleChangeSwitch = ( id: number, value: boolean, PuestoSelected: PuestoInterface ) => {
        
        const newRows = rows.map( (row) => { if(row.idPuesto === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );        
        
        cambiarEstatus( PuestoSelected );
    }

    const cambiarEstatus = async ( { idPuesto, descripcion, activo }: PuestoInterface ) => {

        const params: PuestoInterface = {
            idPuesto,     
            descripcion,
            activo,
        };

        await ActualizarPuesto( params );
    }

    const EditarRegistro = ({ idPuesto, descripcion } : PuestoInterface) => {
        
       
        setDescripcion( descripcion );       

        setOpcion( 'Editar' );
        setReferencia( idPuesto );

        setOpen( true );
    }

    const AgregarRegistro = () => {

        setDescripcion( '' );        

        setOpcion( 'Agregar' );
        setReferencia( 0 );

        setOpen( true );
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores: Errors = {};

        if( descripcion.trim() === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }         
      
        setErrors( errores );
        return valid;
    } 

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setIsSaving( true );

        if( opcion === 'Agregar' ){

            const params: PuestoInterface = {
                idPuesto: 0,              
                descripcion: descripcion.trim(),              
                activo: true,
            };

            await AgregarPuesto( params ).then( resp => {

                if( resp ){

                    setTimeout(() => {
                        
                        setRows([ 
                            ...rows,  
                            {
                                idPuesto: resp.idPuesto,                            
                                descripcion: descripcion,
                                activo: true
                            }
                        ]);  
    
                        setIsSaving( false );
                        handleCloseModal();

                    }, 400);
                        
                }

            });
        }

        if( opcion === 'Editar' ){

            const Puesto = rows.filter( function(row){ return row.idPuesto === referencia; } )[0];

            const params: PuestoInterface = {
                idPuesto: referencia,              
                descripcion: descripcion.trim(),                
                activo: Puesto.activo,
            };
    
            await ActualizarPuesto( params ).then( resp => {
              
                if( resp ){

                    setTimeout(() => {
                        
                        const newRows = rows.map( (row) => { 
                            if( row.idPuesto === referencia){                              
                                row.descripcion = descripcion; 
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });
    
                        setRows( newRows ); 
                        
                        setIsSaving( false );
                        handleCloseModal();

                    }, 400);
                    
                }      

            });
        }
    }

    useEffect(() => {

        async function obtener(){

            setIsLoading( true );
            setRows( [] );

            await Puestos().then( resp => {

                setTimeout(() => {
    
                    setRows( resp );
                    setIsLoading( false );
                    
                }, 400);

            });

        }

        obtener();  

    }, [ ])    

    return (
     
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Sidebar />

            <Header />

            <Layout title={'Puesto'} isCatalog={true} >

                <Grid container spacing={3} >  

                    <Grid md={6} xs={6} sx={{ textAlign: 'left' }}>  
                        <Button 
                            type="button" 
                            variant='plain' 
                            color='neutral' 
                            startDecorator={ <ArrowBackIcon /> } 
                            sx={{ mt: 1, textAlign: 'left' }} 
                            onClick={ () => navigate('/catalogos/') } 
                        > 
                            Regresar
                        </Button> 
                    </Grid>
                    
                    <Grid md={6} xs={6} sx={{ textAlign: 'right' }}> 
                        <Button 
                            type="button" 
                            color='neutral' 
                            startDecorator={ <AddCircleOutlineRoundedIcon /> } 
                            sx={{ mt: 1 }} 
                            onClick={ AgregarRegistro }
                        > 
                            Agregar
                        </Button> 
                    </Grid>
                        
                    <Grid xs={12} md={12}> 
                        
                        <Table  
                            aria-label="striped table" 
                            stripe={'even'} 
                            borderAxis='x'
                            size='md' 
                            sx={{ 
                                width: { xs: '100%', md: '100%' },
                                display:{ xs: 'block', md: 'inline' },
                                overflowX: { xs: 'auto', md: 'hidden' },
                            }}
                        >          

                            <thead>

                                <tr>        
                                    <th style={{ width:'5%' }}> ID </th>        
                                    <th style={{ width:'50%' }}> DESCRIPCIÓN </th>  
                                    <th style={{ width:'5%', textAlign: 'center' }}> HABILITAR </th>
                                    <th style={{ width:'5%', textAlign: 'center' }}> EDITAR </th>
                                </tr>

                            </thead>                            

                            <tbody>        

                                 {

                                    isLoading
                                    ?
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '200px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </td>
                                        </tr>
                                    :
                                        (
                                            rowsPerPage > 0
                                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : rows
                                        ).map( ( row, index ) => (                       

                                            <tr key={ index }>                                         

                                                <td style={{ fontWeight: 'bold' }}>
                                                    { row.idPuesto }
                                                </td>

                                                <td>
                                                    { row.descripcion }
                                                </td>
                                                
                                                <td style={{ textAlign: 'center' }}>
                                                    
                                                    <Switch 
                                                        checked={ row.activo } 
                                                        onChange={ ( e ) => handleChangeSwitch( row.idPuesto, e.target.checked, row ) }
                                                        color={ row.activo ? 'success' : 'neutral'}
                                                        size='lg'
                                                    />
                                                        
                                                </td>

                                                <td style={{ textAlign: 'center' }}>
                                                    <IconButton 
                                                        aria-label="edit" 
                                                        component="span" 
                                                        color='neutral'
                                                        onClick={ () => EditarRegistro( row ) }
                                                    >                                                        
                                                        <EditIcon />
                                                    </IconButton>  
                                                </td>

                                            </tr>     
                                    
                                        ))
                                }

                                {
                                    ( !isLoading && rows.length === 0 )
                                    &&
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '50px' }}>
                                                <Typography fontSize={14} fontWeight={'bold'}>No se encontraron registros</Typography>
                                            </td>
                                        </tr>
                                }                     

                            </tbody>

                        </Table>     

                    </Grid>


                </Grid>

                <Pagination 
                    gotoPage={ setPage }
                    length={ rows.length }
                    pageSize={ rowsPerPage }
                    setPageSize={ setRowsPerPage }       
                    loading={ isLoading }                     
                />
                
            </Layout>

            <Modal             
                disableEscapeKeyDown                
                open={ open }
                onClose={ ( _, r ) =>{ 
                    if( r === 'backdropClick' ) return;                     
                    setOpen( false )
                }}
                sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
            >
                <ModalOverflow>
                
                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    > 

                        <Grid container spacing={3} sx={{ maxHeight: '550px', width: { xs: 'auto', lg: '600px' }, overflow: 'scroll' }}>

                            <Grid md={12} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-descripcion-label">Descripción</FormLabel>
                                
                                    <Textarea
                                        size={'lg'}        
                                        placeholder=''                     
                                        name="descripcion"
                                        variant="outlined"
                                        required
                                        minRows={3}
                                        maxRows={6}
                                        value={ descripcion }
                                        onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                                    >
                                    </Textarea> 

                                </FormControl>

                                {   
                                    errors.descripcion && ( 
                                        <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                            { errors.descripcion } 
                                        </Alert> )
                                }   

                            </Grid>

                        </Grid>                                  
      
                        <DialogActions>

                            <Button color='neutral' onClick={ Guardar } loading={ isSaving }>
                                Guardar 
                            </Button>

                            <Button variant='plain' color='neutral' onClick={ handleCloseModal }>
                                Cancelar
                            </Button>

                        </DialogActions>

                    </ModalDialog>
                    
                </ModalOverflow>

            </Modal>     

        </Box>
       
    )
}