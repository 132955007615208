import { useState } from "react";

import { Modal, ModalOverflow, ModalDialog, ModalClose, DialogTitle, DialogContent, Grid, Table, Typography, Box } from "@mui/joy";

import Pagination from "../components/Pagination";

import { NotificacionProps } from "../interfaces/comun/NotificacionInterface";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    notificaciones: NotificacionProps[];
}

export const ModalNotificaciones = ( { open, setOpen, notificaciones } : Props ) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState( 5 );

    return (
        <Modal             
            disableEscapeKeyDown                
            open={ open }
            onClose={ ( _, r ) =>{ 
                if( r === 'backdropClick' ) return; 
                
                setOpen( false )} 
            }
            sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
        >
            <ModalOverflow>
            
                <ModalDialog
                    aria-labelledby="server-modal-title"
                    aria-describedby="server-modal-description"
                    layout="center"
                    size='lg'
                >
                    <ModalClose />
                    <DialogTitle> Notificaciones </DialogTitle>

                    <DialogContent>      

                        <Grid container >                   

                            <Grid xs={12}>  

                                <Table aria-label="striped table" stripe={'even'} size='md' sx={{ width: '900px' }}>      

                                    <thead>

                                        <tr>
                                            <th style={{ width: '15%' }}>Persona a notificar</th>
                                            <th style={{ width: '20%' }}>Domicilio</th>
                                            <th style={{ width: '13%' }}>Tipo de notificación</th>
                                            <th style={{ width: '10%' }}>Referencia</th>    
                                            <th style={{ width: '10%' }}>Tipo de acuerdo</th>
                                        </tr>

                                    </thead>

                                    <tbody>

                                        {
                                            (
                                                rowsPerPage > 0
                                                ? notificaciones.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                                : notificaciones
                                            )
                                            .map( ( row, index ) => (
                                                <tr key={ index }>
                                                                                                    
                                                    <td>
                                                        <Typography fontSize={14} fontWeight='bold'> { row.personaNotificar } </Typography>
                                                        <Typography fontSize={14}> ({ row.tipoParte }) </Typography>                                         
                                                    </td>
                                                
                                                    <td>{ row.domicilioNotificar }</td>
                                                
                                                    <td>{ row.tipoNotificacion }</td>                                                        

                                                    <td>{ row.referencia }</td>                                                                                                                
                                                    
                                                    <td>{ row.tipoAcuerdo }</td>                                                    

                                                </tr>
                                            ))
                                        }

                                    </tbody>

                                </Table>
                            
                            </Grid>
                        
                        </Grid>                           

                        <Box mt={2}>
                       
                            <Pagination 
                                gotoPage={ setPage }
                                length={ notificaciones.length }
                                pageSize={ rowsPerPage }
                                setPageSize={ setRowsPerPage }     
                                showPerPages={ false }                       
                            />
                            
                        </Box>   

                    </DialogContent>                  

                </ModalDialog>
                
            </ModalOverflow>

        </Modal>
    )
}
