import { useEffect, useRef, useState } from "react";

import { Modal, ModalOverflow, ModalDialog, ModalClose, DialogTitle, Chip, DialogContent, Grid, Typography, DialogActions, Button, CircularProgress } from "@mui/joy";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';

import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

import { Location } from "../interfaces/global/GeolocalizarInterface";
import { NotificacionFullProps } from "../interfaces/comun/NotificacionInterface";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    notificacion: NotificacionFullProps;   
    setNotificacion: React.Dispatch<React.SetStateAction<NotificacionFullProps>>;
    handleSave: ( location: Location ) => void;
    isLoading: boolean;
}

export const ModalEditUbicacion = ( { open, setOpen, notificacion, setNotificacion, handleSave , isLoading} : Props ) => {
    
    const load = useRef<boolean>( true );

    const [location, setLocation] = useState<Location>( { lat: 0, lng: 0 } );
    const [isLoadingMapa, setIsLoadingMapa] = useState<boolean>( true );

    useEffect(() => {

        if( open ){

            if( load.current ){
    
                setIsLoadingMapa( true );
              
                if( notificacion?.lat !== 0 && notificacion?.lng !== 0 ){
                    setLocation( { lat: notificacion?.lat ?? 0, lng: notificacion?.lng ?? 0 } );
    
                    setIsLoadingMapa( false );
                    load.current = false;
                }
    
            }

        }
        
    }, [ open, notificacion ])    

    return (       
        <Modal             
            disableEscapeKeyDown                
            open={ open }
            onClose={ ( _, r ) =>{ 
                if( r === 'backdropClick' ) return;                     
                setOpen( false )
            }}
            sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
        >
            <ModalOverflow>
            
                <ModalDialog
                    aria-labelledby="server-modal-title"
                    aria-describedby="server-modal-description"
                    layout="center"
                    sx={{ width: '800px' }}
                >
                    <ModalClose />
                    <DialogTitle> 
                        Ubicar domicilio 
                        {
                            notificacion.ubicacion
                            ?
                                <Chip size="md" variant="outlined" color="success" endDecorator={ <CheckCircleOutlineIcon /> } > Ubicada </Chip>      
                            :
                                <Chip size="md" variant="outlined" color="danger" endDecorator={ <WrongLocationIcon /> } > No ubicada </Chip>  
                        }
                    </DialogTitle>

                    <DialogContent>      

                        {
                            isLoadingMapa
                            ?
                                <Grid container sx={{ maxHeight: '550px' }}>
                                    <Grid xs={12} sx={{ textAlign: 'center', p: '200px' }}>
                                        <CircularProgress color='neutral' size='md' />
                                    </Grid>
                                </Grid>
                            :
                                <Grid container mt={2}>   
                                    <Grid xs={12}>  
                                        <Typography>
                                            { notificacion?.domicilio ?? '' }
                                        </Typography>                                
                                    </Grid>             

                                    <Grid xs={12} mt={2}>  

                                        <APIProvider apiKey={ process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '' }>

                                            <Map
                                                style={{ height: '500px'}}
                                                defaultCenter={{ lat: notificacion?.lat ?? 0, lng: notificacion?.lng ?? 0}}
                                                defaultZoom={ 18 }
                                                gestureHandling={ 'greedy' }
                                                disableDefaultUI={ true }
                                            >

                                                <Marker                                               
                                                    position={{ lat: notificacion?.lat ?? 0, lng: notificacion?.lng ?? 0 }}
                                                    clickable={ false }
                                                    draggable={ true }
                                                    onDragEnd={ ( e ) => {     
                                                        
                                                        setLocation({
                                                            lat: e.latLng?.lat() ?? 0, 
                                                            lng: e.latLng?.lng() ?? 0,
                                                        });

                                                        setNotificacion({
                                                            ...notificacion,
                                                            lat: e.latLng?.lat(), 
                                                            lng: e.latLng?.lng(),
                                                        });

                                                    }}
                                                />
                                                    
                                            </Map>

                                        </APIProvider>                        
                                    
                                    </Grid>  
                                </Grid>
                        }                 

                    </DialogContent>         

                    <DialogActions>
                        <Button 
                            sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }}                             
                            onClick={ () => handleSave({ lat: location.lat, lng: location.lng }) }
                            loading={isLoading}
                        >
                            Actualizar
                        </Button>
                        <Button color='neutral' variant='plain' disabled={isLoading} onClick={ () => setOpen( false ) }>Cancelar</Button>
                    </DialogActions>         

                </ModalDialog>
                
            </ModalOverflow>

        </Modal>
    )
}
