import { ValidateActuariosZona } from "../interfaces/comun/RutaInterface";

export function groupBy( arr: ValidateActuariosZona[] ): Map<string, ValidateActuariosZona[]> {

    const map = new Map<string, ValidateActuariosZona[]>();

    arr.forEach(obj => {

        const clave = obj.nombreCompleto;

        if ( map.has(clave) ) {
            map.get(clave)!.push(obj);
        } 
        else {
            map.set(clave, [obj]);
        }

    });

    return map;
}