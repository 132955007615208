import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Grid, Button, Table, Switch, IconButton, Typography, CircularProgress, DialogActions, FormControl, FormLabel, Modal, ModalDialog, ModalOverflow, Textarea, Alert, Input, Select, Option } from '@mui/joy';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Sidebar } from '../../components/Sidebar';

import { Header } from '../../components/Header';

import { Layout } from '../../components/Layout';

import Pagination from '../../components/Pagination';

import { ActualizarMunicipio, AgregarMunicipio, Municipios } from '../../connections/catalogos/MunicipioConnection';
import { ObtenerEstados } from '../../connections/catalogos/EstadoConnection';
import { ObtenerZonas } from '../../connections/catalogos/ZonaConnection';

import { MunicipioInterface } from '../../interfaces/catalogos/MunicipioInterface';
import { EstadoInterface } from '../../interfaces/catalogos/EstadoInterface';
import { ZonaInterface } from '../../interfaces/catalogos/ZonaInterface';

interface Errors {
    clave?:         string;
    descripcion?:   string;
    estado?:        string;
    zona?:          string;
}

type OptionType = 'Agregar' | 'Editar' ; 

export const MunicipioPage = ( ) => {

    const navigate = useNavigate();

    const [rows, setRows] = useState<MunicipioInterface[]>( [] );

    const [isLoading, setIsLoading] = useState<boolean>( true );
    const [isSaving, setIsSaving] = useState<boolean>( false );

    const [page, setPage] = useState<number>( 0 );
    const [rowsPerPage, setRowsPerPage] = useState<number>( 10 );

    const [open, setOpen] = useState<boolean>( false );
    
    const [referencia, setReferencia] = useState<number>( 0 );
    const [opcion, setOpcion] = useState<OptionType>( 'Agregar' );
   
    const [clave, setClave] = useState<string>( '' );
    const [descripcion, setDescripcion] = useState<string>( '' );

    const [estado, setEstado] = useState<number>( 0 );
    const [estadoArray, setEstadoArray] = useState<EstadoInterface[]>( [ ] );
    const [zona, setZona] = useState<number>( 0 );
    const [zonaArray, setZonaArray] = useState<ZonaInterface[]>( [ ] );

    const [errors, setErrors] = useState<Errors>( {} );

    const handleCloseModal = () => {
        setOpen( false );
        setErrors( { } );
    }

    const handleChangeSwitch = ( id: number, value: boolean, municipioSelected: MunicipioInterface ) => {
        
        const newRows = rows.map( (row) => { if(row.idMunicipio === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );        
        
        cambiarEstatus( municipioSelected );
    }

    const cambiarEstatus = async ( { idMunicipio, clave, descripcion, idEstado, idZona, activo }: MunicipioInterface ) => {

        const params: MunicipioInterface = {
            idMunicipio,     
            clave,
            descripcion,
            activo,
            idEstado,
            idZona
        };

        await ActualizarMunicipio( params );
    }

    const EditarRegistro = ({ idMunicipio, clave, idEstado, idZona, descripcion } : MunicipioInterface) => {
               
        setClave( clave );       
        setDescripcion( descripcion );       
        setEstado( idEstado );
        setZona( idZona ?? 0 );

        setOpcion( 'Editar' );
        setReferencia( idMunicipio );

        setOpen( true );
    }

    const AgregarRegistro = () => {

        setClave( '' );        
        setDescripcion( '' );        
        setEstado( 0 );        
        setZona( 0 );        

        setOpcion( 'Agregar' );
        setReferencia( 0 );

        setOpen( true );
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores: Errors = {};

        if( clave.trim() === '' ){
            valid = false;
            errores.clave = 'Debes escribir la clave';
        }     

        if( descripcion.trim() === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }  
        
        if( estado === 0 ){
            valid = false;
            errores.estado = 'Seleccione un estado';
        }  
         
        if( estado === 5 && zona === 0 ){
            valid = false;
            errores.zona = 'Seleccione una zona';
        }  
      
        setErrors( errores );
        return valid;
    } 

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setIsSaving( true );

        if( opcion === 'Agregar' ){

            const params: MunicipioInterface = {
                idMunicipio: 0,     
                clave: clave,         
                descripcion: descripcion.trim(),              
                activo: true,
                idEstado: estado,
                idZona: zona,
            };

            await AgregarMunicipio( params ).then( resp => {

                if( resp ){

                    setTimeout(() => {
                        
                        setRows([ 
                            ...rows,  
                            {
                                idMunicipio: resp.idMunicipio,                            
                                clave: clave,
                                descripcion: descripcion,
                                idEstado: estado,
                                estado: resp.estado,
                                idZona: zona,
                                zona: resp.zona,
                                activo: true,
                            }
                        ]);  
    
                        setIsSaving( false );
                        handleCloseModal();

                    }, 400);
                        
                }

            });
        }

        if( opcion === 'Editar' ){

            const Municipio = rows.filter( function(row){ return row.idMunicipio === referencia; } )[0];

            const params: MunicipioInterface = {
                idMunicipio: referencia,              
                clave: clave.trim(),                
                descripcion: descripcion.trim(),                
                activo: Municipio.activo,
                idEstado: estado,
                idZona: zona,
            };
    
            await ActualizarMunicipio( params ).then( resp => {
              
                if( resp ){

                    setTimeout(() => {
                        
                        const newRows = rows.map( (row) => { 
                            if( row.idMunicipio === referencia){                              
                                row.clave = clave; 
                                row.descripcion = descripcion; 
                                row.idEstado = estado; 
                                row.estado = resp.estado; 
                                row.idZona = zona;
                                row.zona = resp.zona;
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });
    
                        setRows( newRows ); 
                        
                        setIsSaving( false );
                        handleCloseModal();

                    }, 400);
                    
                }      

            });
        }
    }

    useEffect(() => {

        async function obtener(){

            setIsLoading( true );
            setRows( [] );

            await Municipios().then( resp => {

                setTimeout(() => {
    
                    setRows( resp );
                    setIsLoading( false );
                    
                }, 400);

            });

        }

        obtener();  

    }, [ ])    

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerEstados().then( resp => {  
                setEstadoArray( resp );
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerZonas().then( resp => {  
                setZonaArray( resp );
            });

        }

        Obtener();

    }, [ ]);

    return (
     
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Sidebar />

            <Header />

            <Layout title={'Municipio'} isCatalog={true} >

                <Grid container spacing={3} >  

                    <Grid md={6} xs={6} sx={{ textAlign: 'left' }}>  
                        <Button 
                            type="button" 
                            variant='plain' 
                            color='neutral' 
                            startDecorator={ <ArrowBackIcon /> } 
                            sx={{ mt: 1, textAlign: 'left' }} 
                            onClick={ () => navigate('/catalogos/') } 
                        > 
                            Regresar
                        </Button> 
                    </Grid>
                    
                    <Grid md={6} xs={6} sx={{ textAlign: 'right' }}> 
                        <Button 
                            type="button" 
                            color='neutral' 
                            startDecorator={ <AddCircleOutlineRoundedIcon /> } 
                            sx={{ mt: 1 }} 
                            disabled
                            onClick={ AgregarRegistro }
                        > 
                            Agregar
                        </Button> 
                    </Grid>
                        
                    <Grid xs={12} md={12}> 
                        
                        <Table  
                            aria-label="striped table" 
                            stripe={'even'} 
                            borderAxis='x'
                            size='md' 
                            sx={{ 
                                width: { xs: '100%', md: '100%' },
                                display:{ xs: 'block', md: 'inline' },
                                overflowX: { xs: 'auto', md: 'hidden' },
                            }}
                        >          

                            <thead>

                                <tr>        
                                    <th style={{ width:'5%' }}> ID </th>        
                                    <th style={{ width:'10%' }}> CLAVE </th>  
                                    <th style={{ width:'30%' }}> DESCRIPCIÓN </th>  
                                    <th style={{ width:'30%' }}> ESTADO </th>  
                                    <th style={{ width:'30%' }}> ZONA </th>  
                                    <th style={{ width:'5%', textAlign: 'center' }}> HABILITAR </th>
                                    <th style={{ width:'5%', textAlign: 'center' }}> EDITAR </th>
                                </tr>

                            </thead>                            

                            <tbody>        

                                 {

                                    isLoading
                                    ?
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '200px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </td>
                                        </tr>
                                    :
                                        (
                                            rowsPerPage > 0
                                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : rows
                                        ).map( ( row, index ) => (                       

                                            <tr key={ index }>                                         

                                                <td style={{ fontWeight: 'bold' }}>
                                                    { row.idMunicipio }
                                                </td>

                                                <td>
                                                    { row.clave }
                                                </td>
                                               
                                                <td>
                                                    { row.descripcion }
                                                </td>
                                               
                                                <td>
                                                    { row.estado?.descripcion }
                                                </td>
                                              
                                                <td>
                                                    { row.zona?.descripcion }
                                                </td>
                                                
                                                <td style={{ textAlign: 'center' }}>
                                                    
                                                    <Switch 
                                                        checked={ row.activo } 
                                                        onChange={ ( e ) => handleChangeSwitch( row.idMunicipio, e.target.checked, row ) }
                                                        color={ row.activo ? 'success' : 'neutral'}
                                                        size='lg'
                                                    />
                                                        
                                                </td>

                                                <td style={{ textAlign: 'center' }}>
                                                    <IconButton 
                                                        aria-label="edit" 
                                                        component="span" 
                                                        color='neutral'
                                                        onClick={ () => EditarRegistro( row ) }
                                                    >                                                        
                                                        <EditIcon />
                                                    </IconButton>  
                                                </td>

                                            </tr>     
                                    
                                        ))
                                }

                                {
                                    ( !isLoading && rows.length === 0 )
                                    &&
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '50px' }}>
                                                <Typography fontSize={14} fontWeight={'bold'}>No se encontraron registros</Typography>
                                            </td>
                                        </tr>
                                }                     

                            </tbody>

                        </Table>     

                    </Grid>


                </Grid>

                <Pagination 
                    gotoPage={ setPage }
                    length={ rows.length }
                    pageSize={ rowsPerPage }
                    setPageSize={ setRowsPerPage }       
                    loading={ isLoading }                     
                />
                
            </Layout>

            <Modal             
                disableEscapeKeyDown                
                open={ open }
                onClose={ ( _, r ) =>{ 
                    if( r === 'backdropClick' ) return;                     
                    setOpen( false )
                }}
                sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
            >
                <ModalOverflow>
                
                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    > 

                        <Grid container spacing={3} sx={{ maxHeight: '550px', width: { xs: 'auto', lg: '600px' }, overflow: 'scroll' }}>

                            <Grid md={12} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-estado-label">Estado</FormLabel>
                                
                                    <Select                      
                                        name="estado"             
                                        size='lg'    
                                        slotProps={{ listbox: { sx: { width: '100%', zIndex: 99999999 } } }}   
                                        value={ estado }                                
                                        onChange={ ( _, value ) => { setEstado( value ?? 0 ); } }            
                                    >                 
                                        <Option key={0} value={0}>Selecciona una opción</Option>
                                        {
                                            estadoArray.map( ( elem ) => (
                                                <Option key={ elem.idEstado } value={ elem.idEstado }> { elem.descripcion } </Option>
                                            ))
                                        }                             
                                    </Select>

                                    {   
                                        errors.estado && ( 
                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                { errors.estado } 
                                            </Alert> )
                                    }   

                                </FormControl>

                            </Grid>

                            {
                                estado === 5
                                &&
                                    <Grid md={12} xs={12} >                            
                                        <FormControl>
                                            <FormLabel sx={{ fontSize: 16 }} id="select-estado-label">Zona</FormLabel>
                                        
                                            <Select                      
                                                name="estado"             
                                                size='lg'    
                                                slotProps={{ listbox: { sx: { width: '100%', zIndex: 99999999 } } }}   
                                                value={ zona }                                
                                                onChange={ ( _, value ) => { setZona( value ?? 0 ); } }            
                                            >                 
                                                <Option key={0} value={0}>Selecciona una opción</Option>
                                                {
                                                    zonaArray.map( ( elem ) => (
                                                        <Option key={ elem.idZona } value={ elem.idZona }> { elem.descripcion } </Option>
                                                    ))
                                                }                             
                                            </Select>

                                            {   
                                                errors.zona && ( 
                                                    <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                        { errors.zona } 
                                                    </Alert> )
                                            }   

                                        </FormControl>

                                    </Grid>
                            }

                            <Grid md={12} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-clave-label">Clave</FormLabel>
                                
                                    <Input
                                        size={'lg'}        
                                        placeholder=''                     
                                        name="clave"
                                        variant="outlined"                                    
                                        autoComplete='off'
                                        value={ clave }
                                        onChange={ ( e ) => { setClave( e.target.value ) } }
                                    />

                                </FormControl>

                                {   
                                    errors.clave && ( 
                                        <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                            { errors.clave } 
                                        </Alert> )
                                }   

                            </Grid>

                            <Grid md={12} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-descripcion-label">Descripción</FormLabel>
                                
                                    <Textarea
                                        size={'lg'}        
                                        placeholder=''                     
                                        name="descripcion"
                                        variant="outlined"
                                        required
                                        minRows={3}
                                        maxRows={6}
                                        value={ descripcion }
                                        onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                                    >
                                    </Textarea> 

                                </FormControl>

                                {   
                                    errors.descripcion && ( 
                                        <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                            { errors.descripcion } 
                                        </Alert> )
                                }   

                            </Grid>

                        </Grid>                                  
      
                        <DialogActions>

                            <Button color='neutral' onClick={ Guardar } loading={ isSaving }>
                                Guardar 
                            </Button>

                            <Button variant='plain' color='neutral' onClick={ handleCloseModal }>
                                Cancelar
                            </Button>

                        </DialogActions>

                    </ModalDialog>
                    
                </ModalOverflow>

            </Modal>     

        </Box>
       
    )
}