import HttpClienteToken from '../../services/HttpClientToken';
import HttpClientFormData from '../../services/HttpClientFormData';

import { HttpResponse } from '../../interfaces/global/HttpInterface';

import { ActualizarUbicacionParams } from '../../interfaces/comun/DomicilioInterface';
import { ActualizarMotivoRechazoParams, ActualizarNotificacionAceptadaParams, ConsultarNotificacionesParams, EliminarAnexoResponse, FinalizarValidacionParams, GuardarFinalizarCambiosNotificacionParams, GuardarFinalizarCambiosNotificacionResponse, NotificacionesResponse, NotificacionFullProps, NotificacionFullResponse, SubirImagenNotificacionResponse } from '../../interfaces/comun/NotificacionInterface';
import { PaginacionInterface } from '../../interfaces/global/PaginacionInterface';

export const ObtenerNotificacionesRecibidas = () => {

    return new Promise<NotificacionFullProps[]>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.get('/Api/Notificacion/ObtenerNotificacionesRecibidas', token)
            .then(({ data }: { data: NotificacionFullProps[] }) => {
                resolve( data );
            })
            .catch(() => {
                resolve( [] );
            });

        }

    });

}

export const GuardarUbicacion = (params : ActualizarUbicacionParams) => {

    return new Promise<HttpResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Notificacion/GuardarUbicacion', params, token)
            .then(({ data }: { data: HttpResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const GuardarNotificacionAceptada = (params : ActualizarNotificacionAceptadaParams) => {

    return new Promise<HttpResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Notificacion/GuardarNotificacionAceptada', params, token)
            .then(({ data }: { data: HttpResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const GuardarTodasLasNotificacion = (params : ActualizarNotificacionAceptadaParams[]) => {

    return new Promise<HttpResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Notificacion/GuardarTodasLasNotificacion', params, token)
            .then(({ data }: { data: HttpResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const GuardarMotivoRechazo = (params : ActualizarMotivoRechazoParams) => {

    return new Promise<HttpResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Notificacion/GuardarMotivoRechazo', params, token)
            .then(({ data }: { data: HttpResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const FinalizarValidacionNotificacion = ( params : FinalizarValidacionParams[] ) => {

    return new Promise<HttpResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Notificacion/FinalizarValidacion',params,  token)
            .then(({ data }: { data: HttpResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const ObtenerNotificacion = ( id: string) => {

    return new Promise<NotificacionFullResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.get(`/Api/Notificacion/ObtenerNotificacion/${ id }`, token)
            .then(({ data }: { data: NotificacionFullResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve( {
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador",
                } );
            });

        }

    });

}

export const SubirFotoViviendaNotificacion = ( params : FormData ) => {

    return new Promise<SubirImagenNotificacionResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClientFormData.post('/Api/Notificacion/SubirFotoViviendaNotificacion',params,  token)
            .then(({ data }: { data: SubirImagenNotificacionResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const SubirFotoCedulaNotificacion = ( params : FormData ) => {

    return new Promise<SubirImagenNotificacionResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClientFormData.post('/Api/Notificacion/SubirFotoCedulaNotificacion',params,  token)
            .then(({ data }: { data: SubirImagenNotificacionResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const SubirFotoAnexoNotificacion = ( params : FormData ) => {

    return new Promise<SubirImagenNotificacionResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClientFormData.post('/Api/Notificacion/SubirFotoAnexoNotificacion',params,  token)
            .then(({ data }: { data: SubirImagenNotificacionResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const EliminarAnexo = ( params : { id: string} ) => {

    return new Promise<EliminarAnexoResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Notificacion/EliminarAnexo',params,  token)
            .then(({ data }: { data: EliminarAnexoResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const GuardarCambiosNotificacion = ( params : GuardarFinalizarCambiosNotificacionParams ) => {

    return new Promise<HttpResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Notificacion/GuardarCambios',params,  token)
            .then(({ data }: { data: HttpResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const FinalizarRazonamientoNotificacion = ( params : GuardarFinalizarCambiosNotificacionParams ) => {

    return new Promise<GuardarFinalizarCambiosNotificacionResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Notificacion/FinalizarRazonamiento',params,  token)
            .then(({ data }: { data: GuardarFinalizarCambiosNotificacionResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
            });

        }

    });

}

export const Notificaciones = ( params: PaginacionInterface ) => {

    return new Promise<NotificacionesResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Notificacion/Notificaciones',params,  token)
            .then(({ data }: { data: NotificacionesResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    pageCount: 0,
                    currentPageIndex: 0,
                    recordsCount: 0,
                    records: [],
                });
            });

        }

    });

}

export const ConsultarNotificaciones = ( params: ConsultarNotificacionesParams ) => {

    return new Promise<NotificacionesResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Notificacion/ConsultarNotificaciones',params,  token)
            .then(({ data }: { data: NotificacionesResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve({
                    pageCount: 0,
                    currentPageIndex: 0,
                    recordsCount: 0,
                    records: [],
                });
            });

        }

    });

}
