import { useEffect, useState } from 'react';

import { Alert, Box, Grid, Button, Table, Switch, IconButton, Typography, Chip, Divider, DialogActions, DialogTitle, Modal, ModalDialog, FormControl, FormLabel, Input, FormHelperText, List, Radio, RadioGroup, CircularProgress, ListItem, Snackbar } from '@mui/joy';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CheckIcon from '@mui/icons-material/Check';

import moment from 'moment';

import { Sidebar } from '../../components/Sidebar';

import { Header } from '../../components/Header';

import { Layout } from '../../components/Layout';

import Pagination from '../../components/Pagination';

import { ActualizarApiKey, ActualizarApiKeyEstatus, AgregarApiKey, ObtenerApiKeys } from '../../connections/admin/ApiKeysConnection';

import { ApiKeyDominioProps, ApiKeyProps } from '../../interfaces/admin/ApiKeysInterface';

type OptionType = 'Agregar' | 'Editar' ; 
type ApiKeyType = 'Sitios Web' | 'Apps' ; 

interface Errors {
    descripcion?:   string;
    dominio?:       string;
}

export const ApiKeyPage = ( ) => {

    const [opcion, setOpcion] = useState<OptionType>( 'Agregar' );
    const [rows, setRows] = useState<ApiKeyProps[]>( [] );

    const [isLoading, setIsLoading] = useState<boolean>( true );
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>( false );

    const [open, setOpen] = useState<boolean>( false );
    const [openMessage, setOpenMessage] = useState<boolean>( false );
    
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>( 10 );

    const [descripcion, setDescripcion] = useState<string>( '' );
    const [dominio, setDominio] = useState<string>( '' );
    const [dominios, setDominios] = useState<ApiKeyDominioProps[]>( [] );

    const [referencia, setReferencia] = useState<string>( '' );

    const [apiKeyType, setApiKeyType] = useState<ApiKeyType>( 'Sitios Web' );
    
    const [errors, setErrors] = useState<Errors>( {} );

    const handleClose = () => {

        setReferencia( '' );
        setDescripcion( '' );
        setDominio( '' );
        setDominios( [] );
        setErrors( {} );

        setApiKeyType( 'Sitios Web' );
        setOpcion( 'Agregar' );
        setOpen( false );
    }

    const handleEdit = ( value: ApiKeyProps ) => {

        setDescripcion( value?.descripcion ?? '' );
        setDominios( value?.dominios ?? [] );
        setReferencia( value?.id ?? '' );

        if( value.forApps ){
            setApiKeyType( 'Apps' );
        }

        setOpcion( 'Editar' );
        setOpen( true );
    }

    const handleAgregarDominio = () => {

        setErrors( {} );

        const validateDominio =  dominios.find( ( element ) => { return element.dominio === dominio });           

        if( dominio.includes('http') || dominio.includes('https') || dominio.includes(':') || dominio.includes('/') ){
            let errores: Errors = {};
    
            errores.dominio = 'El dominio no debe incluir http o https y ningun caracter como : /';
        
            setErrors( errores );  
        }
        else if( !validateDominio && dominio !== '' ){

            const id = new Date().getUTCSeconds() + '' + new Date().getUTCMilliseconds();

            setDominios([
                ...dominios,
                {
                    id,
                    dominio,
                    activo: true,
                }
            ]);

            setDominio( '' );
        }
        else{
            let errores: Errors = {};
    
            errores.dominio = dominio === '' ? 'Escribe un dominio' : 'Ya se tiene agregado un dominio con la misma descripción';
        
            setErrors( errores );  
        }

    }

    const handleDeleteDomain = ( id: string ) => {

        const newDomains = dominios.filter( ( elem ) => elem.id !== id );
        setDominios( newDomains );
    }

    const handleChangeApiKeyActiva = async ( value: boolean, id: string ) => {

        await ActualizarApiKeyEstatus({ id, activar: value }).then( resp => {

            if( resp.success ){
                           
                const newArray = rows.map( ( elem ) => {
                    if( elem.id === id ){
                        elem.activo = value;
                    }
                    return elem;
                });
        
                setRows( newArray );           
                
            }

        })

    }

    const handleGuardarApiKey = async () => {

        if( descripcion === '' ){
            setErrors({ descripcion: 'Debes escribir el nombre' }); 
            return false;
        }
        else if( apiKeyType === 'Sitios Web' && dominios.length === 0 ){
            setErrors({ dominio: 'Agrega un dominio por lo menos' }); 
            return false;
        }

        setIsLoadingSave( true );

        if( opcion === 'Editar' ){

            await ActualizarApiKey({ id: referencia, descripcion, dominios, forApps: apiKeyType === 'Apps' }).then( resp => {

                if( resp.success ){          
                    
                    const { success, descripcion, dominios, forApps } = resp;

                    if( success ){

                        setTimeout(() => {

                            setRows(rows.map( ( elem ) => {
                                if( elem.id === referencia){
                                    elem.descripcion = descripcion;
                                    elem.dominios = dominios;
                                    elem.forApps = forApps;
                                }
                                return elem;
                            }));

                            handleClose();

                            setIsLoadingSave( false );
                            
                        }, 700);

                    }
            
                }
                
    
            })    

        }
        
        if( opcion === 'Agregar' ){

            await AgregarApiKey({ descripcion, dominios: apiKeyType === 'Sitios Web' ? dominios : [], forApps: apiKeyType === 'Apps' }).then( resp => {              

                if( resp.success ){          
                    
                    const { success, descripcion, fechaCreacion, apiKey, dominios, forApps, activo } = resp;

                    if( success ){

                        setTimeout(() => {

                            setRows([
                                ...rows,
                                {
                                    descripcion: descripcion,
                                    fechaCreacion: fechaCreacion,
                                    apiKey: apiKey,
                                    dominios: dominios,
                                    forApps: forApps,
                                    activo: activo,
                                }
                            ]);

                            handleClose();
                            
                            setIsLoadingSave( false );
                            
                        }, 700);

                    }
            
                }                
    
            })    

        }

    }

    useEffect(() => {
    
        async function obtener(){

            setIsLoading( true );
            setRows( [] );

            await ObtenerApiKeys().then( resp => {

                setTimeout(() => {
    
                    setRows( resp );
                    setIsLoading( false );
                    
                }, 400);

            });

        }

        obtener();  

    }, [ ])     

    return (
     
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Snackbar
                key={ 'snackbar-login' }
                autoHideDuration={ 2000 }
                color={ 'success' }
                size="md"
                variant="solid"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={ openMessage }
                onClose={ () => setOpenMessage( false ) }
                startDecorator={ <CheckIcon /> }
            >
                Se copió la Api Key en el portapapeles
            </Snackbar>

            <Sidebar />

            <Header />

            <Layout title={ 'Api Keys' }>

                <Grid container spacing={3} > 
                 
                    <Grid md={12} xs={12} sx={{ textAlign: 'right' }}> 
                        <Button type="button" color='neutral' startDecorator={ <AddCircleOutlineRoundedIcon /> } sx={{ mt: 1 }} onClick={ () => { setOpcion( 'Agregar'); setOpen( true ); } } > 
                            Generar Api Key
                        </Button> 
                    </Grid>
                        
                    <Grid xs={12} md={12}> 
                        
                        <Table  
                            aria-label="striped table" 
                            stripe={'even'} 
                            borderAxis='x'
                            sx={{ 
                                width: { xs: '100%', md: '100%' },
                                display:{ xs: 'block', md: 'inline' },
                                overflowX: { xs: 'auto', md: 'hidden' },
                            }}
                        >          

                            <thead>

                                <tr>        
                                    <th style={{ width:'10%' }}> DESCRIPCIÓN </th>                                   
                                    <th style={{ width:'10%' }}> FECHA DE CREACIÓN	 </th>                                   
                                    <th style={{ width:'15%' }}> API KEY </th>                          
                                    <th style={{ width:'10%' }}> DOMAIN(S) </th>     
                                    <th style={{ width:'5%', textAlign: 'center' }}> FOR APPS </th>                                    
                                    <th style={{ width:'2%', textAlign: 'center' }}> HABILITAR </th>
                                    <th style={{ width:'2%', textAlign: 'center' }}> EDITAR </th>
                                </tr>

                            </thead>                            

                            <tbody> 
                                
                                {       

                                    isLoading
                                    ?
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '200px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </td>
                                        </tr>
                                    :                                 
                                        (
                                            rowsPerPage > 0
                                            ? rows.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                            : rows
                                        )
                                        .map( ( row, index ) => (                       

                                            <tr key={ index }>   

                                                <td>
                                                    { row.descripcion }
                                                </td>

                                                <td>
                                                    { row.fechaCreacion ? moment( row.fechaCreacion ).format( 'DD-MM-YYYY h:mm a' ) : '' }
                                                </td>

                                                <td>     
                                                    <Grid container spacing={1}>                                                       
                                                        <Grid>
                                                            <IconButton 
                                                                size='sm'
                                                                onClick={ () => {
                                                                    navigator.clipboard.writeText( row.apiKey ?? '' );
                                                                    setOpenMessage( true );
                                                                }}      
                                                            >
                                                                <ContentPasteIcon />
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid>
                                                            <Typography level='body-md'>{ row.apiKey }</Typography>                                                                                                                 
                                                        </Grid>
                                                    </Grid>                                               
                                                </td>

                                                <td>                                                  
                                                    {
                                                        row.dominios?.map( ( item, i ) => ( 
                                                            <Typography level='title-sm' sx={{ my: 1 }}>{ ( i + 1 ) + '.-' + item.dominio }</Typography> 
                                                        ))
                                                    }                                                    
                                                </td>

                                                <td style={{ textAlign: 'center' }}>
                                                    {
                                                        row.forApps
                                                        &&
                                                            <Chip variant='solid' color='neutral' size='md'>Si</Chip>
                                                    }
                                                </td>

                                                <td style={{ textAlign: 'center' }}>
                                                    
                                                    <Switch 
                                                        checked={ row.activo } 
                                                        onChange={ (e) => handleChangeApiKeyActiva( e.target.checked, row.id ?? '' ) }
                                                        color={ row.activo ? 'success' : 'neutral'}
                                                        size='lg'
                                                    />
                                                        
                                                </td>

                                                <td style={{ textAlign: 'center' }}>
                                                    <IconButton 
                                                        aria-label="edit" 
                                                        component="span" 
                                                        color='neutral'
                                                        onClick={ () => handleEdit( row ) }
                                                    >                                                        
                                                        <EditIcon />
                                                    </IconButton>  
                                                </td>

                                            </tr>     
                                    
                                        ))
                                }

                                {
                                    ( !isLoading && rows.length === 0 )
                                    &&
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '50px' }}>
                                                <Typography fontSize={14} fontWeight={'bold'}>No se encontraron registros</Typography>
                                            </td>
                                        </tr>
                                }                     

                            </tbody>

                        </Table>     

                    </Grid>

                </Grid>
               
                <Grid container>

                    <Grid xs={12} md={6} >
                        <Typography>Total de registros: <strong>{ rows.length }</strong></Typography>
                    </Grid>

                    <Grid xs={12} md={6} sx={{ mt: { xs: 2, md: 0 } }} >

                        <Pagination 
                            gotoPage={ setPage }
                            length={ rows.length }
                            pageSize={ rowsPerPage }
                            setPageSize={ setRowsPerPage }                            
                        />

                    </Grid>   

                </Grid>   
                
            </Layout>

            <Modal 
                disableEscapeKeyDown
                open={ open }              
                onClose={ ( _, r ) => { 
                    if( r === 'backdropClick' ) return;                     
                    setOpen( false )
                }}
                sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}               
            >

                <ModalDialog variant="outlined" role="alertdialog" sx={{ width: 500 }}>

                    <DialogTitle>
                        {
                            opcion === 'Agregar'
                            ?
                                'Agregar Api Key'
                            :
                                'Modificar Api Key'
                        }                        
                    </DialogTitle>

                    <Divider />              

                    <Grid container spacing={3} mt={1} >

                        <Grid md={12} xs={12}>

                            <FormControl>      
                                <FormLabel sx={{ fontSize: 16 }}>La api key es para uso en:</FormLabel>                                          
                                <RadioGroup defaultValue="Si" onChange={ ( ) => {} } >
                                    <List component='div' orientation='horizontal' >
                                        <Radio color='neutral' value="sitios web" label="Sitios Web" variant="outlined" size='md' sx={{ mr: 1 }} checked={ apiKeyType === 'Sitios Web' } onChange={ () => setApiKeyType( 'Sitios Web' ) } />                                                            
                                        <Radio color='neutral' value="aplicaciones para Android o iOS" label="Aplicaciones para Android o iOS" variant="outlined" size='md' sx={{ ml: 1 }} checked={ apiKeyType === 'Apps' } onChange={ () => setApiKeyType( 'Apps' ) } />
                                    </List>
                                </RadioGroup>
                            </FormControl>        

                        </Grid>

                        <Grid xs={12}>

                            <FormControl>
                                <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                    Descripción
                                </FormLabel>
                                <Input
                                    size='lg'
                                    placeholder=""  
                                    autoComplete='off'
                                    value={ descripcion }
                                    onChange={ (e) => setDescripcion( e.target.value ) }
                                />        
                            </FormControl>            
                            {   
                                errors.descripcion && (                                     
                                    <Alert color='warning' variant="soft" sx={{ mt: 1 }} > { errors.descripcion }  </Alert> 
                                )
                            }     
                        </Grid>

                        {
                            apiKeyType === 'Sitios Web'
                            &&
                            <>
                                <Grid xs={12}>
        
                                    <FormControl>
                                        <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                            Agregar dominio
                                        </FormLabel>
                                        <Input
                                            sx={{ '--Input-decoratorChildHeight': '45px' }}
                                            placeholder=""         
                                            endDecorator={
                                                <IconButton
                                                    variant="solid"
                                                    color="neutral"                   
                                                    sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                    onClick={ handleAgregarDominio }
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            }
                                            autoComplete='off'
                                            value={ dominio }
                                            onChange={ (e) => setDominio( e.target.value ) }
                                        />        
                                        <FormHelperText>Permite a los sitios web especificados hacer solicitudes mediante el uso de la clave .</FormHelperText>
                                    </FormControl>
        
                                </Grid>
        
                                <Grid xs={12}>
        
                                    <Typography level='h4'>Dominio(s) autorizado(s)</Typography>
        
                                    <List>

                                        {
                                            dominios.map( ( elem, index ) => (

                                                <ListItem 
                                                    key={ index }
                                                    endAction={
                                                        <IconButton onClick={ () => handleDeleteDomain( elem.id ) }>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    }
                                                >                                        
                                                    <Typography level='title-md'>{ ( index + 1 ) + '.- ' + elem.dominio } </Typography>
                                                </ListItem>

                                            ))
                                        }

                                    </List>
        
                                </Grid>
        
                                {   
                                    errors.dominio && ( 
                                        <Grid xs={12} >
                                            <Alert color='warning' variant="soft" > { errors.dominio }  </Alert> 
                                        </Grid>
                                    )
                                }                            
                            </>
                        }

                    </Grid>

                    <DialogActions>

                        <Button sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }} onClick={ handleGuardarApiKey } loading={ isLoadingSave } >
                            Guardar
                        </Button>

                        <Button variant="plain" color="neutral" onClick={ handleClose } >
                            Cancelar
                        </Button>

                    </DialogActions>

                </ModalDialog>

            </Modal>

        </Box>
       
    )
}