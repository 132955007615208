import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Box, Button, Checkbox, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, Modal, ModalClose, ModalDialog, ModalOverflow, Snackbar, Table, Typography } from "@mui/joy"

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from "@mui/icons-material/Warning";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import { Layout } from "../components/Layout"
import { Sidebar } from "../components/Sidebar"
import { Header } from "../components/Header"

import moment from "moment";

import Pagination from '../components/Pagination';

import { ModalNotificaciones } from '../modals/ModalNotificaciones';

import { setRecepcion } from "../store/slices/recepcionSlice";

import { FinalizarRecepcion, ObtenerListadosEnvios } from '../connections/comun/ListadoEnvioConnection';

import { SnackbarProps } from '../interfaces/ui/Snackbar';
import { ListadoEnvioProps } from '../interfaces/comun/ListadoEnvioInterface';
import { NotificacionProps } from '../interfaces/comun/NotificacionInterface';

export const RecepcionPage = () => {

    const dispatch = useDispatch();

    const [open, setOpen] = useState<boolean>( false );
    const [openConfirmacion, setOpenConfirmacion] = useState<boolean>( false );
    const [openAcuse, setOpenAcuse] = useState<boolean>( false );
   
    const [acuse, setAcuse] = useState<string>("");
   
    const [isLoading, setIsLoading] = useState<boolean>( false );
    const [isLoadingFinalizar, setIsLoadingFinalizar] = useState<boolean>( false );
    const [reload, setReload] = useState<boolean>( true );

    const [checkedAll, setCheckedAll] = useState<boolean>( false );

    const [rows, setRows] = useState<ListadoEnvioProps[]>( [] );
    const [notificaciones, setNotificaciones] = useState<NotificacionProps[]>( [] );

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>( 10 );

    const [{ type: typeSnackbar, open: openMessage, message }, setOpenMessage] = useState<SnackbarProps>({
        type: 'warning',
        message: '',
        open: false,
    });

    const handleCloseAlert = () => setOpenMessage({ type: typeSnackbar, open: false, message })
   
    const handleChangeSeleccionar = (id: string, selected: boolean ) => {

        const newRows = rows.map( ( elem ) => {
            if( elem.id === id ){
                elem.selected = selected;
            }            
            return elem;
        });

        setRows( newRows );
    }

    const handleChangeAll = ( value: boolean ) => {

        setCheckedAll( value );

        const newRows = rows.map( ( elem ) =>{ elem.selected = value; return elem; } );

        setRows( newRows );

    }

    const handleFinalizar = async() => {
        
        setIsLoadingFinalizar( true );

        const params = rows.filter((e) => e.selected === true );
        
        if( params.length === 0 ) return;
            
        await FinalizarRecepcion( params ).then( resp => {

            if( resp.success ) {

                setTimeout(() => {

                    const errorMessage: string = resp.message ?? '';
                    setOpenMessage({ type: 'success', open: true, message: errorMessage });
                    
                    setIsLoadingFinalizar( false ) ;

                    setAcuse( resp.acuse?? '' );
                    setCheckedAll( false );

                    setOpenAcuse( true );

                    setOpenConfirmacion( false );
                    setReload( true );

                }, 400);
                
            }
            else {

                setTimeout(() => {

                    const errorMessage: string = resp.message ?? '';

                    setOpenMessage({ type: 'danger', open: true, message: errorMessage });

                    setOpenConfirmacion( false );
                    setIsLoadingFinalizar( false );

                }, 400);

            }

        });
    }

    useEffect(() => {
    
        async function obtener(){

            setIsLoading( true ); 

            await ObtenerListadosEnvios().then( resp =>  {

                setTimeout(() => {
                    
                    setRows( resp.map((e) => { e.selected = false; return e; }) );
                    setIsLoading( false ); 
                    
                }, 400);

            });

        }
       
        obtener();          

    }, [ ])   

    useEffect(() => {
    
        async function obtener(){
            
            setIsLoading( true ); 

            await ObtenerListadosEnvios().then( resp =>  {

                setTimeout(() => {
                    
                    setRows( resp.map((e) => { e.selected = false; return e; }) );
                    setIsLoading( false ); 
                    setReload( false );
                    
                }, 400);


            });

        }

        if( reload ){
            obtener();  
        }

    }, [ reload ])   

    useEffect(() => {
    
        dispatch( setRecepcion( { numeroNotificaciones: rows.length } ) );

    }, [ dispatch, rows ])   

    return (
        
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

        <Snackbar
                key={'snackbar-login'}
                autoHideDuration={ 2000 }
                color={ typeSnackbar }
                size="md"
                variant="solid"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={ openMessage }
                onClose={ handleCloseAlert }
                startDecorator={ typeSnackbar === 'success' ? <CheckIcon /> : <WarningIcon /> }
            >
                { message }
            </Snackbar>

            <Sidebar />
            
            <Header />       
       
            <Layout title='Nueva recepción'>

                <Grid container >                   

                    <Grid xs={12}>                     

                        <Table 
                            aria-label="striped table" 
                            stripe={'even'} 
                            borderAxis='x'
                            size='lg' 
                            sx={{ 
                                width: { xs: '100%', md: '100%' },
                                display:{ xs: 'block', md: 'inline' },
                                overflowX: { xs: 'auto', md: 'hidden' },
                            }}
                        >      

                            <thead style={{ width: '100%' }}>

                                <tr style={{ width: '100%' }}>
                                    <th style={{ width: '10%' }}>Número de Listado <br /> de envió</th>
                                    <th style={{ width: '55%' }}>Juzgado</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Número de <br /> Notificaciones</th>
                                    <th style={{ width: '20%' }}>Persona que envia <br /> (Actuario)</th>
                                    <th style={{ width: '5%', textAlign: 'center' }}>
                                        Seleccionar 
                                        <Checkbox checked={ checkedAll } color='neutral' sx={{ ml: 1 }} onChange={ (e) => handleChangeAll( e.target.checked ) } />
                                    </th>
                                </tr>

                            </thead>

                            <tbody>

                                {

                                    isLoading
                                    ?
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '250px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </td>
                                        </tr>
                                    :   
                                        (
                                            rowsPerPage > 0
                                            ? rows.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                                            : rows
                                        )
                                        .map( ( row ) => (
                                            <tr key={ row.id }>     
                                            
                                                <td>
                                                    <Grid container>

                                                        <Grid xs={6}> 
                                                            <Typography fontSize={18} fontWeight={'bold'}>
                                                                { 'LAC-' + row.idListadoEnvio }
                                                            </Typography>
                                                        </Grid>

                                                        <Grid xs={6}> 
                                                            <Typography fontSize={14}>
                                                                (GLEN-{ row.numeroSeguimiento })
                                                            </Typography>
                                                        </Grid>

                                                    </Grid>                                                   
                                                   
                                                    <Typography fontSize={14} mt={1}>
                                                        { moment( row.fecha ).format('DD-MM-YYYY h:mm a') }
                                                    </Typography>
                                                </td>
                                            
                                                <td>{ row.juzgado }</td>      
                                            
                                                <td style={{ textAlign: 'center' }}>
                                                    <Button variant="solid" color="neutral" size='sm' endDecorator={ <FormatListBulletedIcon /> } onClick={ () => {setNotificaciones(row.notificaciones); setOpen( true );} }>
                                                        { row.numeroNotificaciones }
                                                    </Button>                                                
                                                </td>
                                            
                                                <td>{ row.actuario }</td>
                                                
                                                <td>
                                                    <Grid container spacing={3} textAlign='center'>
                                                        <Grid xs={12}>
                                                            <Checkbox 
                                                                sx={{ mt: '6px' }} 
                                                                color='neutral'
                                                                checked={ row.selected } 
                                                                onChange={ ( e ) => handleChangeSeleccionar( row.id, e.target.checked ) }  
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </td>

                                            </tr>
                                        ))
                                }

                                {
                                    ( !isLoading && rows.length === 0 )
                                    &&
                                        <tr>
                                            <td colSpan={6} style={{ textAlign: 'center', padding: '50px' }}>
                                                <Typography fontSize={14} fontWeight={'bold'}>No se encontraron registros</Typography>
                                            </td>
                                        </tr>
                                }

                            </tbody>                          

                        </Table>     
                       
                    </Grid>

                </Grid>                
                
                <Pagination 
                    gotoPage={ setPage }
                    length={ rows.length }
                    pageSize={ rowsPerPage }
                    setPageSize={ setRowsPerPage }                            
                />                           

                <Grid container textAlign='end'> 

                    <Grid xs={12} mt={5} >

                        <Button 
                            size='lg'
                            sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }} 
                            startDecorator={ <DoneOutlineIcon /> }
                            disabled={ rows.filter( (e) => e.selected === true ).length === 0 }
                            onClick={ ( ) => setOpenConfirmacion( true ) }
                        >
                            Finalizar recepción
                        </Button>

                    </Grid>

                </Grid> 
    
            </Layout>    
          
            <ModalNotificaciones 
                open={ open }
                setOpen={ setOpen}
                notificaciones={ notificaciones }
            />

            <Modal 
                open={ openConfirmacion }              
                onClose={ ( _, r ) => { 
                    if( r === 'backdropClick' ) return;                     
                    setOpenConfirmacion( false )
                }}
                sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}               
            >

                <ModalDialog variant="outlined" role="alertdialog" sx={{ width: 400 }}>

                    <DialogTitle>
                        <WarningRoundedIcon />
                        Confirmación
                    </DialogTitle>

                    <Divider />

                    <DialogContent>
                        ¿Desea finalizar la recepción?
                    </DialogContent>

                    <DialogActions>

                        <Button sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }} loading = { isLoadingFinalizar } onClick={ handleFinalizar } >
                            Aceptar
                        </Button>

                        <Button variant="plain" color="neutral" disabled={ isLoadingFinalizar } onClick={ () => setOpenConfirmacion( false ) } >
                            Cancelar
                        </Button>

                    </DialogActions>

                </ModalDialog>

            </Modal>

            <Modal             
                disableEscapeKeyDown                
                open={ openAcuse }
                onClose={ ( _, r ) =>{ 
                    if( r === 'backdropClick' ) return;                     
                    setOpenAcuse( false )
                }}
                sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
            >
                <ModalOverflow>
                
                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    >
                        <ModalClose />
                        <DialogTitle> Acuse de recepción </DialogTitle>

                        <DialogContent>      

                            <Grid container mt={2} sx={{ width: '800px' }}>                   

                                <Grid xs={12}>  

                                    <iframe title='acuse' src={acuse} height="500px" width="100%"></iframe>
                                
                                </Grid>
                            
                            </Grid>                      

                        </DialogContent>         

                    </ModalDialog>
                    
                </ModalOverflow>

            </Modal>

        </Box>

    )
}
