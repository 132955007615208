import { useState, useEffect, memo } from "react";

import { Button, Grid, Option, Select, Typography } from "@mui/joy";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";

import { usePages } from "../hooks/UsePages";
import { NumberWithCommas } from "../helpers/FormatNumber";

const Pagination = ({ gotoPage, length, pageSize, setPageSize, showPerPages = true, loading = false, }: { gotoPage: (updater: number | ((pageIndex: number) => number)) => void; length: number; pageSize: number; setPageSize: (pageSize: number) => void; showPerPages?: boolean, loading?: boolean }) => {
    
    const [perPage, setPerPage] = useState(pageSize);

    const { canGo, currentPage, pages, goTo, goNext, goPrev } = usePages({ gotoPage, length, pageSize, });

    const handleChange = ( event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element, Element> | null, value: number | null ) => {
        if( value ){
            setPerPage( value );
            gotoPage( 0 );
        }
    };

    useEffect(() => {
      
        setPageSize(perPage);

    }, [ perPage, setPageSize ]);

    return (
        <Grid container sx={{ display: length === 0 ? 'none': 'flex' }} >

            <Grid xs={12} md={5}>
                <Typography>Total de registros: <strong>{ NumberWithCommas( length ) }</strong></Typography>
            </Grid>

            <Grid xs={3} md={2} textAlign='center' mt={{ xs: 2, md: 0 }}>

                <Button
                    size="sm"
                    onClick={ goPrev }
                    disabled={ !canGo.previous || loading }                    
                    style={{
                        color: canGo.previous ? 'white' : '#004360',
                        backgroundColor: canGo.previous ? '#004360' : ''
                    }}
                >
                    <ChevronLeftOutlined />
                </Button>

            </Grid>

            <Grid xs={4} md={2} textAlign='center' mt={{ xs: 2, md: 0 }}>

                {
                    pages.map( ( page, i ) => (
                        <Button
                            size="sm"
                            onClick={ () => goTo(page) }
                            key={i}
                            variant={ currentPage === page ? "solid" : "outlined" }
                            disabled={ loading }       
                            style={{
                                marginLeft: i !== 0 ? '5px' : '0px',
                                color: currentPage === page ? 'white' : '#004360',
                                backgroundColor: currentPage === page ? '#004360' : '',
                                border: '1px solid #004360'
                            }}
                        >
                            { page }
                        </Button>
                    ))
                }
            
            </Grid>

            <Grid xs={3} md={2} textAlign='center' mt={{ xs: 2, md: 0 }}>

                <Button
                    size="sm"
                    onClick={ goNext }
                    disabled={ !canGo.next || loading }   
                    style={{
                        color: canGo.next ? 'white' : '#004360',
                        backgroundColor: canGo.next ? '#004360' : ''
                    }}             
                >
                    <ChevronRightOutlined />
                </Button>

            </Grid>

            {
                showPerPages
                &&
                    <Grid xs={2} md={1} mt={{ xs: 2, md: 0 }}>

                        <Select   
                            size="sm"
                            defaultValue={ pageSize }
                            onChange={ handleChange }      
                            disabled={ loading }            
                        >
                            {
                                [5, 10, 25, 50, 100]
                                .map( ( pgSize ) => (
                                    <Option value={ pgSize } key={ pgSize } >
                                        { pgSize }
                                    </Option  >
                                ))
                            }

                        </Select>

                    </Grid>
            }

        </Grid>
    );
};

export default memo(Pagination);
