import HttpClienteToken from '../../services/HttpClientToken';

import { PaisInterface } from '../../interfaces/catalogos/PaisInterface';

export const ObtenerPaises = () => {

    return new Promise<PaisInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Pais/ObtenerPaises', token)
            .then( ({ data } : { data : PaisInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const Paises = () => {

    return new Promise<PaisInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Pais/', token)
            .then( ({ data } : { data : PaisInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarPais = ( params: PaisInterface ) => {

    return new Promise<PaisInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Pais/Actualizar', params, token)
            .then( ({ data } : { data : PaisInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idPais: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarPais = ( params: PaisInterface ) => {

    return new Promise<PaisInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Pais/Agregar', params, token)
            .then( ({ data } : { data : PaisInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idPais: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}