
import { Sheet, GlobalStyles, IconButton } from "@mui/joy";

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

function openSidebar() {

    if (typeof window !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }

}
  
function closeSidebar() {
    if (typeof window !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
}
  
function toggleSidebar() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
          
        const slideIn = window.getComputedStyle(document.documentElement).getPropertyValue('--SideNavigation-slideIn');

        if (slideIn) {
          closeSidebar();
        } 
        else {
          openSidebar();
        }
    }
}
  
export const Header = () => {
    return (
        <Sheet
            sx={{
                display: { xs: 'flex', md: 'none' },
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: 'var(--Header-height)',
                zIndex: 9998,
                p: 2,
                gap: 1,
                borderBottom: '1px solid',
                borderColor: 'background.level1',
                boxShadow: 'sm',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                      '--Header-height': '52px',
                      [theme.breakpoints.up('md')]: {
                        '--Header-height': '0px',
                      },
                    },
                })}
            />

            <IconButton
                onClick={ () => toggleSidebar() }
                variant="outlined"
                color="neutral"
                size="sm"
            >
                <MenuRoundedIcon />
            </IconButton>

        </Sheet>
    );
}
