import HttpClienteToken from '../../services/HttpClientToken';

import { SexoInterface } from '../../interfaces/catalogos/SexoInterface';

export const Sexo = () => {

    return new Promise<SexoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Sexo/', token)
            .then( ({ data } : { data : SexoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ObtenerSexos = () => {

    return new Promise<SexoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Sexo/ObtenerSexos', token)
            .then( ({ data } : { data : SexoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarSexo = ( params: SexoInterface ) => {

    return new Promise<SexoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Sexo/Actualizar', params, token)
            .then( ({ data } : { data : SexoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idSexo: 0,
                    descripcion: '',     
                    abreviacion: '',
                    activo: false,      
                });
            });
        }

    });

}

export const AgregarSexo = ( params: SexoInterface ) => {

    return new Promise<SexoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Sexo/Agregar', params, token)
            .then( ({ data } : { data : SexoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idSexo: 0,
                    descripcion: '',     
                    abreviacion: '',
                    activo: false,         
                });
            });
        }

    });

}