import { useEffect, useState } from "react";

import { Modal, ModalOverflow, ModalDialog, ModalClose, DialogTitle, DialogContent, Grid, DialogActions, Button, CircularProgress } from "@mui/joy";

import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

import { Location } from "../interfaces/global/GeolocalizarInterface";
import { ZonaInterface } from "../interfaces/catalogos/ZonaInterface";

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    zona: ZonaInterface;   
    setZona: React.Dispatch<React.SetStateAction<ZonaInterface>>;
    handleSave: ( location: Location ) => void;
    isLoading: boolean;
}

export const ModalEditUbicacionZona = ( { open, setOpen, zona, setZona, handleSave , isLoading} : Props ) => {
    
    const [isLoadingMapa, setIsLoadingMapa] = useState<boolean>( true );
    const [location, setLocation] = useState<Location>( { lat: 0, lng: 0 } );

    useEffect(() => {

        setIsLoadingMapa( true );
      
        if( zona?.latitud !== 0 && zona?.longitud !== 0 ){
            setLocation( { lat: zona?.latitud ?? 0, lng: zona?.longitud ?? 0 } );
        }

        setTimeout(() => {
            setIsLoadingMapa( false );
        }, 400);
        
    }, [ zona ])    

    return (       
        <Modal             
            disableEscapeKeyDown                
            open={ open }
            onClose={ ( _, r ) =>{ 
                if( r === 'backdropClick' ) return;                     
                setOpen( false )
            }}
            sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
        >
            <ModalOverflow>
            
                <ModalDialog
                    aria-labelledby="server-modal-title"
                    aria-describedby="server-modal-description"
                    layout="center"
                    sx={{ width: '800px' }}
                >
                    <ModalClose />
                    <DialogTitle> 
                        Ubicar domicilio                       
                    </DialogTitle>

                    <DialogContent>      

                        {
                            isLoadingMapa
                            ?
                                <Grid container sx={{ maxHeight: '550px' }}>
                                    <Grid xs={12} sx={{ textAlign: 'center', p: '200px' }}>
                                        <CircularProgress color='neutral' size='md' />
                                    </Grid>
                                </Grid>
                            :
                                <Grid container mt={2}>      

                                    <Grid xs={12} mt={2}>  

                                        <APIProvider apiKey={ process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '' }>

                                            <Map
                                                style={{ height: '500px'}}
                                                defaultCenter={{ lat: zona?.latitud ?? 0, lng: zona?.longitud ?? 0}}
                                                defaultZoom={ 18 }
                                                gestureHandling={ 'greedy' }
                                                disableDefaultUI={ true }
                                            >

                                                <Marker                                               
                                                    position={{ lat: zona?.latitud ?? 0, lng: zona?.longitud ?? 0 }}
                                                    clickable={ false }
                                                    draggable={ true }
                                                    onDragEnd={ ( e ) => {     
                                                        
                                                        setLocation({
                                                            lat: e.latLng?.lat() ?? 0, 
                                                            lng: e.latLng?.lng() ?? 0,
                                                        });

                                                        setZona({
                                                            ...zona,
                                                            latitud: e.latLng?.lat(), 
                                                            longitud: e.latLng?.lng(),
                                                        });

                                                    }}
                                                />
                                                    
                                            </Map>

                                        </APIProvider>                        
                                    
                                    </Grid>
                                
                                </Grid>     
                        }                 

                    </DialogContent>         

                    <DialogActions>
                        <Button 
                            sx={{ bgcolor: '#004360', ':hover': { bgcolor: 'rgba(0, 67, 96, 0.9)' } }}                             
                            onClick={ () => handleSave({ lat: location.lat, lng: location.lng }) }
                            loading={isLoading}
                        >
                            Actualizar
                        </Button>
                        <Button color='neutral' variant='plain' disabled={isLoading} onClick={ () => setOpen( false ) }>Cancelar</Button>
                    </DialogActions>         

                </ModalDialog>
                
            </ModalOverflow>

        </Modal>
    )
}
