import HttpClienteToken from '../../services/HttpClientToken';

import { CiudadInterface, ObtenerCiudadesParams } from '../../interfaces/catalogos/CiudadInterface';

export const ObtenerCiudades = ( params: ObtenerCiudadesParams ) => {

    return new Promise<CiudadInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Ciudad/ObtenerCiudades', params, token)
            .then( ({ data } : { data : CiudadInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const Ciudades = () => {

    return new Promise<CiudadInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Ciudad/', token)
            .then( ({ data } : { data : CiudadInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarCiudad = ( params: CiudadInterface ) => {

    return new Promise<CiudadInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Ciudad/Actualizar', params, token)
            .then( ({ data } : { data : CiudadInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idCiudad: 0,
                    clave: '',
                    descripcion: '',
                    activo: false,
                    idEstado: 0,     
                    idMunicipio: 0,               
                });
            });
        }

    });

}

export const AgregarCiudad = ( params: CiudadInterface ) => {

    return new Promise<CiudadInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Ciudad/Agregar', params, token)
            .then( ({ data } : { data : CiudadInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idCiudad: 0,
                    clave: '',
                    descripcion: '',
                    activo: false,
                    idEstado: 0,     
                    idMunicipio: 0,               
                });
            });
        }

    });

}