
import { useEffect, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';

import { Box, Container, Typography, Button, FormControl, FormLabel, Input, IconButton, CircularProgress, Snackbar, DialogActions, DialogContent, DialogTitle, Modal, ModalDialog, ModalOverflow, } from '@mui/joy';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from '@mui/icons-material/Check';

import { SnackbarProps } from '../../interfaces/ui/Snackbar';

import { RestablecerContrasena, ValidarTokenRecuperacion } from '../../connections/auth/AuthConnection';

export const ResetPasswordPage = () => {

    const navigate = useNavigate();

    const { token } = useParams();

    const [contrasena, setContrasena] = useState<string>( '' );
    const [confirmarContrasena, setConfirmarContrasena] = useState<string>( '' );
    
    const [isVisibility, setIsVisibility] = useState<boolean>( false );
    const [isVisibilityConfirm, setIsVisibilityConfirm] = useState<boolean>( false );

    const [open, setOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>( false );
    const [isLoadingInicio, setIsLoadingInicio] = useState<boolean>( false );

    const [tokenValid, setTokenValid] = useState<boolean>( true );
    const [loadingPage, setLoadingPage] = useState<boolean>( true );

    const [{ type: typeSnackbar, open: openMessage, message }, setOpenMessage] = useState<SnackbarProps>({
        type: 'warning',
        message: '',
        open: false,
    });

    const handleClose = () => setOpenMessage({ type: typeSnackbar, open: false, message })

    const handleReset = async ( e: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {

        e.preventDefault();  

        if( !contrasena ){
            setOpenMessage({type: 'warning', open: true, message: 'Debe escribir una contraseña' });
            return;
        } 
        else if( !confirmarContrasena ){
            setOpenMessage({type: 'warning', open: true, message: 'Debe escribir la confirmación de la contraseña' });
            return;               
        } 
        else if( contrasena !== confirmarContrasena ){                
            setOpenMessage({type: 'warning', open: true, message: 'Las contraseñas no coinciden' });
            return;                    
        }   
        else{

            setIsLoading( true );

           await RestablecerContrasena( { "token" : token ?? '', "contrasena": contrasena } ).then( resp => { 

                if( resp.success ){       
                    
                    setTimeout(() => {
                            
                        setOpenMessage({type: 'success', open: true, message: 'Se cambió la contraseña correctamente' });

                        setIsLoading( false );    

                        setOpen( true );
                        setContrasena( '' );
                        setConfirmarContrasena( '' );

                    }, 1200);
                    
                }
                else{

                    setTimeout(() => {
                        
                        setOpenMessage({ type: 'danger', open: true, message: 'El tiempo de validación de este enlace a pasado de los 30 minutos.' });
                        setIsLoading( false );

                    }, 1200);
                    
                }
                
            });    

        }
    }

    const IniciarSesion = async () => {

        setIsLoadingInicio( true );

        setTimeout(() => {
        
            handleClose();

            setIsLoadingInicio( false );

            navigate("/Auth/Login");

        }, 400);

    }

    useEffect(() => {
       
        async function Obtener(){

            await ValidarTokenRecuperacion( { token: token ?? ''  } ).then( response => {

                if( response ){
                   
                    setTimeout(() => {
                       
                        if( response.success === false ){                 
                            
                            setTokenValid( false );
                            setLoadingPage( false );

                        }
                        else{
                            
                            setLoadingPage( false );

                        }

                    }, 700);
                    
                }
            });
        }

        Obtener();

    }, [ token ] );

    if( !tokenValid ){
        return (
            <>
                <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', color: 'white', backgroundColor: "#004360", }} > 

                    <Container maxWidth='lg' sx={{ bgcolor: 'white', minHeight: '100vh', pt:'3em', pb: '2em' }} >

                        <Container component="main" sx={{ mt: 1, mb: 3 }} maxWidth="sm">

                            <Container component="main" sx={{ textAlign: 'center' }} maxWidth="md">         
                                <img
                                    src={ process.env.PUBLIC_URL + "/logos/Logo_CA.png"}
                                    alt={"PJECZ"}
                                    style={{ width: "400px" }}
                                />
                            </Container> 

                            <Typography component="h1" level='h2' mt={10} textAlign='center'>
                                LO SENTIMOS, ESTE ENLACE A CADUCADO.
                            </Typography>

                        </Container>   

                        <Container component="main" sx={{ mt: 8, mb: 4 }} maxWidth="sm">
                            <Typography component="article" level='body-md' textAlign='center'>
                                El tiempo de validación de este enlace a pasado de los 30 minutos.
                            </Typography>
                        </Container> 

                        <Container component="main" sx={{ mt: 6, textAlign: 'center' }} maxWidth="md">               
                            <Link to="/auth/forgot-password/" style={{ textDecoration: 'none' }} > 
                                <Button variant="solid" color='neutral' startDecorator={ <ArrowBackIosIcon />} >Recuperar contraseña</Button> 
                            </Link>  
                        </Container> 

                    </Container> 

                </Box>
            </>
        )
    }
    else{
        return (   

            <>
                <Snackbar
                    key={ 'snackbar-login' }
                    autoHideDuration={ 2000 }
                    color={ typeSnackbar }
                    size="md"
                    variant="solid"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={ openMessage }
                    onClose={ handleClose }
                    startDecorator={ typeSnackbar === 'success' ? <CheckIcon /> : <WarningIcon /> }
                >
                    { message }
                </Snackbar>
            
                <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', color: 'white', backgroundColor: "#004360", }} >     

                    {
                        loadingPage
                        ?
                            <Container maxWidth='lg' sx={{ bgcolor: 'white', minHeight: '100vh' }} >
                            
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} >
                                    <CircularProgress color='neutral' size={'lg'} />
                                </Box>

                            </Container>
                        :
                            <Container maxWidth='lg' sx={{ bgcolor: 'white', minHeight: '100vh', pt:'3em', pb: '2em' }} >
                            
                                <Container component="main" sx={{ mt: 1, mb: 3 }} maxWidth="sm">

                                    <Container component="main" sx={{ textAlign: 'center' }} maxWidth="md">         
                                        <img
                                            src={ process.env.PUBLIC_URL + "/logos/Logo_CA.png"}
                                            alt={"PJECZ"}
                                            style={{ width: "400px" }}
                                        />
                                    </Container> 

                                    <Typography component="h1" level='h2' mt={6} textAlign={'center'}>
                                        Recuperar contraseña
                                    </Typography>

                                </Container>   

                                <Container component="main" sx={{ mt: 4, mb: 4 }} maxWidth="sm">
                                    <Typography component="article" level='body-md'>
                                        Ingresa tu nueva contraseña para ingresar a la plataforma.
                                    </Typography>
                                </Container> 

                                <form>
                                
                                    <Container component='div' sx={{ mt: 2 }} maxWidth="sm">
                                    
                                        <FormControl required>

                                            <FormLabel sx={{ fontSize: 16 }}>
                                                Nueva contraseña
                                            </FormLabel>
                                            <Input 
                                                type={ isVisibility ? "text" : "password" }
                                                name="password" 
                                                size="lg" 
                                                autoComplete="off" 
                                                endDecorator={ 
                                                    <IconButton onClick={ () => setIsVisibility( ( prev ) => !prev ) }> 
                                                        {
                                                            isVisibility
                                                            ?
                                                                <VisibilityIcon /> 
                                                            :
                                                                <VisibilityOffIcon />
                                                        }
                                                    </IconButton> 
                                                }
                                                value={ contrasena } 
                                                onChange={ (e) => setContrasena( e.target.value ) }  
                                            />

                                        </FormControl>

                                        <FormControl required sx={{ mt: 2 }}>

                                            <FormLabel sx={{ fontSize: 16 }}>
                                                Confirmar contraseña
                                            </FormLabel>
                                            <Input 
                                                type={ isVisibilityConfirm ? "text" : "password" }
                                                name="password" 
                                                size="lg" 
                                                autoComplete="off" 
                                                endDecorator={ 
                                                    <IconButton onClick={ () => setIsVisibilityConfirm( ( prev ) => !prev ) }> 
                                                        {
                                                            isVisibilityConfirm
                                                            ?
                                                                <VisibilityIcon /> 
                                                            :
                                                                <VisibilityOffIcon />
                                                        }
                                                    </IconButton> 
                                                }
                                                value={ confirmarContrasena } 
                                                onChange={ (e) => setConfirmarContrasena( e.target.value ) }  
                                            />

                                        </FormControl>                   

                                    </Container>

                                    <Container component="main" sx={{ mt: 5, textAlign: 'end' }} maxWidth="sm">  
                                        <Button 
                                            type='submit'
                                            color='neutral' 
                                            variant="solid" 
                                            startDecorator={ <SaveOutlinedIcon /> }
                                            loading={ isLoading }
                                            onClick={ handleReset }
                                        >
                                            Guardar contraseña
                                        </Button>                   
                                    </Container>   

                                </form>

                            </Container>
                    }                    

                </Box>       

                <Modal             
                    disableEscapeKeyDown                
                    open={ open }
                    onClose={ ( _, r ) =>{ 
                        if( r === 'backdropClick' ) return;                     
                        setOpen( false )
                    }}
                    sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
                >
                    <ModalOverflow>
                    
                        <ModalDialog
                            aria-labelledby="server-modal-title"
                            aria-describedby="server-modal-description"
                            layout="center"
                            size='lg'
                            sx={{ width: 600 }}
                        >
                            
                            <DialogTitle> Iniciar sesión </DialogTitle>

                            <DialogContent>   
                                Para que la cuenta tome los nuevos cambios es necesario volver a iniciar sesión.
                            </DialogContent>     

                            <DialogActions>
                                
                                <Button variant="solid" color="neutral" loading={ isLoadingInicio } onClick={ IniciarSesion }>
                                    Aceptar
                                </Button>

                            </DialogActions>    

                        </ModalDialog>
                        
                    </ModalOverflow>

                </Modal>           
            </>

        )
    }
}