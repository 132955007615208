import HttpClienteToken from '../../services/HttpClientToken';

import { PuestoInterface } from '../../interfaces/catalogos/PuestoInterface';

export const ObtenerPuestos = () => {

    return new Promise<PuestoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Puesto/ObtenerPuestos', token)
            .then( ({ data } : { data : PuestoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const Puestos = () => {

    return new Promise<PuestoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/Puesto/', token)
            .then( ({ data } : { data : PuestoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarPuesto = ( params: PuestoInterface ) => {

    return new Promise<PuestoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Puesto/Actualizar', params, token)
            .then( ({ data } : { data : PuestoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idPuesto: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarPuesto = ( params: PuestoInterface ) => {

    return new Promise<PuestoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/Puesto/Agregar', params, token)
            .then( ({ data } : { data : PuestoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idPuesto: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}