import HttpClienteToken from '../../services/HttpClientToken';

import { TipoAcuerdoInterface } from '../../interfaces/catalogos/TipoAcuerdoInterface';

export const ObtenerTiposAcuerdos = () => {

    return new Promise<TipoAcuerdoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoAcuerdo/ObtenerTiposAcuerdos', token)
            .then( ({ data } : { data : TipoAcuerdoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const TipoAcuerdo = () => {

    return new Promise<TipoAcuerdoInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoAcuerdo/', token)
            .then( ({ data } : { data : TipoAcuerdoInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarTipoAcuerdo = ( params: TipoAcuerdoInterface ) => {

    return new Promise<TipoAcuerdoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoAcuerdo/Actualizar', params, token)
            .then( ({ data } : { data : TipoAcuerdoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoAcuerdo: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarTipoAcuerdo = ( params: TipoAcuerdoInterface ) => {

    return new Promise<TipoAcuerdoInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoAcuerdo/Agregar', params, token)
            .then( ({ data } : { data : TipoAcuerdoInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoAcuerdo: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}