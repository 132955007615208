import HttpClienteToken from '../../services/HttpClientToken';

import { ConsultarListadosEnvioParams, ListadoEnvioProps, ListadoEnvioResponse, ListadosEnvioResponse } from '../../interfaces/comun/ListadoEnvioInterface';
import { UsuarioProps } from '../../interfaces/admin/UsuarioInterface';
import { PaginacionInterface } from '../../interfaces/global/PaginacionInterface';

export const ObtenerListadosEnvios = () => {

    return new Promise<ListadoEnvioProps[]>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.get('/Api/ListadoEnvio/ObtenerListadosEnvios', token)
           .then(({ data }: { data: ListadoEnvioProps[] }) => {
                resolve( data );
            })
            .catch(() => {
                resolve( [] );
            });

        }

    });

}

export const FinalizarRecepcion = (params: ListadoEnvioProps[]) => {

    return new Promise<ListadoEnvioResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/ListadoEnvio/FinalizarRecepcion', params, token)
           .then(({ data }: { data: ListadoEnvioResponse }) => {
                resolve( data );
            })
            .catch(() => {
                resolve( { success : false, message : "Ocurrio un error, contacte con el administrador", acuse : "" } );
            });

        }

    });

}

export const ObtenerActuariosEnvia = ( ) => {

    return new Promise<UsuarioProps[]>( ( resolve, eject ) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/ListadoEnvio/ObtenerActuariosEnvia/', token)
            .then( ({ data } : { data : UsuarioProps[]} ) => {
                resolve( data );
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ObtenerUsuarioRecibe = ( ) => {

    return new Promise<UsuarioProps[]>( ( resolve, eject ) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/ListadoEnvio/ObtenerUsuarioRecibe/', token)
            .then( ({ data } : { data : UsuarioProps[]} ) => {
                resolve( data );
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ListadosEnvio = ( params: PaginacionInterface ) => {

    return new Promise<ListadosEnvioResponse>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/ListadoEnvio/ListadosEnvio', params, token)
            .then( ({ data } : { data : ListadosEnvioResponse } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve({
                    pageCount: 0,
                    currentPageIndex: 0,
                    recordsCount: 0,
                    records: [],
                });
            });
        }

    });

}

export const ConsultarListadosEnvio = ( params: ConsultarListadosEnvioParams ) => {

    return new Promise<ListadosEnvioResponse>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/ListadoEnvio/ConsultarListadosEnvio', params, token)
            .then( ({ data } : { data : ListadosEnvioResponse } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve({
                    pageCount: 0,
                    currentPageIndex: 0,
                    recordsCount: 0,
                    records: [],
                });
            });
        }

    });

}