import { useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, Container, Typography, Button, FormControl, FormLabel, Input, Snackbar } from '@mui/joy';

import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from '@mui/icons-material/Check';

import { SnackbarProps } from '../../interfaces/ui/Snackbar';

import { RecuperarContrasena } from '../../connections/auth/AuthConnection';

export const ForgotPasswordPage = () => {

    const [correoElectronico, setCorreoElectronico] = useState<string>( '' );
    const [confirmarCorreoElectronico, setConfirmarCorreoElectronico] = useState<string>( '' );

    const [isLoading, setIsLoading] = useState<boolean>( false );

    const [{ type: typeSnackbar, open: openMessage, message }, setOpenMessage] = useState<SnackbarProps>({
        type: 'warning',
        message: '',
        open: false,
    });

    const handleClose = () => setOpenMessage({ type: typeSnackbar, open: false, message })

    const handleReset = async ( e: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) => {
       
        e.preventDefault();  

        const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test( correoElectronico );
        const validConfirmEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test( confirmarCorreoElectronico );

        if( !correoElectronico ){                    
            setOpenMessage({ type: 'warning', open: true, message: 'Debe escribir un correo electrónico' });
            return;            
        } 
        else if( !validEmail ){  
            setOpenMessage({ type: 'warning', open: true, message: 'El correo electrónico no tiene el formato correcto' });
            return;
            
        }
        else if( !confirmarCorreoElectronico ){
            setOpenMessage({ type: 'warning', open: true, message: 'Debe confirmar el correo electrónico' });
            return;
            
        }   
        else if( !validConfirmEmail ){  
            setOpenMessage({ type: 'warning', open: true, message: 'La confirmación de correo electrónico no tiene el formato correcto' });
            return;
        }
        else if( correoElectronico !== confirmarCorreoElectronico ){ 
            setOpenMessage({ type: 'warning', open: true, message: 'Los correos electrónicos no coinciden' });
            return;
        }   
        else{        
            
            setIsLoading( true );

            const params = { "correoElectronico": correoElectronico }

            await RecuperarContrasena( params ).then( resp => {
                
                if( resp.success ){

                    setTimeout(() => {                    

                        setOpenMessage({ type: 'success', open: true, message: `Se envió un correo de recuperación al correo electrónico: ${correoElectronico}` });
                        setIsLoading( false );     
                        
                        setCorreoElectronico( '' );
                        setConfirmarCorreoElectronico( '' );
                    
                    }, 400);
                   
                }
                else{

                    setTimeout(() => {

                        setOpenMessage({ type: 'danger', open: true, message: `Ocurrió un problema con el envío del correo de recuperacion: ${ resp.message }` });
                        setIsLoading( false );           
                    
                    }, 400);
                    
                }

            });
        }
    }

    return (   
        <>
            <Snackbar
                key={ 'snackbar-login' }
                autoHideDuration={2000}
                color={ typeSnackbar }
                size="md"
                variant="solid"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={ openMessage }
                onClose={ handleClose }
                startDecorator={ typeSnackbar === 'success' ? <CheckIcon /> : <WarningIcon /> }
            >
                { message }
            </Snackbar>
        
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', color: 'white', backgroundColor: "#004360", }} >
                
                <Container maxWidth='lg' sx={{ bgcolor: 'white', minHeight: '100vh', pt:'3em', pb: '2em' }} >

                    <Container component="main" sx={{ mt: 1, mb: 3 }} maxWidth="sm">

                        <Container component="main" sx={{ textAlign: 'center' }} maxWidth="md">         
                            <img
                                src={ process.env.PUBLIC_URL + "/logos/Logo_CA.png"}
                                alt={"PJECZ"}
                                style={{ width: "400px" }}
                            />
                        </Container> 

                        <Typography component="h1" level='h2' mt={3} textAlign={'center'}>
                            ¿Perdiste tu contraseña? 
                        </Typography>

                    </Container>   

                    <Container component="main" sx={{ mt: 4, mb: 4 }} maxWidth="sm">
                        <Typography component="article" level='body-md'>
                            Ingresa tu correo electrónico y recibiras un enlace para crear una nueva contraseña.
                        </Typography>
                    </Container> 

                    <form>

                        <Container component='div' sx={{ mt: 2 }} maxWidth="sm">

                            <FormControl required sx={{ mt: 3 }} >
                                <FormLabel sx={{ fontSize: 16 }}>
                                    Correo electrónico
                                </FormLabel>
                                <Input 
                                    type="email" 
                                    name="email" 
                                    size="lg" 
                                    autoComplete="off" 
                                    value={ correoElectronico }
                                    onChange={ ( e ) => setCorreoElectronico( e.target.value ) }
                                />
                            </FormControl>

                            <FormControl required sx={{ mt: 3 }} >
                                <FormLabel sx={{ fontSize: 16 }}>
                                    Confirmar correo electrónico
                                </FormLabel>
                                <Input 
                                    type="email" 
                                    name="email" 
                                    size="lg" 
                                    autoComplete="off" 
                                    value={ confirmarCorreoElectronico }
                                    onChange={ ( e ) => setConfirmarCorreoElectronico( e.target.value ) }
                                />
                            </FormControl>

                        </Container>

                        <Container component="main" sx={{ mt: 5, textAlign: 'end' }} maxWidth="sm">  
                            <Button 
                                type='submit'
                                color='neutral' 
                                variant="solid" 
                                startDecorator={ <ForwardToInboxIcon /> }
                                loading={ isLoading }
                                onClick={ handleReset }
                            >
                                Enviar correo de recuperación
                            </Button>                   
                        </Container> 

                    </form>

                    <Container component="main" sx={{ mt: 5, textAlign: 'center' }} maxWidth="sm">               
                        <Link to="/" style={{ textDecoration: 'none' }} > 
                            <Button color='neutral' variant="plain">
                                ¿Ya tienes una cuenta?, Ingresa aquí.
                            </Button> 
                        </Link>  
                    </Container> 

                </Container>

            </Box>
        
        </>
    )
}