import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Grid, Button, Table, Switch, IconButton, Typography, CircularProgress, DialogActions, FormControl, FormLabel, Input, Modal, ModalDialog, ModalOverflow, Select, Textarea, Option, Alert } from '@mui/joy';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import { Sidebar } from '../../components/Sidebar';

import { Header } from '../../components/Header';

import { Layout } from '../../components/Layout';

import Pagination from '../../components/Pagination';

import { ActualizarAutoridad, AgregarDomicilio, Autoridades } from '../../connections/catalogos/AutoridadConnection';
import { ObtenerMaterias } from '../../connections/catalogos/MateriaConnection';
import { ObtenerDistritosJudiciales } from '../../connections/catalogos/DistritoJudicialConnection';
import { ObtenerEstados } from '../../connections/catalogos/EstadoConnection';
import { ObtenerTiposDomicilios } from '../../connections/catalogos/TipoDomicilioConnection';
import { ObtenerMunicipios } from '../../connections/catalogos/MunicipioConnection';
import { ObtenerAsentamientosMunicipio } from '../../connections/catalogos/AsentamientoConnection';

import { AutoridadDomicilioProps, AutoridadInterface } from '../../interfaces/catalogos/AutoridadInterface';
import { MateriaInterface } from '../../interfaces/catalogos/MateriaInterface';
import { DistritoJudicialInterface } from '../../interfaces/catalogos/DistritoJudicialInterface';
import { EstadoInterface } from '../../interfaces/catalogos/EstadoInterface';
import { MunicipioInterface } from '../../interfaces/catalogos/MunicipioInterface';
import { TipoDomicilioInterface } from '../../interfaces/catalogos/TipoDomicilioInterface';
import { AsentamientoParser } from '../../interfaces/catalogos/AsentamientoInterface';
import { DomicilioInterface } from '../../interfaces/comun/DomicilioInterface';

interface Errors {
    descripcion?:       string;
    referencia?:        string;
    materia?:           string;
    distritoJudicial?:  string;
    estado?:            string;
    municipio?:         string;
    asentamiento?:      string;
    tipoDomicilio?:     string;
    descripcionDomicilio?: string;
    numero?:            string;
}

type OptionType = 'Agregar' | 'Editar' | 'Agregar domicilio' ; 

export const AutoridadPage = ( ) => {

    const navigate = useNavigate();

    const [rows, setRows] = useState<AutoridadInterface[]>( [] );

    const [isLoading, setIsLoading] = useState<boolean>( true );
    const [isSaving, setIsSaving] = useState<boolean>( false );

    const [page, setPage] = useState<number>( 0 );
    const [rowsPerPage, setRowsPerPage] = useState<number>( 10 );

    const [open, setOpen] = useState<boolean>( false );

    const [estado, setEstado] = useState<number>( 0 );
    const [estadoArray, setEstadoArray] = useState<EstadoInterface[]>( [] );

    const [municipio, setMunicipio] = useState<number>( 0 );
    const [municipioArray, setMunicipioArray] = useState<MunicipioInterface[]>( [] );

    const [tipoDomicilio, setTipoDomicilio] = useState<number>( 0 );
    const [tipoDomicilioArray, setTipoDomicilioArray] = useState<TipoDomicilioInterface[]>( [] );

    const [asentamiento, setAsentamiento] = useState<number>( 0 );
    const [asentamientoArray, setAsentamientoArray] = useState<AsentamientoParser[]>( [] );

    const [descripcionDomicilio, setDescripcionDomicilio] = useState<string>( '' );

    const [numero, setNumero] = useState<string>( '' );

    const [referenciaDomicilio, setReferenciaDomicilio] = useState<string>( '' );

    const [openDomicilio, setOpenDomicilio] = useState<boolean>( false );

    const [referencia, setReferencia] = useState<string>( '' );
        
    const [idAutoridad, setIdAutoridad] = useState<number>( 0 );

    const [opcion, setOpcion] = useState<OptionType>( 'Agregar' );

    const [distritoJudicial, setDistritoJudicial] = useState<number>( 0 );
    const [distritoJudicialArray, setDistritoJudicialArray] = useState<DistritoJudicialInterface[]>( [ ] );

    const [materia, setMateria] = useState<number>( 0 );
    const [materiaArray, setMateriaArray] = useState<MateriaInterface[]>( [ ] );

    const [domicilio, setDomicilio] = useState<DomicilioInterface>( {} );

    const [descripcion, setDescripcion] = useState<string>( '' );

    const [errors, setErrors] = useState<Errors>( {} );

    const [errorsDomicilio, setErrorsDomicilio] = useState<Errors>( {} );

    const handleCloseModal = () => {
        setOpen( false );
        setErrors( { } );
    }

    const handleCloseModalDomicilio = () => {
        setOpenDomicilio( false );
        setEstado(0);
        setMunicipio(0);
        setAsentamiento(0);
        setTipoDomicilio(0);
        setDescripcionDomicilio('');
        setNumero('');
        setReferenciaDomicilio('');
        setDomicilio({});
        setErrors( { } );
        setAsentamientoArray([]);
        setMunicipioArray([]);
        setErrorsDomicilio( { } );
    }

    const handleChangeSwitch = ( id: number, value: boolean, autoridadSelected: AutoridadInterface ) => {
        
        const newRows = rows.map( (row) => { if(row.idAutoridad === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );  
        
        cambiarEstatus( autoridadSelected );
    }

    const cambiarEstatus = async ( { idAutoridad, descripcion, activo, idMateria, idDistritoJudicial,referencia, idDomicilio }: AutoridadInterface ) => {

        const params: AutoridadInterface = {
            idAutoridad,
            descripcion,
            activo,
            idMateria,
            idDistritoJudicial,
            referencia,
            idDomicilio,

        };

        await ActualizarAutoridad( params );    
    }

    const EditarRegistro = ({ idAutoridad, referencia, descripcion, idMateria, idDistritoJudicial} : AutoridadInterface) => {
        
        setDescripcion( descripcion );
        setMateria( idMateria );
        setDistritoJudicial( idDistritoJudicial );
        setReferencia( referencia?.toString() ?? ''  );
        setOpcion( 'Editar' );
        setIdAutoridad( idAutoridad );
        setOpen( true );
    }

    const EditarDomicilio = ({ idAutoridad, domicilio} : AutoridadInterface) => {
        
        setIdAutoridad( idAutoridad );
        setOpenDomicilio( true );
        if( domicilio ){

            setDomicilio(domicilio);
        }
    }
   
    const AgregarRegistro = () => {

        setMateria(0 );
        setDistritoJudicial( 0 );
        setDescripcion( '' );        
        setReferencia( '');     
        setOpcion( 'Agregar' );
        setIdAutoridad( 0 );

        setOpen( true );
    }

    const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores: Errors = {};

        if( descripcion.trim() === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        }    
       
        if( materia === 0 ){
            valid = false;
            errores.materia = 'Debes seleccionar una materia';
        }  

        if( distritoJudicial === 0 ){
            valid = false;
            errores.distritoJudicial = 'Debes seleccionar un distrito judicial';
        }  

        setErrors( errores );
        return valid;
    } 

    const validateFielsDomicilio = () => {
        
        setErrorsDomicilio( { } );

        let valid = true;
        let errores: Errors = {};
           
        if( estado === 0 ){
            valid = false;
            errores.estado = 'Debes seleccionar un estado';
        }  

        if( municipio === 0 ){
            valid = false;
            errores.municipio = 'Debes seleccionar un municipio';
        }  

        if( asentamiento === 0 ){
            valid = false;
            errores.asentamiento = 'Debes seleccionar un asentamiento';
        } 

        if( tipoDomicilio === 0 ){
            valid = false;
            errores.tipoDomicilio = 'Debes seleccionar un tipo de domicilio';
        } 

        if( descripcionDomicilio ==='' ){
            valid = false;
            errores.descripcionDomicilio = 'Debes escribir la calle';
        }

        if( numero ==='' ){
            valid = false;
            errores.numero = 'Debes escribir un numero';
        }    

        setErrorsDomicilio( errores );
        return valid;
    } 

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }

        setIsSaving( true );
       
        if( opcion === 'Editar' ){

            const autoridad = rows.filter( function(row){ return row.idAutoridad === idAutoridad; } )[0];

            const params: AutoridadInterface = {
                idAutoridad: idAutoridad,
                idDistritoJudicial: distritoJudicial,
                idMateria: materia,
                referencia: referencia === '' ? 0 : parseInt(referencia),
                descripcion: descripcion.trim(),                
                activo: autoridad.activo,  
            };
    
            await ActualizarAutoridad( params ).then( resp => {
              
                if( resp ){

                    setTimeout(() => {
                        
                        const newRows = rows.map( (row) => { 
                            if(row.idAutoridad === idAutoridad){ 
                                row.referencia = resp.referencia; 
                                row.descripcion = descripcion; 
                                row.idMateria = resp.idMateria;
                                row.materia = resp.materia;
                                row.idDistritoJudicial = resp.idDistritoJudicial;
                                row.distritoJudicial = resp.distritoJudicial;
                                row.activo = resp.activo;
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });
    
                        setRows( newRows ); 
                        
                        setIsSaving( false );
                        handleCloseModal();

                    }, 400);
                    
                }      

            });
        }     
    }

    const GuardarDomicilio = async () => {

        const valid = validateFielsDomicilio();

        if( !valid ){
            return false;
        }

        setIsSaving( true );

            const params: AutoridadDomicilioProps = {
                idAutoridad: idAutoridad,
                idDomicilio: domicilio.idDomicilio ?? 0,
                descripcion: descripcionDomicilio.trim(),
                numero: numero.trim(),
                referencia: referenciaDomicilio.trim(),
                idAsentamiento: asentamiento,
                idTipoDomicilio: tipoDomicilio,
            };

            await AgregarDomicilio( params ).then( resp => {

                if( resp ){

                    console.log(resp)

                    setTimeout(() => {
                        const newRows = rows.map( (row) => { 
                            if(row.idAutoridad === idAutoridad){ 
                                row.domicilio = resp; 
                                return row; 
                            } 
                            else{ 
                                return row; 
                            } 
                        });

                        setRows( newRows ); 
                        setIsSaving( false );
                        handleCloseModalDomicilio();

                    }, 400);
                        
                }

            });
    }      
    
    useEffect(() => {

        async function obtener(){

            setIsLoading( true );
            setRows( [] );

            await Autoridades().then( resp => {

                setTimeout(() => {

    
                    setRows( resp );
                    setIsLoading( false );
                    
                }, 400);


            });

        }

        obtener();  

    }, [ page, rowsPerPage ])    

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerDistritosJudiciales().then( resp => {  
                setDistritoJudicialArray( resp );
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerMaterias().then( resp => {  
                setMateriaArray( resp );
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerEstados().then( resp => {  
                setEstadoArray( resp );
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){     

            setMunicipio( 0 );
            setMunicipioArray( [] );

            if( estado !== 0 ){       
                
                await ObtenerMunicipios( estado ).then( resp => {                   
                    setMunicipioArray( resp );                           
                });

            }

        }

        Obtener();

    }, [ estado ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerTiposDomicilios().then( resp => {  
                setTipoDomicilioArray( resp );
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){     

            
            setAsentamiento( 0 );
            setAsentamientoArray( [] );

            if( municipio !== 0 ){       
                
                await ObtenerAsentamientosMunicipio( municipio ).then( resp => {                   
                    setAsentamientoArray( resp );                           
                });

            }

        }

        Obtener();

    }, [ municipio ]);

    useEffect(() => {

        if( Object.keys( domicilio ).length !== 0 ){

            setEstado( domicilio.asentamiento?.idEstado ?? 0 );
            setTipoDomicilio( domicilio.idTipoDomicilio ?? 0 );
            setDescripcionDomicilio( domicilio.descripcion ?? '' );
            setNumero( domicilio.numero ?? '' );
            setReferenciaDomicilio( domicilio.referencia ?? '' );

        }

    }, [ domicilio ]); 
    
    useEffect(() => {
        
        if( Object.keys( domicilio ).length !== 0 ){
        
            setMunicipio( 0 );

            if( municipioArray.length > 0 && domicilio.asentamiento?.idEstado === estado){
                setMunicipio( domicilio.asentamiento?.idMunicipio ?? 0 );
            }

        }

    }, [ domicilio, estado, municipioArray ]);

    useEffect(() => {
        
        if( Object.keys( domicilio ).length !== 0 ){
        
            setAsentamiento( 0 );

            if( asentamientoArray.length > 0 ){
                setAsentamiento( domicilio.idAsentamiento ?? 0 );
            }
        }

    }, [ domicilio, asentamientoArray ]);
   
    return (
     
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Sidebar />

            <Header />

            <Layout title={'Autoridad'} isCatalog={true} >

                <Grid container spacing={3} >  

                    <Grid md={6} xs={6} sx={{ textAlign: 'left' }}>  
                        <Button 
                            type="button" 
                            variant='plain' 
                            color='neutral' 
                            startDecorator={ <ArrowBackIcon /> } 
                            sx={{ mt: 1, textAlign: 'left' }} 
                            onClick={ () => navigate('/catalogos/') } 
                        > 
                            Regresar
                        </Button> 
                    </Grid>
                    
                    <Grid md={6} xs={6} sx={{ textAlign: 'right' }}> 
                        <Button 
                            type="button" 
                            color='neutral' 
                            startDecorator={ <AddCircleOutlineRoundedIcon /> } 
                            sx={{ mt: 1 }} 
                            disabled
                            onClick={ AgregarRegistro }
                        > 
                            Agregar
                        </Button> 
                    </Grid>
                        
                    <Grid xs={12} md={12}> 
                        
                        <Table  
                            aria-label="striped table" 
                            stripe={'even'} 
                            borderAxis='x'
                            size='md' 
                            sx={{ 
                                width: { xs: '100%', md: '100%' },
                                display:{ xs: 'block', md: 'inline' },
                                overflowX: { xs: 'auto', md: 'hidden' },
                            }}
                        >          

                            <thead>

                                <tr>        
                                    <th style={{ width:'5%' }}> ID </th>                                     
                                    <th style={{ width:'35%' }}> DESCRIPCIÓN </th>                                   
                                    <th style={{ width:'10%' }}> MATERIA </th>                                   
                                    <th style={{ width:'15%' }}> DISTRITO JUDICIAL </th>                                   
                                    <th style={{ width:'5%' }}> REFERENCIA </th>                                   
                                    <th style={{ width:'5%' }}> DOMICILIO </th>                                   
                                    <th style={{ width:'5%', textAlign: 'center' }}> HABILITAR </th>
                                    <th style={{ width:'5%', textAlign: 'center' }}> EDITAR </th>
                                </tr>

                            </thead>                            

                            <tbody>        

                                 {

                                    isLoading
                                    ?
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '200px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </td>
                                        </tr>
                                    :
                                        rows.map( ( row, index ) => (                       

                                            <tr key={ index }>                                         

                                                <td style={{ fontWeight: 'bold' }}>
                                                    { row.idAutoridad }
                                                </td>

                                                <td>
                                                    { row.descripcion }
                                                </td>

                                                <td>
                                                    { row.materia?.descripcion }
                                                </td>

                                                <td>
                                                    { row.distritoJudicial?.descripcion }
                                                </td>
                                            
                                                <td>
                                                    { row.referencia }
                                                </td>
                                            
                                                <td>
                                                    <Button
                                                        color='neutral'
                                                        onClick={ () => { EditarDomicilio(row); } }
                                                    >
                                                        <HomeRoundedIcon />
                                                    </Button>
                                                </td>
                                                
                                                <td style={{ textAlign: 'center' }}>
                                                    
                                                    <Switch 
                                                        checked={ row.activo } 
                                                        onChange={ ( e ) => handleChangeSwitch( row.idAutoridad, e.target.checked, row ) }
                                                        color={ row.activo ? 'success' : 'neutral'}
                                                        size='lg'
                                                    />
                                                        
                                                </td>

                                                <td style={{ textAlign: 'center' }}>
                                                    <IconButton 
                                                        aria-label="edit" 
                                                        component="span" 
                                                        color='neutral'
                                                        onClick={ () => EditarRegistro( row ) }
                                                    >                                                        
                                                        <EditIcon />
                                                    </IconButton>  
                                                </td>

                                            </tr>     
                                    
                                        ))
                                }

                                {
                                    ( !isLoading && rows.length === 0 )
                                    &&
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '50px' }}>
                                                <Typography fontSize={14} fontWeight={'bold'}>No se encontraron registros</Typography>
                                            </td>
                                        </tr>
                                }                     

                            </tbody>

                        </Table>     

                    </Grid>

                </Grid>

                <Pagination 
                    gotoPage={ setPage }
                    length={ rows.length }
                    pageSize={ rowsPerPage }
                    setPageSize={ setRowsPerPage }       
                    loading={ isLoading }                     
                />
                
            </Layout>

            <Modal             
                disableEscapeKeyDown                
                open={ open }
                onClose={ ( _, r ) =>{ 
                    if( r === 'backdropClick' ) return;                     
                    setOpen( false )
                }}
                sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
            >
                <ModalOverflow>
                
                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    > 

                        <Grid container spacing={3} sx={{ maxHeight: '550px', width: { xs: 'auto', lg: '600px' }, overflow: 'scroll' }}>                               

                            <Grid md={6} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-distritoJudicial-label">Distrito Judicial</FormLabel>
                                
                                    <Select                      
                                        name="distritoJudicial"             
                                        size='lg'    
                                        slotProps={{ listbox: { sx: { width: '100%', zIndex: 99999999 } } }}   
                                        value={ distritoJudicial }                                
                                        onChange={ ( _, value ) => { setDistritoJudicial( value ?? 0 ); } }  

                                    >                 
                                        <Option key={0} value={0}>Selecciona una opción</Option>
                                        {
                                            distritoJudicialArray.map( ( elem ) => (
                                                <Option key={ elem.idDistritoJudicial } value={ elem.idDistritoJudicial }> { elem.descripcion } </Option>
                                            ))
                                        }                             
                                    </Select>

                                    {   
                                        errors.distritoJudicial && ( 
                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                { errors.distritoJudicial } 
                                            </Alert> )
                                    }   

                                </FormControl>

                            </Grid>

                            <Grid md={6} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-materia-label">Materia</FormLabel>
                                
                                    <Select 
                                        name="materia"          
                                        size='lg'      
                                        slotProps={{ listbox: { sx: { width: '100%', zIndex: 99999999 } } }}      
                                        value={ materia }                                
                                        onChange={ ( _, value ) => { setMateria( value ?? 0 ); } }  
                                    >
                                        <Option key={0} value={0}>Selecciona una opción</Option>
                                        {
                                            materiaArray.map( ( elem ) => (
                                                <Option key={ elem.idMateria } value={ elem.idMateria }> { elem.descripcion } </Option>
                                            ))
                                        }
                                    </Select>

                                    {   
                                        errors.materia && ( 
                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                { errors.materia } 
                                            </Alert> )
                                    }   

                                </FormControl>

                            </Grid>                               
                            
                            <Grid md={12} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-referencia-label">Referencia</FormLabel>
                                
                                    <Input
                                        size={'lg'}        
                                        placeholder=''                     
                                        name="referencia"
                                        variant="outlined"
                                        required
                                        type='number'
                                        value={ referencia }
                                        onChange={ ( e ) => { setReferencia( e.target.value) } }
                                    />
                                 

                                </FormControl>

                                {   
                                    errors.referencia && ( 
                                        <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                            { errors.referencia } 
                                        </Alert> )
                                }   

                            </Grid>
                         
                            <Grid md={12} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-descripcion-label">Descripción</FormLabel>
                                
                                    <Textarea
                                        size={'lg'}        
                                        placeholder=''                     
                                        name="descripcion"
                                        variant="outlined"
                                        required
                                        minRows={3}
                                        maxRows={6}
                                        value={ descripcion }
                                        onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                                    >
                                    </Textarea> 

                                </FormControl>

                                {   
                                    errors.descripcion && ( 
                                        <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                            { errors.descripcion } 
                                        </Alert> )
                                }   

                            </Grid>

                        </Grid>                                  
      
                        <DialogActions>

                            <Button color='neutral' onClick={ Guardar } loading={ isSaving }>
                                Guardar 
                            </Button>

                            <Button variant='plain' color='neutral' onClick={ handleCloseModal }>
                                Cancelar
                            </Button>

                        </DialogActions>

                    </ModalDialog>
                    
                </ModalOverflow>

            </Modal>     

            <Modal             
                disableEscapeKeyDown                
                open={ openDomicilio }
                onClose={ ( _, r ) =>{ 
                    if( r === 'backdropClick' ) return;                     
                    setOpenDomicilio( false )
                }}
                sx={{  zIndex: 9999999, backdropFilter: 'blur(0px)' }}                
            >
                <ModalOverflow>
                
                    <ModalDialog
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        layout="center"
                        size='lg'
                    > 

                        <Grid container spacing={3} sx={{ maxHeight: '550px', width: { xs: 'auto', lg: '600px' }, overflow: 'scroll' }}>                               

                            <Grid md={6} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-estado-label">Estado</FormLabel>
                                
                                    <Select                      
                                        name="estado"             
                                        size='lg'    
                                        slotProps={{ listbox: { sx: { width: '100%', zIndex: 99999999 } } }}   
                                        value={ estado }                                
                                        onChange={ ( _, value ) => { setEstado( value ?? 0 ); } }  
                                     
                                    >                 
                                        <Option key={0} value={0}>Selecciona una opción</Option>
                                        {
                                            estadoArray.map( ( elem ) => (
                                                <Option key={ elem.idEstado } value={ elem.idEstado }> { elem.descripcion } </Option>
                                            ))
                                        }                             
                                    </Select>

                                    {   
                                        errorsDomicilio.estado && ( 
                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                { errorsDomicilio.estado } 
                                            </Alert> )
                                    }   

                                </FormControl>

                            </Grid>

                            <Grid md={6} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-municipio-label">Municipio</FormLabel>
                                
                                    <Select                      
                                        name="municipio"             
                                        size='lg'    
                                        slotProps={{ listbox: { sx: { width: '100%', zIndex: 99999999 } } }}   
                                        value={ municipio }                                
                                        onChange={ ( _, value ) => { setMunicipio( value ?? 0 ); } }  
                                        disabled={ estado === 0 }                                            
                                    >                 
                                        <Option key={0} value={0}>Selecciona una opción</Option>
                                        {
                                            municipioArray.map( ( elem ) => (
                                                <Option key={ elem.idMunicipio } value={ elem.idMunicipio }> { elem.descripcion } </Option>
                                            ))
                                        }                             
                                    </Select>

                                    {   
                                        errorsDomicilio.municipio && ( 
                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                { errorsDomicilio.municipio } 
                                            </Alert> )
                                    }   

                                </FormControl>

                            </Grid>
                            
                            <Grid md={12} xs={12} >                            
                                <FormControl >
                                    <FormLabel sx={{ fontSize: 16 }} id="select-asentamiento-label">Asentamiento</FormLabel>
                                
                                    <Select 
                                        name="asentamiento"          
                                        size='lg'      
                                        slotProps={{ listbox: { sx: { width: '100%', zIndex: 99999999 } } }}      
                                        value={ asentamiento }                                
                                        onChange={ ( _, value ) => { setAsentamiento( value ?? 0 ); } } 
                                        disabled={ municipio === 0 }
                                    >
                                        <Option key={0} value={0}>Selecciona una opción</Option>
                                        {
                                            asentamientoArray.map( ( elem ) => (
                                                <Option key={ elem.idAsentamiento } value={ elem.idAsentamiento }> { elem.asentamiento } (C.P. { elem.codigoPostal }) </Option>

                                            ))
                                        }
                                    </Select>

                                    {   
                                        errorsDomicilio.asentamiento && ( 
                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                { errorsDomicilio.asentamiento } 
                                            </Alert> )
                                    }   

                                </FormControl>

                            </Grid>  

                             <Grid md={12} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-tipoDomicilio-label">Tipo de domicilio</FormLabel>
                                
                                    <Select 
                                        name="tipoDomicilio"          
                                        size='lg'      
                                        slotProps={{ listbox: { sx: { width: '100%', zIndex: 99999999 } } }}      
                                        value={ tipoDomicilio }                                
                                        onChange={ ( _, value ) => { setTipoDomicilio( value ?? 0 ); } } 
                                    >
                                        <Option key={0} value={0}>Selecciona una opción</Option>
                                        {
                                            tipoDomicilioArray.map( ( elem ) => (
                                                <Option key={ elem.idTipoDomicilio } value={ elem.idTipoDomicilio }> { elem.descripcion } </Option>

                                            ))
                                        }
                                    </Select>

                                    {   
                                        errorsDomicilio.tipoDomicilio && ( 
                                            <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                                { errorsDomicilio.tipoDomicilio } 
                                            </Alert> )
                                    }   

                                </FormControl>

                            </Grid>                                                         
                         
                            <Grid md={8} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-descripcionDomicilio-label">Calle</FormLabel>
                                
                                    <Textarea
                                        size={'lg'}        
                                        placeholder=''                     
                                        name="descripcionDomicilio"
                                        variant="outlined"
                                        required
                                        minRows={1}
                                        maxRows={3}
                                        value={ descripcionDomicilio }
                                        onChange={ ( e ) => { setDescripcionDomicilio( e.target.value ) } }
                                    >
                                    </Textarea> 

                                </FormControl>

                                {   
                                    errorsDomicilio.descripcionDomicilio && ( 
                                        <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                            { errorsDomicilio.descripcionDomicilio } 
                                        </Alert> )
                                }   

                            </Grid>

                            <Grid md={4} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-numero-label">Númer Int./Ext.</FormLabel>
                                
                                    <Textarea
                                        size={'lg'}        
                                        placeholder=''                     
                                        name="numero"
                                        variant="outlined"
                                        required
                                        minRows={1}
                                        maxRows={3}
                                        value={ numero }
                                        onChange={ ( e ) => { setNumero( e.target.value ) } }
                                    >
                                    </Textarea> 

                                </FormControl>

                                {   
                                    errorsDomicilio.numero && ( 
                                        <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                            { errorsDomicilio.numero } 
                                        </Alert> )
                                }   

                            </Grid>

                            <Grid md={12} xs={12} >                            
                                <FormControl>
                                    <FormLabel sx={{ fontSize: 16 }} id="select-referenciaDomicilio-label">Referencia</FormLabel>
                                
                                    <Textarea
                                        size={'lg'}        
                                        placeholder=''                     
                                        name="referenciaDomicilio"
                                        variant="outlined"
                                        required
                                        minRows={2}
                                        maxRows={4}
                                        value={ referenciaDomicilio }
                                        onChange={ ( e ) => { setReferenciaDomicilio( e.target.value ) } }
                                    >
                                    </Textarea> 

                                </FormControl>

                                {   
                                    errorsDomicilio.referencia && ( 
                                        <Alert color='warning' variant="soft" style={{ marginTop: '1em' }} > 
                                            { errorsDomicilio.referencia } 
                                        </Alert> )
                                }   

                            </Grid>

                        </Grid>                                  
      
                        <DialogActions>

                            <Button color='neutral' onClick={ GuardarDomicilio } loading={ isSaving }>
                                Guardar 
                            </Button>

                            <Button variant='plain' color='neutral' onClick={ handleCloseModalDomicilio }>
                                Cancelar
                            </Button>

                        </DialogActions>

                    </ModalDialog>
                    
                </ModalOverflow>

            </Modal>     

        </Box>
       
    )
}