import { useSelector } from 'react-redux';

import { Navigate, Route, Routes } from 'react-router-dom';

import { RootState } from '../store';

import { NotFoundPage } from '../pages/auth/NotFoundPage';

import { HomePage } from '../pages/HomePage';
import { RecepcionPage } from '../pages/RecepcionPage';
import { GestionarNotificacionesPage } from '../pages/GestionarNotificacionesPage';
import { AsignacionActuarioPage } from '../pages/AsignacionActuarioPage';
import { MisRutasPage } from '../pages/MisRutasPage';
import { RutaPage } from '../pages/RutaPage';
import { NotificacionPage } from '../pages/NotificacionPage';
import { GenerarRutaPage } from '../pages/GenerarRutaPage';

import { NotificacionesPage } from '../pages/consultas/NotificacionesPage';
import { ListadoEnvioPage } from '../pages/consultas/ListadoEnvioPage';
import { RutasAsignadasPage } from '../pages/consultas/RutasAsignadasPage';

import { UsuariosPage } from '../pages/admin/UsuariosPage';
import { PlantillasPage } from '../pages/admin/PlantillasPage';
import { ApiKeyPage } from '../pages/admin/ApiKeyPage';
import { CatalogosPage } from '../pages/admin/CatalogosPage';

import { AsentamientoPage } from '../pages/catalogos/AsentamientoPage';
import { CiudadPage } from '../pages/catalogos/CiudadPage';
import { EstadoPage } from '../pages/catalogos/EstadoPage';
import { MunicipioPage } from '../pages/catalogos/MunicipioPage';
import { PaisPage } from '../pages/catalogos/PaisPage';
import { TipoAsentamientoPage } from '../pages/catalogos/TipoAsentamientoPage';
import { ZonaPage } from '../pages/catalogos/ZonaPage';
import { TipoUsuarioPage } from '../pages/catalogos/TipoUsuarioPage';
import { TipoArchivoPage } from '../pages/catalogos/TipoArchivoPage';
import { TipoNotificacionPage } from '../pages/catalogos/TipoNotificacionPage';
import { TipoDomicilioPage } from '../pages/catalogos/TipoDomicilioPage';
import { TipoPartePage } from '../pages/catalogos/TipoPartePage';
import { TipoPersonaPage } from '../pages/catalogos/TipoPersonaPage';
import { TipoSesionPage } from '../pages/catalogos/TipoSesionPage';
import { PuestoPage } from '../pages/catalogos/PuestoPage';
import { RolPage } from '../pages/catalogos/RolPage';
import { SexoPage } from '../pages/catalogos/SexoPage';
import { MateriaPage } from '../pages/catalogos/MateriaPage';
import { DistritoJudicialPage } from '../pages/catalogos/DistritoJudicialPage';
import { AutoridadPage } from '../pages/catalogos/AutoridadPage';
import { TipoAcuerdoPage } from '../pages/catalogos/TipoAcuerdoPage';
import { EstatusPage } from '../pages/catalogos/EstatusPage';
import { SubTipoAcuerdoPage } from '../pages/catalogos/SubTipoAcuerdoPage';

export const SagaRoutes = () => {

    const { administrador } = useSelector( ( state: RootState ) => state.auth );

    return (
        <Routes>

            { /* Módulos principales */}          

            <Route path='/inicio/' element={ <HomePage /> } />  

            {
                !administrador
                &&
                <>
                    <Route path='/nueva-recepcion/' element={ <RecepcionPage /> } />  
                    
                    <Route path='/gestionar-notificaciones/' element={ <GestionarNotificacionesPage /> } />  
                    
                    <Route path='/asignacion-actuario/' element={ <AsignacionActuarioPage /> } />  
        
                    <Route path='/mis-rutas/' element={ <MisRutasPage /> } />  
        
                    <Route path='/ruta/:id' element={ <RutaPage /> } />  
        
                    <Route path='/notificacion/:id' element={ <NotificacionPage /> } />         

                    <Route path='/generar-ruta/' element={ <GenerarRutaPage /> } />                  
                </>
            }            

            { /* Consultas */}

            <Route path='/consultas/notificaciones' element={ <NotificacionesPage /> } />  
            
            <Route path='/consultas/listados-envio' element={ <ListadoEnvioPage /> } />  
            
            <Route path='/consultas/rutas-asignadas' element={ <RutasAsignadasPage /> } />  

            { /* Módulos de administración */}

            { 
                administrador 
                && 
                    <>                    
                      
                        <Route path='/usuarios/' element={ <UsuariosPage /> } />         

                        <Route path='/plantillas/' element={ <PlantillasPage /> } />                          
                        
                        <Route path='/api-keys/' element={ <ApiKeyPage /> } />       

                        <Route path='/catalogos/' element={ <CatalogosPage /> } />      

                        <Route path='/catalogos/asentamiento' element={ <AsentamientoPage /> } />     

                        <Route path='/catalogos/ciudad' element={ <CiudadPage /> } />       

                        <Route path='/catalogos/estado' element={ <EstadoPage /> } />      

                        <Route path='/catalogos/municipio' element={ <MunicipioPage /> } />  

                        <Route path='/catalogos/pais' element={ <PaisPage /> } />        

                        <Route path='/catalogos/tipoAsentamiento' element={ <TipoAsentamientoPage /> } />     

                        <Route path='/catalogos/zona' element={ <ZonaPage /> } />

                        <Route path='/catalogos/tipoUsuario' element={ <TipoUsuarioPage /> } />   

                        <Route path='/catalogos/tipoArchivo' element={ <TipoArchivoPage/> } />   

                        <Route path='/catalogos/tipoNotificacion' element={ <TipoNotificacionPage /> } />   

                        <Route path='/catalogos/tipoDomicilio' element={ <TipoDomicilioPage/> } />

                        <Route path='/catalogos/tipoParte' element={ <TipoPartePage/> } />

                        <Route path='/catalogos/tipoPersona' element={ <TipoPersonaPage/> } />

                        <Route path='/catalogos/tipoSesion' element={ <TipoSesionPage/> } />

                        <Route path='/catalogos/puesto' element={ <PuestoPage /> } /> 

                        <Route path='/catalogos/rol' element={ <RolPage /> } /> 
                        
                        <Route path='/catalogos/sexo' element={ <SexoPage /> } /> 

                        <Route path='/catalogos/materia' element={ <MateriaPage /> } />

                        <Route path='/catalogos/distritoJudicial/' element={ <DistritoJudicialPage /> } />

                        <Route path='/catalogos/autoridad/' element={ <AutoridadPage /> } />

                        <Route path='/catalogos/tipoAcuerdo/' element={ <TipoAcuerdoPage /> } />

                        <Route path='/catalogos/estatus/' element={ <EstatusPage /> } />

                        <Route path='/catalogos/subTipoAcuerdo/' element={ <SubTipoAcuerdoPage /> } />                    

                    </> 
            }

            { /* Redirecciones */}

            <Route path='/' element={ <Navigate to={ '/inicio/' } /> } />

            <Route path="*" element={ <NotFoundPage /> } />

        </Routes>
    )

}