
import ReactDOM from 'react-dom/client';

import { App } from './App';

import '@fontsource/inter';
import './css/Main.css'

const root = ReactDOM.createRoot( document.getElementById('root') as HTMLElement );

root.render( <App /> );