import HttpClienteToken from '../../services/HttpClientToken';

import { TipoSesionInterface } from '../../interfaces/catalogos/TipoSesionInterface';

export const ObtenerTiposSesiones= () => {

    return new Promise<TipoSesionInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoSesion/ObtenerTiposSesiones', token)
            .then( ({ data } : { data : TipoSesionInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const TiposSesiones = () => {

    return new Promise<TipoSesionInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoSesion/', token)
            .then( ({ data } : { data : TipoSesionInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarTipoSesion = ( params: TipoSesionInterface ) => {

    return new Promise<TipoSesionInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoSesion/Actualizar', params, token)
            .then( ({ data } : { data : TipoSesionInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoSesion: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarTipoSesion = ( params: TipoSesionInterface ) => {

    return new Promise<TipoSesionInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoSesion/Agregar', params, token)
            .then( ({ data } : { data : TipoSesionInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoSesion: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}