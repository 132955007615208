import { ListItem, Chip, ListItemButton, ListItemContent, Typography } from '@mui/joy'

import moment from 'moment'

import { RutaProps } from '../interfaces/comun/RutaInterface'

interface Props {
    ruta: RutaProps,
    onClick: ( ruta: RutaProps ) => void,
}

export const RutaListItem = ( { ruta, onClick }: Props ) => {

    const { idRuta, actuario, fecha, notificaciones, selected } = ruta;

    return (
        <ListItem endAction={ <Chip variant='outlined' color='neutral' sx={{ fontWeight: 'bold', mr: 2 }}>{ notificaciones?.length ?? 0 }</Chip> }>
            <ListItemButton 
                variant={ selected === true ? 'soft' : 'plain' }
                sx={{ borderRadius: selected ? 15 : 0, p: 1 }}
                onClick={ () => onClick( ruta ) }
                selected={ selected === true }
            >                                  
                <ListItemContent>
                    <Typography level="body-md" fontWeight={'bold'} mb={1}>Ruta - { idRuta }</Typography>   
                    <Typography level="body-sm">{ actuario }</Typography>
                    <Typography level="body-sm" noWrap mt={'4px'}>
                        Fecha de asignación: <strong>{ moment( fecha ).format('DD-MM-YYYY') }</strong> 
                    </Typography>
                </ListItemContent>
            </ListItemButton>
        </ListItem>
    )

}
