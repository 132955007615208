import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Grid, Table, CircularProgress, Box, Typography, Button, Chip, Select, Option, FormControl, FormLabel, Input } from '@mui/joy';

import MapIcon from '@mui/icons-material/Map';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';

import moment from 'moment';

import { Sidebar } from '../../components/Sidebar';

import { Header } from '../../components/Header';
import { Layout } from '../../components/Layout';
import Pagination from '../../components/Pagination';

import { RootState } from '../../store';

import { ModalRutaNotificaciones } from '../../modals/ModalRutaNotificaciones';
import { ModalRutaActuario } from '../../modals/ModalRutaActuario';

import { ConsultarRutasAsignadas, RutasAsignadas } from '../../connections/comun/RutaConnection';
import { ObtenerActuarios } from '../../connections/admin/UsuarioConnection';
import { ObtenerEstatus } from '../../connections/catalogos/EstatusConnection';

import { UsuarioProps } from '../../interfaces/admin/UsuarioInterface';
import { RutasAsignadasProps } from '../../interfaces/comun/RutaInterface';
import { NotificacionFullProps } from '../../interfaces/comun/NotificacionInterface';
import { EstatusInterface } from '../../interfaces/catalogos/EstatusInterface';

export const RutasAsignadasPage = () => {

    const { administrador, puesto } = useSelector( ( state: RootState ) => state.auth );

    const [isLoading, setIsLoading] = useState<boolean>( true );

    const [open, setOpen] = useState<boolean>( false );
    const [openRuta, setOpenRuta] = useState<boolean>( false );

    const [searching, setSearching] = useState<boolean>( false );

    const [page, setPage] = useState<number>( 0 );
    const [rowsPerPage, setRowsPerPage] = useState<number>( 10 );
    const [recordsCount, setRecordsCount] = useState<number>( 0 );

    const [rows, setRows] = useState<RutasAsignadasProps[]>( [] );
    const [notificaciones, setNotificaciones] = useState<NotificacionFullProps[]>( [] );
    const [notificacionesRuta, setNotificacionesRuta] = useState<NotificacionFullProps[]>( [] );

    const [actuario, setActuario] = useState<UsuarioProps>(  { id: '', idUsuario: 0, nombreCompleto: '', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' } );
    const [actuarios, setActuarios] = useState<UsuarioProps[]>( [] );

    const [estatus, setEstatus] = useState<EstatusInterface>( { idEstatus: 0, descripcion: '', activo: false } );
    const [estatusArray, setEstatusArray] = useState<EstatusInterface[]>( [] );

    const [fecha, setFecha] = useState<string>( "" );

    const handleChange = ( _: React.SyntheticEvent | null, newValue: UsuarioProps | null, ) => setActuario( newValue ?? { id: '', idUsuario: 0, nombreCompleto: '', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' } )

    const handleChangeEstatus = ( _: React.SyntheticEvent | null, newValue: EstatusInterface | null, ) => setEstatus( newValue ?? { idEstatus: 0, descripcion: '', activo: false } )

    const handleBuscar = () => {

        setSearching( true );
        setPage( 0 );

    }

    const handleClearFields = () => {

        setRows( [] );
        setPage( 0 );

        setEstatus( { idEstatus: 0, descripcion: '', activo: false } );
        setActuario( { id: '', idUsuario: 0, nombreCompleto: '', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' } );
        setFecha( '' );
        setSearching( false );
        setIsLoading( true );
    }

    useEffect(() => {

        async function Obtener(){

            setIsLoading( true ); 

            await RutasAsignadas({ page, rowsPerPage }).then( resp => {

                setTimeout(() => {
                    
                    setRows( resp.records );
                    setRecordsCount( resp.recordsCount);
                    setIsLoading( false ); 

                }, 400);

            });

        }

        if( !searching ){
            Obtener();
        }
     
    }, [ page, rowsPerPage, searching ])  
     
    useEffect(() => {

        async function Obtener(){

            setIsLoading( true ); 

            await ConsultarRutasAsignadas({ page, rowsPerPage, actuario: actuario.idUsuario, fecha, estatus: estatus.idEstatus }).then( resp => {

                setTimeout(() => {
                 
                    setRows( resp.records );
                    setRecordsCount( resp.recordsCount);
                    setIsLoading( false ); 

                }, 400);                   

            });

        }

        if( searching ){
            Obtener();
        }
     
    }, [ page, rowsPerPage, searching, actuario, fecha, estatus ])  

    useEffect(() => {
      
        async function obtener() {            

            await ObtenerActuarios().then( resp => {

                const defaultUsuario: UsuarioProps = { id: '', idUsuario: 0, nombreCompleto: 'Seleccione el actuario', imagenPerfil: '', puesto: '', activo: false, administrador: false, correoElectronico: '', tipoUsuario: '' }; 

                setActuarios([ defaultUsuario, ...resp ]);
            });

        }
    
        obtener();        
        
    }, [ ])   

    useEffect(() => {
      
        async function obtener() {            

            await ObtenerEstatus().then( resp => {

                const defaultEstatus: EstatusInterface = { idEstatus: 0, descripcion: 'Seleccione el estatus', activo: false };

                setEstatusArray( [ defaultEstatus, ...resp.filter( (e) => e.idEstatus === 6 || e.idEstatus === 7 || e.idEstatus === 8 || e.idEstatus === 10 ) ] );
            });

        }
       
        obtener();        

    }, [ ])   

    return (   
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>

            <Sidebar />
            
            <Header />

            <Layout
                title={'Rutas asignadas'}
                isQuery={ true }
            >

                <Grid container spacing={3}>  

                    {
                        ( administrador || ( puesto !== 0 && puesto !== 3) )
                        &&
                            <Grid xs={12} md={4}>

                                <FormControl disabled={ searching }>
                                    <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>Actuario(s)</FormLabel>
                                    
                                    <Select
                                        size='lg'
                                        sx={{ width: '100%' }}                     
                                        slotProps={{
                                            listbox: {
                                                sx: {
                                                    width: '100%',
                                                    zIndex: 99999999
                                                },
                                            },
                                        }}      
                                        value={ actuario }
                                        onChange={ handleChange }
                                        placeholder="Seleccione el actuario"
                                    >
                                        {
                                            actuarios.map( ( e ) => (
                                                <Option key={ e.idUsuario } value={ e } sx={{ py: 1 }}>{ e.nombreCompleto }</Option>
                                            ))
                                        }

                                    </Select>

                                </FormControl>

                            </Grid>
                    }

                    <Grid xs={12} md={4}>

                        <FormControl disabled={ searching }>
                            <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>Estatus</FormLabel>
                            <Select
                                size='lg'
                                sx={{ width: '100%' }}  
                                slotProps={{
                                    listbox: {
                                        sx: {
                                            width: '100%',
                                            zIndex: 99999999
                                        },
                                    },
                                }}   
                                value={ estatus }
                                onChange={ handleChangeEstatus }  
                                placeholder="Seleccione el estatus"
                            >                            
                                {
                                    estatusArray.map( ( e ) => (
                                        <Option key={ e.idEstatus } value={ e } sx={{ py: 1 }}>{ e.descripcion }</Option>
                                    ))
                                }
                            </Select>                                    
                        </FormControl>
                    
                    </Grid>

                    <Grid xs={12} md={4}>

                        <FormControl disabled={ searching }>
                            <FormLabel sx={{ fontSize: 16, fontWeight: 'bold' }}>Fecha de asignación</FormLabel>
                            <Input size='lg' placeholder="Placeholder" type='date' value={ fecha } onChange={ (e) => setFecha( e.target.value ) } />
                        </FormControl>

                    </Grid>

                    <Grid xs={12} textAlign={'end'}>

                        <Button
                            color='neutral'
                            variant='solid'
                            size='lg'
                            startDecorator={ searching ? <SearchOffIcon /> : <SearchIcon /> }
                            onClick={ () => searching ? handleClearFields() : handleBuscar() }
                            disabled={ actuario.idUsuario === 0 && fecha === '' && estatus.idEstatus === 0 }
                        >
                            { searching ? 'Limpiar' : 'Buscar' }
                        </Button>

                    </Grid>
                           
                    <Grid xs={12} md={12} mt={1}>                         
                        
                        <Table 
                            aria-label="striped table" 
                            stripe={'even'} 
                            borderAxis='x'
                            size='lg' 
                            sx={{ 
                                width: { xs: '100%', md: '100%' },
                                display:{ xs: 'block', md: 'inline' },
                                overflowX: { xs: 'auto', md: 'hidden' },
                            }}
                        >      

                            <thead>

                                <tr>
                                    <th style={{ width: '25%', textAlign: 'center' }}>Actuario</th>
                                    <th style={{ width: '15%' }}>Fecha asignación</th>
                                    <th style={{ width: '15%' }}>Fecha inicio</th>
                                    <th style={{ width: '15%' }}>Fecha finalización</th>
                                    <th style={{ width: '5%', textAlign: 'center' }}>Zona</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Número de <br /> notificaciones</th>
                                    <th style={{ width: '20%', textAlign: 'center' }}>Ver ruta</th>                            
                                    <th style={{ width: '15%', textAlign: 'center' }}>Estatus</th>  
                                </tr>

                            </thead>

                            <tbody>

                                {

                                    isLoading
                                    ?
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '250px' }}>
                                                <CircularProgress color='neutral' size='md' />
                                            </td>
                                        </tr>
                                    :   
                                        rows
                                        .map( ( row ) => (

                                            <tr key={ row.id }>     
                                                
                                                <td>                                              
                                                    <Typography level="title-md">{ row.actuario }</Typography>
                                                </td>
                                            
                                                <td>{ moment( row.fecha ).format('DD-MM-YYYY h:mm a') }</td>

                                                <td>{ row.fechaInicio ? moment( row.fechaInicio ).format('DD-MM-YYYY h:mm a') : '' }</td>
                                                
                                                <td>{ row.fechaFinalizacion ? moment( row.fechaFinalizacion ).format('DD-MM-YYYY h:mm a') : '' }</td>

                                                <td style={{ textAlign: 'center' }}>{ row.zona }</td>                                        
                                                                                    
                                                <td style={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>
                                                    
                                                    <Button 
                                                        variant="solid" 
                                                        color="neutral" 
                                                        size='sm'
                                                        endDecorator={ <FormatListBulletedIcon /> }
                                                        onClick={ () => { setNotificaciones( row?.notificaciones ?? [] ); setOpen( true ); } }
                                                    >
                                                        { row.notificaciones?.length }
                                                    </Button>                                            
                                                    
                                                </td> 

                                                <td style={{ textAlign: 'center' }}>

                                                    <Button 
                                                        variant="soft" 
                                                        color="neutral" 
                                                        startDecorator={ <ArrowForwardIcon />  }
                                                        endDecorator={ <MapIcon /> }
                                                        onClick={ () => { setNotificacionesRuta( row?.notificaciones ?? [] ); setOpenRuta( true ); } }
                                                    >
                                                        Ver ruta
                                                    </Button>
                                                
                                                </td> 

                                                <td style={{ textAlign: 'center' }}>
                                                    <Chip variant='outlined' size='lg'>
                                                        { row.estatus }
                                                    </Chip>
                                                </td>                                                            

                                            </tr>
                                        ))
                                }

                                {
                                    ( !isLoading && rows.length === 0 )
                                    &&
                                        <tr>
                                            <td colSpan={9} style={{ textAlign: 'center', padding: '50px' }}>
                                                <Typography fontSize={14} fontWeight={'bold'}>No se encontraron registros</Typography>
                                            </td>
                                        </tr>
                                }

                            </tbody>                          

                        </Table>       

                    </Grid>

                </Grid>
                
                <Pagination
                    gotoPage={ setPage }
                    length={ recordsCount }
                    pageSize={ rowsPerPage }
                    setPageSize={ setRowsPerPage }       
                    loading={ isLoading }                     
                />

            </Layout>
            
            <ModalRutaNotificaciones
                open={ open }
                setOpen={ setOpen}
                notificaciones={ notificaciones }
            />   

            <ModalRutaActuario
                open={ openRuta }
                setOpen={ setOpenRuta }
                notificaciones={ notificacionesRuta }
            />     

        </Box>
    );
}
