
import { Navigate, Route, Routes } from 'react-router-dom';

import { LoginPage } from '../pages/auth/LoginPage';
import { ForgotPasswordPage } from '../pages/auth/ForgotPasswordPage';
import { ResetPasswordPage } from '../pages/auth/ResetPasswordPage';
import { FirstLoginPage } from '../pages/auth/FirstLoginPage';

export const AuthRoutes = () => {

    return (
        <Routes>

            <Route path="login" element={ <LoginPage /> } />    

            <Route path="forgot-password" element={ <ForgotPasswordPage /> } />    

            <Route path="reset-password/:token" element={ <ResetPasswordPage /> } />    

            <Route path="first-login" element={ <FirstLoginPage /> } />    

            <Route path='/*' element={ <Navigate to="/auth/login" /> } />                          

        </Routes>
    )

}