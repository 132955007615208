import HttpClienteToken from '../../services/HttpClientToken';

import { TipoParteInterface } from '../../interfaces/catalogos/TipoParteInterface';

export const ObtenerTiposPartes= () => {

    return new Promise<TipoParteInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoParte/ObtenerTiposPartes', token)
            .then( ({ data } : { data : TipoParteInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const TiposPartes = () => {

    return new Promise<TipoParteInterface[]>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.get('/Api/TipoParte/', token)
            .then( ({ data } : { data : TipoParteInterface[] } ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( [] );
            });
        }

    });

}

export const ActualizarTipoParte = ( params: TipoParteInterface ) => {

    return new Promise<TipoParteInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoParte/Actualizar', params, token)
            .then( ({ data } : { data : TipoParteInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoParte: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}

export const AgregarTipoParte= ( params: TipoParteInterface ) => {

    return new Promise<TipoParteInterface>( (resolve, eject) => {

        const data = JSON.parse( window.localStorage.getItem('data') ?? '' );

        if( data ){
            const { token } = data;

            HttpClienteToken.post('/Api/TipoParte/Agregar', params, token)
            .then( ({ data } : { data : TipoParteInterface} ) => {               
                resolve( data );                
            })
            .catch( () => {
                resolve( {
                    idTipoParte: 0,
                    descripcion: '',
                    activo: false,           
                });
            });
        }

    });

}