import HttpClienteToken from '../../services/HttpClientToken';

import { PlantillaInterface, PlantillaResponse } from '../../interfaces/comun/PlantillaInterface';

export const ObtenerPlantillas = () => {

    return new Promise<PlantillaInterface[]>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.get('/Api/Plantilla/ObtenerPlantillas', token)
            .then(({ data }: { data: PlantillaInterface[] }) => {
                resolve( data );
            })
            .catch(() => {
                resolve( [] );
            });

        }

    });

}

export const Plantillas = () => {

    return new Promise<PlantillaInterface[]>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.get('/Api/Plantilla', token)
            .then(({ data }: { data: PlantillaInterface[] }) => {
                resolve( data );
            })
            .catch(() => {
                resolve( [] );
            });

        }

    });

}

export const ActualizarPlantilla = ( params: PlantillaInterface ) => {

    return new Promise<PlantillaResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {

            const { token } = data;

            HttpClienteToken.post('/Api/Plantilla/Actualizar', params, token)
            .then(({ data }: { data: PlantillaResponse }) => {
                resolve( data );
            })
            .catch(() => {

                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });

            });

        }

    });

}

export const AgregarPlantilla = ( params: PlantillaInterface ) => {

    return new Promise<PlantillaResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) {
            
            const { token } = data;

            HttpClienteToken.post('/Api/Plantilla/Agregar', params, token)
            .then(({ data }: { data: PlantillaResponse }) => {
                resolve( data );
            })
            .catch(() => {

                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });
                
            });
        }

    });

}

export const ActualizarActivo = (params: PlantillaInterface) => {

    return new Promise<PlantillaResponse>((resolve, eject) => {

        const data = JSON.parse(window.localStorage.getItem('data') ?? '');

        if (data) { 

            const { token } = data;

            HttpClienteToken.post('/Api/Plantilla/ActualizarActivo', params, token)
            .then(({ data }: { data: PlantillaResponse }) => {
                resolve( data );
            })
            .catch(() => {

                resolve({
                    success: false,
                    message: "Ocurrio un error, contacte con el administrador"
                });

            });

        }

    });

}