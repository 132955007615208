
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PermisoProps } from '../../interfaces/admin/UsuarioInterface';

export interface stateProps {
    nombres?:                string;
    apellidos?:              string;
    correoElectronico?:      string;
    token?:                  string;
    administrador?:          boolean;
    restablecerContrasena?:  boolean;
    puesto?:                 number;
    permisos?:               PermisoProps;
}

const initialState = {
    token: '',
    nombres: '', 
    apellidos: '', 
    correoElectronico: '', 
    administrador: false,
    restablecerContrasena: false,
} as stateProps;

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        login: ( state, { payload } : PayloadAction<stateProps> ) => {
            state.token = payload.token;
            state.nombres = payload.nombres;
            state.apellidos = payload.apellidos;
            state.correoElectronico = payload.correoElectronico;
            state.administrador = payload.administrador;    
            state.restablecerContrasena = payload.restablecerContrasena;    
            state.permisos = payload.permisos;    
            state.puesto = payload.puesto;    
        },
        logout: ( state ) => {     
            state.token = '';
            state.nombres = '';
            state.apellidos = '';
            state.correoElectronico = '';
            state.administrador = false;
            state.restablecerContrasena = false;
            state.puesto = 0;
            state.permisos = {
                RecepcionistaEnvios:                    false,
                GestionarNotificaciones:                false,
                AsignadorActuarios:                     false,
                GeneradorRutas:                         false,
                VisualizadorMisRutas:                   false,
                VisualizadorConsultas:                  false,
                VisualizadorNotificaciones:             false,
                VisualizadorListadosEnvio:              false,
                VisualizadorRutasAsignadas:             false,
            };
        },
        setPermisos: ( state, { payload } : PayloadAction<stateProps> ) => {
            state.permisos = payload.permisos;    
        },
    },
})

export const { login, logout, setPermisos } = authSlice.actions

export default authSlice.reducer