import { v4 as uuidv4 } from 'uuid';

import { CatalogoProps } from "../interfaces/admin/CatalogoInterface";

export const catalogosDB: CatalogoProps[] = [   
    { 
        id: uuidv4(),
        descripcion: 'Asentamiento' , 
        path: '/catalogos/asentamiento/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Autoridad' , 
        path: '/catalogos/autoridad/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Ciudad' , 
        path: '/catalogos/ciudad/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Distrito Judicial' , 
        path: '/catalogos/distritoJudicial/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Estado' , 
        path: '/catalogos/estado/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Estatus' , 
        path: '/catalogos/estatus/'
    },   
    { 
        id: uuidv4(),
        descripcion: 'Matería' , 
        path: '/catalogos/materia/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Municipio' , 
        path: '/catalogos/municipio/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Pais' , 
        path: '/catalogos/pais/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Puesto' , 
        path: '/catalogos/puesto/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Rol' , 
        path: '/catalogos/rol/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Sexo' , 
        path: '/catalogos/sexo/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Subtipo de acuerdo' , 
        path: '/catalogos/subTipoAcuerdo/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Tipo de acuerdo' , 
        path: '/catalogos/tipoAcuerdo/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Tipo de asentamiento' , 
        path: '/catalogos/tipoAsentamiento/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Tipo de archivo' , 
        path: '/catalogos/tipoArchivo/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Tipo de domicilio' , 
        path: '/catalogos/tipoDomicilio/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Tipo de notificación' , 
        path: '/catalogos/tipoNotificacion/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Tipo de parte' , 
        path: '/catalogos/tipoParte/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Tipo de persona' , 
        path: '/catalogos/tipoPersona/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Tipo de sesion' , 
        path: '/catalogos/tipoSesion/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Tipo de usuario' , 
        path: '/catalogos/tipoUsuario/'
    },
    { 
        id: uuidv4(),
        descripcion: 'Zona' , 
        path: '/catalogos/zona/'
    },    
];